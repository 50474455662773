import React, { Component } from 'react'
import axios from 'axios'
import { getCookie } from '../helpers/Auth'

export class ExpertServicePage extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             paragraph:'',
             item:null,
             items:[],
             token:null
        }
        this.addItem = this.addItem.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
        this.removeItem = this.removeItem.bind(this)
        this.paraHandler = this.paraHandler.bind(this)
        this.submitHandler = this.submitHandler.bind(this)
    }
    componentDidMount = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/page/expert-services`)
        .then(res => {
            console.log(res.data)
            this.setState({
                paragraph:res.data.content.paragraph,
                items:res.data.content.items
            })
        })
        .catch(err => console.log(err))

        let tk = getCookie('token')

        if(tk){
            this.setState({token:tk})
        }
    }


    changeHandler = (e) => {
       
        this.setState({
            item:e.target.value
        })

    }

    paraHandler = (e) => {
        this.setState({
            paragraph:e.target.value
        })
    }
    addItem = (e) => {
            e.preventDefault()
            let itemsList = this.state.items
            itemsList.push(this.state.item)
            this.setState({
                items:itemsList
            })

           // console.log(this.state.items)
    }
    
    removeItem = (e, idx) => {
        let itemsList = this.state.items
        itemsList.splice(idx,1)
        this.setState({
            items:itemsList
        })
        
    }

    submitHandler = (e) => {
        e.preventDefault()
        const config2 = {
            headers: {
                
                'Authorization': 'Bearer ' + this.state.token
            }
        }
        axios.post(`${process.env.REACT_APP_API_URL}/page/expert-services`,{
            content:{ paragraph:this.state.paragraph, items:this.state.items}
        }, config2)
        .then(res => console.log(res.data))
        .catch(err => console.log(err))
    }
    render() {
        return (
            <div className="container">
                <h1>Create a List of Services to be provided</h1>
             <form onSubmit={e => this.submitHandler(e)}>
                 <div className="h5">Paragraph 1</div>
                   <textarea
                    rows="3"
                    cols="30"
                    id="paragraph"
                    name="paragraph"
                    className="form-control"
                    value={this.state.paragraph}
                    onChange={e => this.paraHandler(e)}
                    ></textarea>
                <div className="h5">Points to be added</div>
                <input 
                    type="text"
                    name="item"
                    id="item"
                    placeholder="Enter the item to be added"
                    className="form-control"
                    onChange={ e => this.changeHandler(e) }
                    
                    
                />
                <br/>
                <input type="button" className="btn btn-success btn-sm" value="Add Item" onClick={e => this.addItem(e)}/>
                {/* </form> */}
                <br/>
                <br/>
                <h5>Services:</h5>
                <ul class="list-group">
                {
                    this.state.items.map((item, index) => {
                        return  <li class="list-group-item " key={index}>{item}
                                <button onClick={e => this.removeItem(e, index)} className="btn-sm border btn-danger float-right">X</button>
                         </li>
                    })
                }
                </ul>
                <br/>
                <input type="submit" className="form-control btn btn-primary bg-primary" value="Click To Update Content"/>
                </form>
            
            </div>
        )
    }
}

export default ExpertServicePage
