import React from 'react'
import { Topbar } from './Topbar'
import { HeaderClassic } from './HeaderClassic'
export function HeaderTransparent() {
    return (
        <div>
            <div class="header-transparent">
                <Topbar/>
                <HeaderClassic/>
            </div>
            
        </div>
    )
}

export default HeaderTransparent