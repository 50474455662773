import React from 'react'
import ContactForm from './ContactForm'

export  function ContactMap() {
    return (
        <div>
            <div id="map"></div>
        </div>
    )
}

export default ContactForm