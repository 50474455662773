import React, {useState, useRef, useEffect} from 'react'
//import JoditEditor from "jodit-react"
import axios from 'axios'
import { getCookie } from '../helpers/Auth'

export function VisaHomeSection() {
    const editor = useRef(null)
	const [content, setContent] = useState({
        studyVisa:'',
        workPermit:'',
        expressEntry:'',
        lmiaPermit:'',
        familySponsorship:'',
        pnpVisa:'',
        touristVisa:'',
        childCareVisa:''
    })
    const [token, setToken] = useState(null)
    const [errMessage, setErrMessage ] = useState('')
    const [infoMessage, setInfoMessage ] = useState('')
    const loadData = async () => {
       
        await axios.get(`${process.env.REACT_APP_API_URL}/page/visa-section`)
        .then( res => {
           // console.log(res.data)
            setContent(res.data.content)
            
        })
        .catch(err => {
            //setErrMessage(err.message)
            console.log(err.message)

        })

    }
    useEffect(() => {
        loadData()
        let tk = getCookie('token')

        if(tk){
            setToken(tk)
        }
       // console.log(content)

    },[])
    


	const config2 = {
        headers: {
            
            'Authorization': 'Bearer ' + token
        }
    }

    const changeHandler = (e) => {
        content[e.target.name] = e.target.value
        setContent({...content})

    }
    
    const submitHandler = (e) => {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_API_URL}/page/visa-section`,content,config2)
        .then( res => {
            console.log(res.data)
            setInfoMessage(res.data.message)
        })
        .catch(err => setErrMessage(err.message))
        //console.log(content)
       
    }
    const {studyVisa, workPermit, expressEntry, lmiaPermit, familySponsorship, pnpVisa, touristVisa, childCareVisa } = content
    return (
       
        <div className="container">
                { (errMessage) && 
                    <div className="bg-danger p-2 text-white">{errMessage}</div>
                }
                { (infoMessage) && 
                    <div className="bg-success p-2 text-white">{infoMessage}</div>
                }
             <form onSubmit={e => submitHandler(e)}>
                 <h1>Please Enter Short Description of Various Visas for HomePage</h1>
                
                <div className="h5">Study Visa</div>
                <input
                    type="text"
                    name="studyVisa"
                    id="studyVisa"
                    value={studyVisa}
                    className="form-control"
                    onChange = {e => changeHandler(e)}
                    required
                />
                <div className="h5">Work Permit</div>
                <input
                    type="text"
                    name="workPermit"
                    id="workPermit"
                    value={workPermit}
                    className="form-control"
                    onChange = {e => changeHandler(e)}
                    required
                />
                <div className="h5">Express Entry</div>
                <input
                    type="text"
                    name="expressEntry"
                    id="expressEntry"
                    value={expressEntry}
                    className="form-control"
                    onChange = {e => changeHandler(e)}
                    required
                />
                <div className="h5">LMIA Permit</div>
                <input
                    type="text"
                    name="lmiaPermit"
                    id="lmiaPermit"
                    value={lmiaPermit}
                    className="form-control"
                    onChange = {e => changeHandler(e)}
                    required
                />
                 <div className="h5">Family Sponsorship</div>
                <input
                    type="text"
                    name="familySponsorship"
                    id="familySponsorship"
                    value={familySponsorship}
                    className="form-control"
                    onChange = {e => changeHandler(e)}
                    required
                />
                
                <div className="h5">PNP Visa</div>
                <input
                    type="text"
                    name="pnpVisa"
                    id="pnpVisa"
                    value={pnpVisa}
                    className="form-control"
                    onChange = {e => changeHandler(e)}
                    required
                />
                
                <div className="h5">Tourist Visa</div>
                <input
                    type="text"
                    name="touristVisa"
                    id="touristVisa"
                    value={touristVisa}
                    className="form-control"
                    onChange = {e => changeHandler(e)}
                    required
                />
                 <div className="h5">Home Child Care Visa</div>
                <input
                    type="text"
                    name="childCareVisa"
                    id="childCareVisa"
                    value={childCareVisa}
                    className="form-control"
                    onChange = {e => changeHandler(e)}
                    required
                />
                
                <br/>
                <input
                type="submit"
                value="Update"
                className="form-control btn btn-primary bg-primary"
                />
                </form>
        </div>
    )
}

export default VisaHomeSection