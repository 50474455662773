import React from 'react'

export function CountryListContent() {
    return (
        <div>
            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="country-flag-thumbnail">

                                <div className="country-icon-thumbnail-img"><a href="#"><img src="./assets/images/country/canada.svg" alt="" className="img-fluid" /></a></div>
                                <div className="country-flag-thumbnail-content">
                                    <h3><a href="#" className="title">Canada</a></h3>
                                    <p>Suspendisse lobortis, eros ac blandit efficituunc mi aliquet justo, sit amet aliquet nunc risus a felis.</p>
                                    <a href="#" className="btn-link-primary">Read More</a>
                                </div>
                                <div className="slanting-pattern"></div>
                            </div>

                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="country-flag-thumbnail">

                                <div className="country-icon-thumbnail-img"><a href="#"><img src="./assets/images/country/australia.svg" alt="" className="img-fluid" /></a></div>
                                <div className="country-flag-thumbnail-content">
                                    <h3><a href="#" className="title">Australia</a></h3>
                                    <p>Quam semper simple dummy eleifend lorem in imperdiet mauris estibulum et efficitur lorem ipsum.</p>
                                    <a href="#" className="btn-link-primary">Read More</a>
                                </div>
                                <div className="slanting-pattern"></div>
                            </div>

                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="country-flag-thumbnail">

                                <div className="country-icon-thumbnail-img"><a href="#"><img src="./assets/images/country/united-kingdom.svg" alt="" className="img-fluid" /></a></div>
                                <div className="country-flag-thumbnail-content">
                                    <h3><a href="#" className="title">United Kingdom</a></h3>
                                    <p>Duis id quam semper, eleifend lorem in, imperdiet mauris simple estibulum imple et efficitur nunc.</p>
                                    <a href="#" className="btn-link-primary">Read More</a>
                                </div>
                                <div className="slanting-pattern"></div>
                            </div>

                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="country-flag-thumbnail">

                                <div className="country-icon-thumbnail-img"><a href="#"><img src="./assets/images/country/united-states-of-america.svg" alt="" className="img-fluid" /></a></div>
                                <div className="country-flag-thumbnail-content">
                                    <h3><a href="#" className="title">USA</a></h3>
                                    <p>Duis id quam semper, eleifend lorem in, imperdiet mauris simple estibulum imple et efficitur nunc.</p>
                                    <a href="#" className="btn-link-primary">Read More</a>
                                </div>
                                <div className="slanting-pattern"></div>
                            </div>

                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="country-flag-thumbnail">

                                <div className="country-icon-thumbnail-img"><a href="#"><img src="./assets/images/country/germany.svg" alt="" className="img-fluid" /></a></div>
                                <div className="country-flag-thumbnail-content">
                                    <h3><a href="#" className="title">Europe</a></h3>
                                    <p>Asellus maximus odio bibendum tortor scelerisque quisraesent malesuada lorem nec mauris viverra consequat. </p>
                                    <a href="#" className="btn-link-primary">Read More</a>
                                </div>
                                <div className="slanting-pattern"></div>
                            </div>

                        </div>
                        {/* <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="country-flag-thumbnail">

                                <div className="country-icon-thumbnail-img"><a href="#"><img src="./assets/images/country/south-africa.svg" alt="" className="img-fluid" /></a></div>
                                <div className="country-flag-thumbnail-content">
                                    <h3><a href="#" className="title">South Africa</a></h3>
                                    <p>Quam semper simple dummy eleifend lorem in lorem ipsumimperdiet mauris estibulum et efficitur lorem ipsum.</p>
                                    <a href="#" className="btn-link-primary">Read More</a>
                                </div>
                                <div className="slanting-pattern"></div>
                            </div>

                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="country-flag-thumbnail">

                                <div className="country-icon-thumbnail-img"><a href="#"><img src="./assets/images/country/singapore.svg" alt="" className="img-fluid" /></a></div>
                                <div className="country-flag-thumbnail-content">
                                    <h3><a href="#" className="title">Singapore</a></h3>
                                    <p>Duis id quam semper, esimple dummy leifend lorem inimperdiet mauris simple estibulum et efficitur nunc.</p>
                                    <a href="#" className="btn-link-primary">Read More</a>
                                </div>
                                <div className="slanting-pattern"></div>
                            </div>

                        </div> */}
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="country-flag-thumbnail">

                                <div className="country-icon-thumbnail-img"><a href="#"><img src="./assets/images/country/new-zealand.svg" alt="" className="img-fluid" /></a></div>
                                <div className="country-flag-thumbnail-content">
                                    <h3><a href="#" className="title">New Zealand</a></h3>
                                    <p>Duis id quam semper, esimple dummy leifend lorem inimperdiet mauris simple estibulum et efficitur nunc.</p>
                                    <a href="#" className="btn-link-primary">Read More</a>
                                </div>
                                <div className="slanting-pattern"></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CountryListContent