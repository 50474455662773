import React, {useState, useRef, useEffect} from 'react'
import axios from 'axios'

export function Main() {
    const [content, setContent] = useState(null)
    const loadData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/page/aboutus-main`)
          .then(res => {
  
              console.log("On load: "+res.data.content)
              setContent(res.data.content)
          })
          .catch(err => console.log(err.message))
      }
  
      useEffect(() => {
         loadData()
      },[])

    return (
        <div>
            <div dangerouslySetInnerHTML={{__html: content}} />
        </div>
    )
}
export default Main