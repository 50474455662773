import React, { Component } from 'react'

export class CanadianRelative extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             relativeScore:null
        }
        this.changeHandler = this.changeHandler.bind(this)
    }
    componentDidUpdate = () => {
        if(this.state.relativeScore){
            
            this.props.setCanadianRelativeScore(this.state.relativeScore)

            if(this.props.partner){
                this.props.showSpouseComp(true)
            }
            
            
            this.setState({
                relativeScore:null
            })

        }
    }
    changeHandler = (e) => {

        if(e.target.value){
            this.setState({
                relativeScore:e.target.value
            })
        }

    }
    render() {
        return (
            <div>
                <div className="form-group">
                        <label for="name">10) Do you or your spouse or common law partner (if they will come with you to Canada) have at least one brother or sister living in Canada who is a citizen or permanent resident?
                            <br/>
                            Note: to answer yes, the brother or sister must be:

                            <ul>
                                <li>18 years old or older</li>
                                <li>related to you or your partner by blood, marriage, common-law partnership or adoption</li>
                                <li>have a parent in common with you or your partner</li>
                            </ul>
                            <br/>
                            A brother or sister is related to you by:

                            <ul>
                                <li>blood (biological)</li>
                                <li>adoption</li>
                                <li>marriage (step-brother or step-sister)</li>
                            </ul>
                        </label>
                                                      
                        <select id="relative" name="relative" className="form-control" onChange={this.changeHandler} >
                            <option value=''>Please Select..</option>
                            
                                 <option value="0">No</option> 
                                 <option value="15">Yes</option> 
                        </select>
                    </div>
            </div>
        )
    }
}

export default CanadianRelative
