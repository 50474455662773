import React from 'react'

export  function Testimonial() {
    return (
        <div>
           
        <div className="container">
            <div className="row">
                <div className="offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                    <div className="section-title text-center mb60">
                       
                        <h2>What our Clients say about us. Client Testimonials</h2>
                       
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="card-testimonial card-testimonial-light">
                       
                        <div className="card-testimonial-img"><img src="./assets/images/card-testimonial-img-1.jpg" alt="" className="rounded-circle"/></div>
                            <div className="card-testimonial-content">
                                <p className="card-testimonial-text">“Fusce non mi at nisl laoreet pretiumulla ut elementum sapien, a pulvinar augueed semper sed tellus in ultrices am simply dummy content hendrerit elit vel urna fermentum congue. . ”</p>
                            </div>
                            <div className="card-testimonial-info">
                                {/* <span className="card-testimonial-flag"><img src="./assets/images/country/canada.svg" className="flag-small"/></span> */}
                                <h4 className="card-testimonial-name">Dustin A. Morgan</h4>
                                <p><small>( Canada Work Permit )</small></p>
                            </div>
                        </div>
                       
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="card-testimonial card-testimonial-light">
                            
                            <div className="card-testimonial-img"><img src="./assets/images/card-testimonial-img-2.jpg" alt="" className="rounded-circle"/></div>
                                <div className="card-testimonial-content">
                                    <p className="card-testimonial-text">“Nulla facilisi. Phasellus maximus odio bibendum tortor scelerisque, quis fermentum nulla ornare. Praesent malesuada lorem nec mauris vulputate, viverra tempus felis consequat.”</p>
                                </div>
                                <div className="card-testimonial-info">
                                    {/* <span className="card-testimonial-flag"><img src="./assets/images/country/united-states-of-america.svg" className="flag-small"/></span> */}
                                    <h4 className="card-testimonial-name">Sandra M. Lebrun</h4>
                                    <p><small>( PR visa )</small></p>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="card-testimonial card-testimonial-light">
                               
                                <div className="card-testimonial-img"><img src="./assets/images/card-testimonial-img-3.jpg" alt="" className="rounded-circle"/></div>
                                    <div className="card-testimonial-content">
                                        <p className="card-testimonial-text">“Sed sollicitudin, ex id lacinia sagittis, lacus lacus ruNulla in ex ut orci pretium congue quis id nunc. trum lus nulla nunc rutrum est. Pellentesque sit amet elementum risus.”</p>
                                    </div>
                                    <div className="card-testimonial-info">
                                        {/* <span className="card-testimonial-flag"><img src="./assets/images/country/united-kingdom.svg" className="flag-small"/></span> */}
                                        <h4 className="card-testimonial-name">Roger N. Towle</h4>
                                        <p><small>( Student Visa )</small></p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
       
    )
}

export default Testimonial