import React, { Component } from 'react'
import axios from 'axios'
export class ExpertServices extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            paragraph:'',
            items:[]
       }

    }

    componentDidMount = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/page/expert-services`)
        .then(res => {
            //console.log(res.data)
            this.setState({
                paragraph:res.data.content.paragraph,
                items:res.data.content.items
            })
        })
        .catch(err => console.log(err))
    }
    
    render() {
        return (
            <div>
                <p>{this.state.paragraph}</p>
                    <ul class="check-circle listnone">
                        {this.state.items.map((item, index) => {
                            return  <li key={index}>{item}</li>
                        })}
                       
                        
                    </ul>
            </div>
        )
    }
}

export default ExpertServices
