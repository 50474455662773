import React, {useState} from 'react'
import axios from 'axios'
import { Button, Toast } from 'react-bootstrap'
import {authenticate, isAuth } from '../helpers/Auth'
import { Link, Redirect, useHistory } from "react-router-dom"


export function AdminLogin() {

    const [ formData, setFormData ] = useState({
        email:'',
        password:'',
        textChange:'Sign In'
    })
    const [show, setShow] = useState(false)
    const [errMessage, setErrMessage ] = useState('')
    const [infoMessage, setInfoMessage ] = useState('')

    let history = useHistory()
    const changeHandler = ( event ) => {
        let data = {...formData}
        data[event.target.name] = event.target.value
        setFormData(data)
       // console.log(formData)
     }

     const { email, password, textChange } = formData

     const handleSubmit = (event) => {
         event.preventDefault()
         setFormData({...formData, textChange:'Submitting...'})
         if(email && password){
                axios.post(`${process.env.REACT_APP_API_URL}/auth/login`,{
                    email,
                    password
                })
                .then(res => {
                    //console.log(res)
                    authenticate(res, () => {
                        setFormData({
                            ...formData,
                            email:'',
                            password:'',
                            textChange:'Submitted'
                        })
                    })
                    setInfoMessage(res.data.message)
                    //console.log(isAuth().role)
                    history.push('/admin')
                    isAuth() && isAuth().role === 'admin'
                    ? history.push('/admin')
                    : history.push('/admin');
                })
                .catch( err => {
                    setFormData({
                        ...formData,
                      
                        textChange:'Submit'
                    })
                    setErrMessage(err.response.data)
                    setTimeout(() => {
                        setErrMessage('')
                    },5000)
                    console.log(err)
                })
        }else{
                console.log("Email and Password Missing")
        }
         

     }

    return (
        <div className="container">
            
                
            <div className="row m-5">
                <div className="offset-md-2 col-md-8">
                <br/>
                 {isAuth() ? <Redirect to='/admin' /> : null}
                { (errMessage) && 
                    <div className="p-2 bg-danger text-white">{errMessage}</div>
                }
                { (infoMessage) && 
                    <div className="bg-success p-2 text-white">{infoMessage}</div>
                }
                <br/>
                    <fieldset>
                        <legend><h2 className="bg-default p-2">Nitro Admin Login </h2></legend>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Email address </label>
                                <input
                                 type="email"
                                 className="form-control"
                                 id="email"
                                 name = "email"
                                 aria-describedby="emailHelp"
                                 placeholder="Enter email" 
                                 onChange = { event => changeHandler(event)}
                                 required
                                 
                                 />
                                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                            </div>
                            <div className="form-group">
                                <label for="exampleInputPassword1">Password</label>
                                <input
                                 type="password"
                                 className="form-control"
                                 id="password"
                                 name="password"
                                 placeholder="Password"
                                 onChange = { event => changeHandler(event)}
                                 required
                                 />
                            </div>
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                <label className="form-check-label" for="exampleCheck1">Check me out</label>
                            </div>
                            <br/>
                            <button type="submit" className="btn btn-default">{textChange}</button>
                        </form>
                    </fieldset>
                </div>
            </div>
            {/* <div className="container">
        <h1>React Bootstrap Toast Example - ItSolutionStuff.com</h1>
  
        <Toast onClose={() => setShow(false)} show={show} delay={2000} autohide>
          <Toast.Header>
            <strong className="mr-auto">Bootstrap Toast</strong>
            <small>11 mins ago</small>
          </Toast.Header>
          <Toast.Body>This is simple Bootstrap Toast Example</Toast.Body>
        </Toast>
  
        <Button onClick={() => setShow(true)}>Click to Show Toast</Button>
  
    </div> */}
        </div>
    )
}


export default AdminLogin
