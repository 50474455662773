import React, { useState, useEffect } from 'react'
import axios from 'axios'
export function ContactForm() {

    const [contactData, setContactData ] = useState({
        name: '',
        mobileno: '',
        email:'',
        subject:'',
        message:''
    })
    const [data, setData] = useState(null)
    const changeHandler = (e) => {
        
        contactData[e.target.name] = e.target.value
        setContactData({...contactData})
        console.log(contactData)


    }

    const[ resMsg, setResMsg ] = useState(null)
    
    useEffect(async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/page/contactus-main`)
        .then( res => {
            //console.log(res.data)
            setData(res.data.content)
        })
        .catch(err => {
            console.log(err.data)
        })
    },[data])
    const onSubmitHandler = (e) => {

        e.preventDefault()

        axios.post(`${process.env.REACT_APP_API_URL}/user/contactus`,{
            name,
            mobileno,
            email,
            subject,
            message

        })
        .then( res => {
            setResMsg(res.data.message)
            setTimeout(() => {
                setResMsg('')
            },5000)
            setContactData({
                name: '',
                mobileno: '',
                email:'',
                subject:'',
                message:''
            })

        })
        .catch( err => {

            console.log(err)
        })
        //console.log(contactData)

    }

    const { name, mobileno, email, subject, message } = contactData
    return (
        <div>
            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-5 col-md-7 col-sm-12 col-12">
                            <div className="contact-form-head">
                                <h2 className="mb0">Leave a Message Here</h2>
                                <p>We will connect you to Nitro Consultant ASAP.</p>
                            </div>

                            {(resMsg) && 
                                <div className="p-2 round contact-form-head text-success">
                                
                                <p>{resMsg}....</p>
                            </div>
                            }
                            

                            <div className="contact-form mt30">
                                <form method="post" onSubmit={e => onSubmitHandler(e)} >
                                    <div className="form-group">
                                        <label for="name">Name <span className="required">*</span></label>
                                        <input 
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        placeholder="Your Name"
                                        onChange = { e => changeHandler(e)}
                                        value = {name}
                                        required />
                                    </div>
                                    <div className="form-group">
                                        <label for="mobileno">Mobile No <span className="required">*</span></label>
                                        <input 
                                        type="text"
                                        className="form-control"
                                        id="mobileno"
                                        name="mobileno"
                                        placeholder="Mobile No"
                                        onChange = { e => changeHandler(e)}
                                        value = {mobileno}
                                        required />
                                    </div>
                                    <div className="form-group">
                                        <label for="email">Email Address <span className="required">*</span></label>
                                        <input
                                         type="email"
                                         className="form-control"
                                         id="email"
                                         name="email"
                                         placeholder="Enter Email"
                                         onChange = { e => changeHandler(e)}
                                         value = {email}
                                         required />
                                    </div>
                                    <div className="form-group">
                                        <label for="subject">Subject</label>
                                        <input
                                         type="text"
                                         className="form-control"
                                         id="subject"
                                         name="subject"
                                         onChange = { e => changeHandler(e)}
                                         value = {subject}
                                         placeholder="Subject"
                                         
                                         />
                                    </div>
                                    <div className="form-group">
                                        <label for="message">Message</label>
                                        <textarea
                                         className="form-control"
                                          id="message"
                                          name="message"
                                          onChange = { e => changeHandler(e)}
                                          rows="3">{message}</textarea>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-default">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="offset-xl-1 col-xl-6 offset-lg-1 col-lg-5 col-md-5 col-sm-12 col-12">
                        <div dangerouslySetInnerHTML={{__html: data}} />
                            {/* <h2>Do you have any questions regarding immigration?</h2>
                            <h4 className="mb0">Phone</h4>
                            <p><i class="fas fa-phone"></i> 1800 102 4150</p>
                            <div className="row mb30">
                                <div className="col">
                                    <h4 className="mb0">Email</h4>
                                    <p><i class="fas fa-envelope-square"></i> contactus@nitroimmigration.com</p>
                                </div>
                            </div>
                            <div className="row mb20">
                                <div className="col mb10">
                                    <h4 className="mb0">Inquire with us</h4>
                                    <p><i class="fas fa-envelope-square"></i> info@nitroimmigration.com</p>
                                </div>
                                <div className="col mb10">
                                    <h4 className="mb0">Send your feedback</h4>
                                    <p><i class="fas fa-envelope-square"></i> support@nitroimmigration.com</p>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col">
                                    <h4 className="mb0">Head Office <span><img src="./assets/images/country/canada.svg" alt="" className="flag-xs"/></span></h4>
                                    <p><i class="fas fa-building"></i> 104-9507 120 ST DELTA BC V4C 6S3</p>
                                    
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col">
                                    <h4 className="mb0">Other Office Location <span><img src="./assets/images/country/india.svg" alt="" className="flag-xs"/></span> </h4>
                                    
                                    <p><i class="fas fa-building"></i> SCO: 13-14, 5th Floor, <br/>Main Block Silver Crown Plaza, Mithapur Rd, <br/> Jalandhar Punjab 144001, India</p>
                                   
                                    <p><i class="fas fa-phone"></i> +91-9888079280</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactForm