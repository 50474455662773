import React from 'react'
import FooterBlue from '../components/Footers/FooterBlue'
import HeaderWhite from '../components/Headers/HeaderLight/HeaderWhite'
import CountryListHero from '../components/Hero/CountryListHero'
import CountryListContent from '../components/Pages/CountryList/CountryListContent'

export function CountryList() {
    return (
        <div>
            <HeaderWhite/>
            <CountryListHero/>
            <CountryListContent/>
            <FooterBlue/>
        </div>
    )
}

export default CountryListHero
