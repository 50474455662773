import React, {useState, useEffect} from 'react'
import { getCookie } from '../helpers/Auth'
import { Toast } from 'react-bootstrap'
import axios from 'axios'
export function AddTestimonial(props) {
    const [testimonial, setTestimonial] = useState({
        description:'',
        from:'',
        topic:'',
        display:''
    })
    const [token, setToken] = useState(null)
    const [errMessage, setErrMessage ] = useState('')
    const [infoMessage, setInfoMessage ] = useState('')
    const [editMode, setEditMode] = useState(false)
    const[testimonialId, setTestimonialId] = useState(null)
    const loadData = async (id) => {
        console.log(id)
        await axios.get(`${process.env.REACT_APP_API_URL}/admin/testimonial/${id}`,
            config
        )
        .then( res => {
            console.log(res.data)
            setTestimonial(res.data.testimonial)
            
        })
        .catch(err => {
            setErrMessage(err.message)

        })

    }
    useEffect(() => {
        let tk = getCookie('token')

        if(tk){
            setToken(tk)
        }
        let id = props.match.params.id
        if(id){
            setTestimonialId(id)
            setEditMode(true)
            loadData(id)
        }

    },[])

    const config = {
        headers: {
            
            'Authorization': 'Bearer ' + token
        }
    }
    const changeHandler = (e) => {

        
        testimonial[e.target.name] = e.target.value
        setTestimonial({...testimonial})
       
    }
    const { from, description, topic, display } = testimonial
    const submitHandler = (e) => {
        e.preventDefault()
        console.log(testimonial)
        axios.post(`${process.env.REACT_APP_API_URL}/admin/testimonial`,{            
            description,
            from,
            topic
        }, config )
        .then( res => {
            
            console.log(res.data)
            setInfoMessage(res.data.message)
        })
        .catch( err => {
            console.log(err.message)
            setErrMessage(err.message)
        })
       
    }

    const updateHandler = (e, id) => {
        e.preventDefault()
        axios.put(`${process.env.REACT_APP_API_URL}/admin/testimonial/${id}`,{
          
            description,
            from,
            topic,
            display

            },config
        )
        .then(res => {
            setInfoMessage(res.data.message)
            
        })
        .catch( err => {
            setErrMessage(err.message)
        })
    }

    return (
        <div className="container">
            <h1 className="text-center">Add Testimonial</h1>
            <form onSubmit={(editMode) ? e => updateHandler(e, testimonialId): e => submitHandler(e)} action="#">
                { (errMessage) && 
                    <div className="bg-danger p-2 text-white">{errMessage}</div>
                }
                { (infoMessage) && 
                    <div className="bg-success p-2 text-white">{infoMessage}</div>
                }

                <div className="row  p-2">

                    <div className="col-md-3 text-right">
                        <label className="fomr-control">Person Name</label>
                    </div>

                    <div className="col-md-9">
                        <input
                            type="text"
                            className="form-control"
                            name="from"
                            id="from"
                            value = {from}
                            onChange={e => changeHandler(e)}
                            placeholder="Name of the person"
                            required

                        />
                    </div>
                </div>
                <div className="row p-2">
                    <div className="col-md-3 text-right">
                        <label className="fomr-control">Write Content</label>
                    </div>

                    <div className="col-md-9">
                        <textarea
                            rows="3"
                            cols="50"
                            type="text"
                            name="description"
                            id="description"
                            className="form-control"
                            onChange={e => changeHandler(e)}
                            value={description}
                            placeholder="Write testimonial content"
                            required

                        ></textarea>
                    </div>
                </div>

                <div className="row p-2">
                    <div className="col-md-3 text-right">
                        <label className="fomr-control">Topic (Study Visa, PR, Work Permit Etc)</label>
                    </div>

                    <div className="col-md-9">
                         <input
                            type="text"
                            className="form-control"
                            name="topic"
                            id="topic"
                            value = {topic}
                            onChange={e => changeHandler(e)}
                            placeholder="Topic"
                            required

                        />  
                    </div>
                </div>
                { (editMode) && <div className="row p-2">
                    <div className="col-md-3 text-right">
                        <label className="fomr-control">Do you want to display on website?</label>
                    </div>

                    <div className="col-md-9">
                           <select id="display" name="display" onChange={e => changeHandler(e)}>
                               <option value="no" selected={ (!display) && 'selected' }>No</option>
                               <option value="yes"  selected={ (display) && 'selected' }>Yes</option>
                              
                           </select>

                    </div>
                </div> }


                <div className="row  p-2">
                    <div className="col-md-3 text-right">

                    </div>

                    <div className="col-md-9">
                        <input
                            type="submit"
                            value={ (editMode)? 'Update' : 'Save'}
                            className="form-control btn bg-primary btn-primary"

                        />
                    </div>
                </div>
            </form>
            <div className="container text-right">
                 <a href="/admin/testimonialList" className="btn btn-default btn-sm text-right">Go to Testimonial List</a>
            </div>
           

        </div>
    )
}
export default AddTestimonial