import React from 'react'

export function BlogsHero() {
    return (
        <div>
            <div className="page-header bg-background-imgVisas"  >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <h1 className="page-title">Blogs </h1>
                            {/* <p className="page-description">Making an excellent website header design is very important. Page header is an excellent way to deliver a message. Make the website header active.</p> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="slanting-pattern"></div>
        </div>
    )
}

export default BlogsHero