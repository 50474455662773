import React , {useState, useEffect, useRef } from 'react'
import { educationScore } from '../../helpers/CRSHelper'
import AgeCRS from './AgeCRS'
import CanadianRelative from './CanadianRelative'
import CertQualification from './CertQualification'
import EducationCRS from './EducationCRS'
import LanguageScore from './LanguageScore'
import LMIACalc from './LMIACalc'
import NominationCert from './NominationCert'
import PartnerComing from './PartnerComing'
import ResultCard from './ResultCard'
import SpouseComp from './SpouseComp'
import WorkExperience from './WorkExperience'

export function CRSCalc() {

    const maritalStatusArray = ['Annulled Marriage', 'Common-Law', 'Divorced / Seperated', 'Legally Seperated', 'Married', 'Never Married / Single', 'Widowed']
    const [partner, setPartner] = useState(false) // if true means partner score will be availed like married
    const [age, setAge] = useState({points:0, show:false})
    const [education, setEducation] = useState({points:0, show:false, level:null})
    const [partnerFromCanada, setPartnerFromCanada] = useState(null) // is Spouse from Canada?
    const [comingWithPartner, setComingWithPartner] = useState(null) // is Spouse Coming along with you if not from canada?
    const [canadianDegreePoints, setCanadianDegreePoints] = useState(0)
    const [firstLangScore, setFirstLangScore] = useState({points:0, show:false, type:null, clb:null})
    const [otherLangScore, setOtherLangScore] = useState({points:0, show:false, type:null})
    const [workExpScore, setWorkExpScore] = useState({points:0, show:false, type:null})
    const [foreignExpScore, setForeignExpScore] = useState({points:0, show:false, type:null, level:null})
    const [certificateOfQualification, setCertificateOfQualification] = useState({points:0, show:false})
    const [lmiaScore, setLmiaScore] = useState({points:0, show:false, type:null})
    const [nominationCert, setNominationCert] =  useState({points:0, show:false})
    const [siblingInCanada, setSiblingInCanada] = useState({points:0, show:false})
    const [spousePoints, setSpousePoints] = useState({educationPoints:0, languagePoints:0, canadaExpPoints:0, show:false})
    const [score, setScore] = useState(0)
    const [isDisabled, setIsDisabled] = useState("disabled")
    const [coreHumanFactors, setCoreHumanFactors] = useState({ 
        age:null,
        educationLevel:null,
        firstOfficialLang:null,
        secondOfficialLang: null,
        canadianWorkExp: null
    })
    
    const [skillTransferabilityFactors, setSkillTransferabilityFactors] = useState({})
    const [additionalFactors, setAdditionalFactors] = useState({})

    const [result, setResult] = useState({score:0, show:false})

    useEffect(() => {
        
       // calculateTotalScore()
     

    },[age,education, canadianDegreePoints,firstLangScore, otherLangScore, workExpScore, foreignExpScore, certificateOfQualification, lmiaScore, nominationCert, siblingInCanada, spousePoints, coreHumanFactors])
    
    const onChangeAgeHandler = async (score) => {
         let newAgeObj = {...age,points:score}
         setAge(newAgeObj)
        //  setAge(state => {
        //     // console.log(state)
        //      return state
        //  })
        
         setEducation({...education, show:true})
        
     //   calculateTotalScore()0
    }
    const selectChangeHandler = (e) => {
        if(e.target.value == 1 || e.target.value == 4){
            setPartner(true)
            setAge({...age, show:false})
            setEducation({...education, show:false})
            setScore(0)
           // console.log("Test")

        }else{
            setPartner(false)
            setAge({...age, show:true})
            setEducation({...education, show:false})
            setScore(0)
        }
       // console.log(partner)


    }


    const partnerComingHandler = (val) => {
            setComingWithPartner(val)
            if(val == "no"){
                setPartner(false) // to get benefits of coming to canada alone
            }
            if(val){
                setAge({...age, show:true})
            }
            
            
    }

    const partnerFromCanadaHandler = (val) => {
        setPartnerFromCanada(val)  
        if(val == "yes"){
            setPartner(false) // To get benefits of coming to canada alone
        }
        if(val == "yes"){
            setAge({...age, show:true})
        } 
       
    }

    const calculateTotalScore = () => {
        let points = parseInt(age.points) + parseInt(education.points) + canadianDegreePoints + parseInt(firstLangScore.points) + parseInt(otherLangScore.points) + workExpScore.points + foreignExpScore.points + certificateOfQualification.points + lmiaScore.points + parseInt(nominationCert.points) + parseInt(siblingInCanada.points)
        setScore(points)
        //console.log(firstLangScore)
        
    }

    const setEducationScore = (val, val2) => {
        setEducation({...education, points:val, level:val2})
        
        console.log(age)
    }

    const setEngLangScore = (val, testType) => {
        setFirstLangScore({...firstLangScore, points:val, type:testType})
        
    }

    const setOtherOfficialLangScore = (val, testType) => {
        setOtherLangScore({...otherLangScore, points:val, type:testType})
    }

    const setCanadianSkilledWorkExp = (val) => {
        setWorkExpScore({...workExpScore, points:val})
    }

    const setForeignSkilledExpScore = (val, val2) => {

        setForeignExpScore({...foreignExpScore,points:val, level:val2})
    }

    const setLmiaScoreObj = (obj) => {
        setLmiaScore({...lmiaScore, points:obj.score, type:obj.type})
    }

    const setNominationCertScore = (val) => {
        setNominationCert({...nominationCert, points:val})
    }

    const setCanadianRelativeScore = (val) => {
        setSiblingInCanada({...siblingInCanada, points:val})
        setIsDisabled("")
        
    }

    const setSpouseScore = (obj) => {
        console.log(obj)
        setSpousePoints({
            ...spousePoints,
            educationPoints:obj.edu,
            canadaExpPoints:obj.exp,
            languagePoints:obj.lang
        })

       setIsDisabled("")
    }
    
   
    const compileResult = () => {

        // Core Human Factors
        setCoreHumanFactors({ 
            
            age: parseInt(age.points),
            educationLevel: parseInt(education.points),
            firstOfficialLang:parseInt(firstLangScore.points),
            secondOfficialLang: parseInt(otherLangScore.points),
            canadianWorkExp: parseInt(workExpScore.points)
        })

        console.log(coreHumanFactors)
        // Spouse or Common Law Factors
            // spouseFactors = spousePoints
            //console.log(spouseFactors)
        // CRS – C. Skill transferability factors (Maximum 100 points for this section)

            let eduScoreCLB = 0
            //CLB 7  Points for CLB 7 or more on all first official language abilities, with one or more under CLB 9
            if(firstLangScore.points >=64 && firstLangScore.points < 116 && partner){
                    if(education.level == 8 || education.level == 7 || education.level == 6 ){
                        eduScoreCLB = 25
                    }

                    if(education.level == 5 || education.level == 4 || education.level == 3){
                        eduScoreCLB = 13
                    }

                    if(education.level == 2 || education.level == 1){
                        eduScoreCLB = 0
                    }

            }

             //CLB 9 or Above  Points for CLB 9 or more on all first official language abilities, with one or more under CLB 9
            if(firstLangScore.points >= 116 && partner){
               
                if(education.level == 8 || education.level == 7 || education.level == 6 ){
                    eduScoreCLB = 50
                   
                }

                if(education.level == 5 || education.level == 4 || education.level == 3){
                    eduScoreCLB = 25
                }

                if(education.level == 2 || education.level == 1){
                    eduScoreCLB = 0
                }

            }

            console.log("Worked"+eduScoreCLB)

            //CLB 7  Points for CLB 7 or more on all first official language abilities, with one or more under CLB 9
            if(firstLangScore.points >=68 && firstLangScore.points < 124 && !partner){
                if(education.level == 8 || education.level == 7 || education.level == 6){
                    eduScoreCLB = 25
                }

                if(education.level == 5 || education.level == 4 || education.level == 3){
                    eduScoreCLB = 13
                }

                if(education.level == 2 || education.level == 1){
                    eduScoreCLB = 0
                }

            }

             //CLB 9 or Above  Points for CLB 9 or more on all first official language abilities, with one or more under CLB 9
            if(firstLangScore.points >= 124 && !partner){

                if(education.level == 8 || education.level == 7 || education.level == 6 ){
                    eduScoreCLB = 50
                }

                if(education.level == 5 || education.level == 4 || education.level == 3){
                    eduScoreCLB = 25
                }

                if(education.level == 2 || education.level == 1){
                    eduScoreCLB = 0
                }

            }

            let workExpScoreEdu = 0
            let foreignExpCanadaExp =0 
            // For One year Canadian Work Experience 
            if(workExpScore.points == 35 || workExpScore.points == 40){
                 console.log("Domew"+workExpScore.points)
                if(education.level == 8 || education.level == 7 || education.level == 6){
                        workExpScoreEdu = 25
                }

                if(education.level == 5 || education.level == 4 || education.level == 3){
                    workExpScoreEdu = 13
                }

                if(education.level == 2 || education.level == 1){
                    workExpScoreEdu = 0
                }

                if(foreignExpScore.level == 1){
                    foreignExpCanadaExp = 13
                }

                if(foreignExpScore.level == 2){
                    foreignExpCanadaExp = 25
                }


            }
            //For more than two years canadian Experience
            if(workExpScore.points >= 46){

                if(education.level == 8 || education.level == 7 || education.level == 6 ){
                    workExpScoreEdu = 50
                }

                if(education.level == 5 || education.level == 4 || education.level == 3){
                    workExpScoreEdu = 25
                }

                if(education.level == 2 || education.level == 1){
                    workExpScoreEdu = 0
                }

                if(foreignExpScore.level == 1){
                    foreignExpCanadaExp = 25
                }
                if(foreignExpScore.level == 2){
                    foreignExpCanadaExp = 50
                }
                
            }
            console.log("Level Of Education:" +education.level)
            console.log("First Lang Points:"+firstLangScore.points)

         setSkillTransferabilityFactors({
            education:{officialLangEdu: eduScoreCLB, canadianWorkExpEdu:workExpScoreEdu },
            foreignExp:{foreignWorkExpCLB:foreignExpScore.points, canadianForeignExp:foreignExpCanadaExp},
            certOfQual:certificateOfQualification.points
        })   

       // console.log(skillTransferabilityFactors)


        // Additional Points for PR
        console.log("Other Lang "+otherLangScore.points + firstLangScore.points)
        let firstLang = null
        if(firstLangScore.type == 'ielts' || firstLangScore.type == 'celpip'){
            firstLang =  'English'
        }else{
            firstLang =  'French'
        }
        

        let frenchScore = 0
       
        if(firstLang == "English" && otherLangScore.points >= 12 && firstLangScore.points < 24){
            frenchScore = 25
        }

        if(firstLang == "English" && otherLangScore.points >= 12 && firstLangScore.points >= 24){
            frenchScore = 50
        }
       //Scored NCLC 7 or higher on all four French language skills and scored CLB 4 or lower in English (or didn’t take an English test)	

        if(firstLang == "French" && (otherLangScore.points >=0 && otherLangScore.points < 4) && firstLangScore.points >= 64 && partner){
            frenchScore = 25
        }
        if(firstLang == "French" && (otherLangScore.points >=0 && otherLangScore.points < 4) && firstLangScore.points >= 68 && !partner){
            frenchScore = 25
        }
        // Scored NCLC 7 or higher on all four French language skills and scored CLB 5 or higher on all four English skills
        if(firstLang == "French" && otherLangScore.points >=4 && firstLangScore.points >= 64 && partner){
            frenchScore = 50
        }
        if(firstLang == "French" && otherLangScore.points >=4 && firstLangScore.points >= 68 && !partner){
            frenchScore = 50
        }

        if(firstLang == "French" && otherLangScore.points >= 12 && firstLangScore.points >= 24){
            frenchScore = 50
        }   
//        console.log("French Score" + frenchScore)

         setAdditionalFactors({
            provincialNomination:parseInt(nominationCert.points),
            jobOffer: parseInt(lmiaScore.points),
            studyInCanada:parseInt(canadianDegreePoints),
            siblingsCanada:parseInt(siblingInCanada.points),
            frenchLanguageSkills:parseInt(frenchScore)

        })

        setResult({...result, show:true})
    }
    return (
        
        <div>
            <div className="content">
                <div className="container">
                     <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <h1>Comprehensive Ranking System (CRS) Criteria – Express Entry</h1>
                            <p>
                                This tool will help you calculate your Comprehensive Ranking System (CRS) score based on the answers you provide below. The CRS is a points-based system that we use to assess and score your profile and rank it in the Express Entry pool. It’s used to assess your:
                            </p>
                            <ul>
                                <li>Skills</li>
                                <li>Education</li>
                                <li>Language Ability</li>
                                <li>Work Experience</li>
                                <li>Other Factors</li>
                            </ul>

                            <br />
                        </div>

                     </div>
                    <div className="post-holder">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="post-content post-quote">
                                    <blockquote>Note If your spouse or partner is not coming with you to Canada, or they are a Canadian citizen or permanent resident, you will earn points as if you don’t have a spouse or partner.

</blockquote>
                                </div>
                                {/* <div className="post-header">
                                    <p className="meta"><span className="meta-date">1 Feb, 2018</span> <span className="meta-author">By <a href="#" className="meta-link">Visapress</a></span> <span className="meta-comments"><a href="#" className="meta-link">Comments</a></span></p>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                                    <div className="form-group">
                                        <label for="name">1.) What is the marital status? <span className="required">*</span></label>
                                        <select id="maritalStatus" name="maritalStatus" className="form-control round" onChange={e => selectChangeHandler(e)}>
                                            <option value=''>Please Select...</option>
                                            {maritalStatusArray.map((element, idx) => {
                                               return <option value={idx}>{element}</option>
                                            })}
                                        </select>
                                       
                                    </div>
                    </div>
                    {(partnerFromCanada || comingWithPartner || partner) && <div className="row">
                            <PartnerComing partner={partner} partnerComingHandler={partnerComingHandler} partnerFromCanadaHandler={partnerFromCanadaHandler}/>                                            
                    
                    
                    </div>}
                    { (partner && age.show) && <div className="row">
                        <div className="form-group">
                                            <label for="name">3.) How old are you? <span className="required">*</span></label>
                                            <AgeCRS partner={partner} onChangeAge={onChangeAgeHandler}/>
                                        
                            </div>
                                            
                    </div>}
                    { (!partner && age.show) && <div className="row">
                        <div className="form-group">
                                            <label for="name">3.) How old are you? <span className="required">*</span></label>
                                            <AgeCRS partner={partner} onChangeAge={onChangeAgeHandler}/>
                                        
                            </div>
                                            
                    </div>}

                    { (education.show) && <div className="row">
                             <EducationCRS  setEducationScore={setEducationScore} partner={partner} setCanadianDegreePoints={score => setCanadianDegreePoints(score)} setEngScoreShowStatus={val => setFirstLangScore({points:0, show:val})}/>
                   </div> }

                   {
                       (firstLangScore.show) && <div className="row">
                            <LanguageScore partner={partner} setEngLangScore={setEngLangScore} setOtherOfficialLangScore={setOtherOfficialLangScore} setWorkExpShowStatus={val => setWorkExpScore({points:0, type:null, show:val})}/>
                        </div>
                   }

                   {
                       (workExpScore.show) && <div className="row">
                           <WorkExperience  partner={partner} setCanadianSkilledWorkExp = {setCanadianSkilledWorkExp} setForeignSkilledExpScore={setForeignSkilledExpScore} firstLangScore={firstLangScore.points} setCertOfQualShow = {val => setCertificateOfQualification({...certificateOfQualification, show:val})}/>
                       </div>
                   }
                   
                   {
                       (certificateOfQualification.show) && <div className="row">
                           <CertQualification setCertOfQual={val => setCertificateOfQualification({...certificateOfQualification, points:val})} setLmiaShow={val => setLmiaScore({...lmiaScore, show:val})}/>
                       </div>
                   }
                   {
                       (lmiaScore.show) && <div className="row">
                           <LMIACalc setLmiaScoreObj={setLmiaScoreObj} setNominationCertShow={val => setNominationCert({...nominationCert, show:true })}/>
                        </div>
                   }

                   {
                       (nominationCert.show) && <div className="row">

                           <NominationCert setNominationCertScore={setNominationCertScore} setRelativeInCanadaStatus={val => setSiblingInCanada({...siblingInCanada, show:val})}/>
                           </div>
                   }
                   {
                       (siblingInCanada.show) && <div className="row">
                           <CanadianRelative setCanadianRelativeScore={setCanadianRelativeScore} partner={partner} showSpouseComp = {val => setSpousePoints({...spousePoints, show:val})}/>
                       </div>
                   }
                   {
                       (spousePoints.show) && <div className="row">
                           <SpouseComp  setSpouseScore={setSpouseScore}/>
                       </div>
                   }
                   <br/>
                        {/* <div className="container">
                            <div className="row bg-light">
                                    <div className="form-group row  align-middle">
                                                                <label for="inputPassword" className="col-sm-5 col-form-label"><strong>Total points Based on CRS <span className="text-danger">*</span></strong></label>
                                                                <div className="col-sm-2 ">
                                                                    <input type="text" readonly className="form-control rounded bg-warning" id="resultScore" name="resultScore" value={score} placeholder=""/>                          
                                                                  <button className="btn btn-default" onClick={calculateTotalScore}>Compute</button>  
                                                                </div>
                                                                <div className="col-sm-5 ">
                                                                    <a href="/contactus" className="btn btn-default">Contact Us For Further Information</a>
                                                                </div>
                                    </div>
                            </div>
                        </div> */}

                        <div className="col-sm-5 ">
                                <button   onClick={compileResult} disabled={isDisabled}  className="btn btn-default" title="Fill the form to enable the button">Calculate Score</button>
                        </div>
                        {
                                (result.show) && <div className="row">
                                      
                                       <ResultCard  coreFactors={coreHumanFactors} spouseFactors={spousePoints} skillTransferabilityFactors={skillTransferabilityFactors} additionalFactors={additionalFactors}/>
                                </div>
                        }
                </div>
            </div>
        </div>
    )
}

export default CRSCalc