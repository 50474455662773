import React, { useState, useEffect } from 'react'
import { getCookie } from '../helpers/Auth'

import axios from 'axios'

export function AddPost(props) {
    const [post, setPost ] = useState({
        title:'',
        description:'',
        display:''
    })
    const [token, setToken] = useState(null)
    const [errMessage, setErrMessage ] = useState('')
    const [infoMessage, setInfoMessage ] = useState('')
    const [editMode, setEditMode] = useState(false)
    const [postId, setPostId ] = useState(null)
    const config = {
        headers: {
            
            'Authorization': 'Bearer ' + token
        }
    }
    const loadData = async (id) => {
        console.log(id)
        await axios.get(`${process.env.REACT_APP_API_URL}/admin/post/${id}`,
            config
        )
        .then( res => {
            console.log(res.data)
            setPost(res.data.post)
            
        })
        .catch(err => {
            setErrMessage(err.message)

        })

    }
    useEffect(() => {
        let tk = getCookie('token')

        if(tk){
            setToken(tk)
        }
        let id = props.match.params.id
        if(id){
            setPostId(id)
            setEditMode(true)
            loadData(id)
        }

    },[])
    

   
    const changeHandler = (e) => {

        
        post[e.target.name] = e.target.value
        setPost({...post})
       // console.log(post)
    }

    const { title, description, display } = post
    const submitHandler = (e) => {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_API_URL}/admin/post`,{
            title,
            description
        }, config )
        .then( res => {
            
            console.log(res.data)
            setInfoMessage(res.data.message)
        })
        .catch( err => {
           // console.log(err.message)
            setErrMessage(err.response.data)
        })
        console.log("Worked")
    }

    const updateHandler = (e, id) => {
        e.preventDefault()
        axios.put(`${process.env.REACT_APP_API_URL}/admin/post/${id}`,{
          
                title,
                description,
                display
            },config
        )
        .then(res => {
            setInfoMessage(res.data.message)
            
        })
        .catch( err => {
            setErrMessage(err.message)
        })
    }
    return (
        <div className="container">
            <h1 className="text-center">Add Recent Update Post</h1>
            <form onSubmit={(editMode) ? e => updateHandler(e, postId): e => submitHandler(e)} action="#">
                { (errMessage) && 
                    <div className="bg-danger p-2 text-white">{errMessage}</div>
                }
                { (infoMessage) && 
                    <div className="bg-success p-2 text-white">{infoMessage}</div>
                }

                <div className="row  p-2">

                    <div className="col-md-3 text-right">
                        <label className="fomr-control">Title of the Post</label>
                    </div>
                    
                    <div className="col-md-9">
                     <input
                            type="text"
                            className="form-control"
                            name="title"
                            id="title"
                            value = {title}
                            onChange={e => changeHandler(e)}
                            placeholder="Specify the title of your post"
                            required

                        />
                    </div>
                </div>
                <div className="row p-2">
                    <div className="col-md-3 text-right">
                        <label className="fomr-control">Short Description of the Post (upto 100 Words)</label>
                    </div>

                    <div className="col-md-9">
                        <textarea
                            rows="3"
                            cols="50"
                            type="text"
                            name="description"
                            id="description"
                            className="form-control"
                            onChange={e => changeHandler(e)}
                            value={description}
                            placeholder="Specify the short description of your post"
                            required

                        ></textarea>
                    </div>
                </div>

                { (editMode) && <div className="row p-2">
                    <div className="col-md-3 text-right">
                        <label className="fomr-control">Do you want to display on website?</label>
                    </div>

                    <div className="col-md-9">
                           <select id="display" name="display" onChange={e => changeHandler(e)}>
                               <option value="no" selected={ (!display) && 'selected' }>No</option>
                               <option value="yes"  selected={ (display) && 'selected' }>Yes</option>
                              
                           </select>

                    </div>
                </div> }

                <div className="row  p-2">
                    <div className="col-md-3 text-right">

                    </div>

                    <div className="col-md-9">
                        <input
                            type="submit"
                            value={ (editMode)? 'Update' : 'Save'}
                            className="form-control btn bg-primary btn-primary"

                        />
                    </div>
                </div>
            </form>

            <div className="container text-right">
                 <a href="/admin/postList" className="btn btn-default btn-sm text-right">Go to Post List</a>
            </div>
           

        </div>
    )
}

export default AddPost