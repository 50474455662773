import React, {useState, useEffect} from 'react'
import axios from 'axios'
export function WorkPermit() {
    const [content, setContent] = useState(null)
    const loadData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/page/work-permit`)
          .then(res => {
  
              console.log("On load: "+res.data.content)
              setContent(res.data.content)
          })
          .catch(err => console.log(err.message))
      }
  
      useEffect(() => {
         loadData()
      },[])

    return (
        <div>
            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="content-area">
                                <div dangerouslySetInnerHTML={{__html: content}} />
                                <br/>
                                <img src={`${process.env.PUBLIC_URL}` + "/assets/img/work-permit.jpg"} height="320" alt="" className="img-fluid mb30" />
                                
                                
                            </div>
                            <a href="/contactus" className="btn btn-default">For Further Information Contact Us</a> | <a href="/visas#study-visa" className="btn ">Go to Top</a>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WorkPermit