import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { HashLink as Link } from 'react-router-hash-link'
export  function Footer() {
    const [emailId, setEmailId] = useState(null)
    const [errMessage, setErrMessage ] = useState('')
    const [infoMessage, setInfoMessage ] = useState('')
    const changeHandler = (e) => {

        setEmailId(e.target.value)
    }

    const submitHandler = (e) => {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_API_URL}/user/subscribe`,{email:emailId})
        .then(res => {
            //console.log(res.data)
            setInfoMessage(res.data.message)
        })
        .catch(err => {
           // console.log(err.message)
           setErrMessage(err.message)
        })

    }

    return (
        <div>
            <div class="footer-dark text-left">
                    
                    <div class="container">
                        <div class="row">
                            
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-6">
                                <div class="widget-footer">
                                    <h3 class="widget-title">Services</h3>
                                    <ul class="listnone arrow-footer">
                                            <li><Link to="/visas#study-visa">Student Visa</Link></li>
                                            <li><Link to="/visas#work-permit">Work Permit</Link></li>                                            
                                            <li><Link to="/visas#express-entry">Express Entry</Link></li>
                                            <li><Link to="/visas#lmia-permit">LMIA</Link></li>
                                            <li><Link to="/visas#family-sponsorship">Family Sponsorship</Link></li>
                                            <li><Link to="/visas#pnp">Provincial Nominee Program</Link></li>
                                            <li><Link to="/visas#tourist-visa">Tourist & Visitor Visa</Link></li>
                                            <li><Link to="/visas#child-care">Home Child Care Nominee Pilot Program</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-3 col-6">
                                <div class="widget-footer">
                                    <h3 class="widget-title">Contact Us</h3>
                                    <strong>Business Address</strong>
                                    <address>104-9507 120 ST

                                        <br/> DELTA BC V4C 6S3</address>
                                        <p>+1(604) 584-4443</p>
                                        <strong>Business Location</strong>
                                        <p>104 9507 120 ST</p>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-2 col-6">
                                <div class="widget-footer widget-social">
                                    <h3 class="widget-title">Connect</h3>
                                    <ul class="listnone">
                                        <li><a href="#"><i class="fa fa-facebook social-icon"></i> Facebook</a></li>
                                        {/*<li><a href="#"><i class="fa fa-twitter social-icon"></i> Twitter</a></li>
                                        <li><a href="#"><i class="fa fa-instagram social-icon"></i> Instagram</a></li>
                                        <li><a href="#"><i class="fa fa-youtube social-icon"></i> Youtube</a></li>
                                        <li><a href="#"><i class="fa fa-linkedin social-icon"></i> Linked In</a></li>*/}
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                <div class="widget-footer">
                                    <h3 class="widget-title">Subscribe For Newletter</h3>
                                    <p>Sign up for our Newsletter and join us on the path to success.</p>
                                    <p><small>{(infoMessage) && <span className="text-success">{infoMessage}</span>}</small></p>
                                    <p><small>{(errMessage) && <span className="text-danger">{errMessage}</span>}</small></p>
                                    
                                    <form onSubmit={e => submitHandler(e)}>
                                        <div class="form-row">
                                            <div class="form-group col-md-12">
                                                <label for="newsletteremail" className="text-white">Email Address</label>
                                                <input 
                                                type="email"
                                                class="form-control"
                                                id="newsletteremail"
                                                name="newsletteremail"
                                                placeholder="Enter Email Address"
                                                required
                                                onChange = {e => changeHandler(e)}
                                                />
                                            </div>
                                        </div>
                                        <button type="submit" class="btn btn-default">Subscribe</button>
                                    </form>
                                </div>
                                <div class="widget-footer">
                                        <img src={`${process.env.PUBLIC_URL}`+"/assets/img/RCIC_LOGO.png"} alt="Nitro Immigration and Visa Consulting Website Template" className="img-fluid" height="150" width="150"/>
                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="tiny-footer-dark">
                    
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <p>Copyright © 2021 <a href="#">Nitro Canadian Immigration and Visa Consulting Ltd. </a>. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default Footer
