import React, {useState, useRef, useEffect} from 'react'
import axios from 'axios'
import { calculateAnnualWageBcJobOffer, skillLevelBcJobOffer } from '../../helpers/Helper'
export default function BcPNP() {
    const [formBcPoints, setFormBcPoints ] = useState({

        bcJobSkillLevel:0,    
        bcBonusOccupation:0,
        bcLabourMarket:0,
        currentlyWorkingBC:0,
        bcJobOfferPoints:0,
        empArea:0,
        workExpBcJobOffer:0,
        directCanadaExp:0,
        educationPoints:0,
        educationInBc:0,
        educationOutsideBc:0,
        eduCredentialAssessment:0,
        tradeCertificateAssessment:0,
        clbScore:0,
        
        }
       
    )
    const [content, setContent] = useState(null)
    const loadData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/page/bc-calc`)
          .then(res => {
  
            //  console.log("On load: "+res.data.content)
              setContent(res.data.content)
          })
          .catch(err => console.log(err.message))
      }
  
      useEffect(() => {
         loadData()
      },[])

    const [ score, setScore ] = useState(0)
    const [ hourlyWageBc, setHourlyWageBc ] = useState(0)
    const [ weekHoursBc, setWeekHoursBc ] = useState(0)
    const [ bcJobSkillType, setBcJobSkillType ] = useState(0)
    const [ eduCompletedOutsideBc, setEduCompletedOutsideBc ] = useState(0)
    
    const sumJsonObject = (obj) => {
        //console.log(Object.values(obj).reduce((acc,b) => parseInt(acc) +parseInt(b) ))
        return  Object.values(obj).reduce((acc,b) => parseInt(acc)+parseInt(b),0)
     }

    const changeHandler = (e) => {
        //let data = {...formBcPoints}
        if(e.target.name === 'bcJobSkillLevel'){
            //console.log(skillLevelBcJobOffer.find( o => o.type === e.target.value ))
            
            formBcPoints[e.target.name] = skillLevelBcJobOffer.find( o => o.type === e.target.value ).points
            //Logic to include bonus points
            if(e.target.value === 'O'){
                setBcJobSkillType(1)
            }else{
                formBcPoints['bcBonusOccupation'] = 0
                setBcJobSkillType(0)
            }

        }
       
        if(e.target.name === 'hourlyWageBc'){
            setHourlyWageBc(e.target.value)
        }
        if(e.target.name === 'weekHoursBc'){
            setWeekHoursBc(e.target.value)
        }

        if(e.target.name === 'weekHoursBc'){
            //console.log(calculateAnnualWageBcJobOffer( hourlyWageBc, weekHoursBc ))
           formBcPoints['bcJobOfferPoints'] = calculateAnnualWageBcJobOffer( hourlyWageBc, e.target.value )
        }

        if(e.target.name === 'educationInBc'){
          
            if(e.target.value === '0'){
                //console.log("Test"+eduCompletedOutsideBc)
                setEduCompletedOutsideBc(1)
                
            }else{

                setEduCompletedOutsideBc(0)
                formBcPoints['educationOutsideBc'] = 0
            }
        }

        if(e.target.name !== 'hourlyWageBc' && e.target.name !== 'weekHoursBc' && e.target.name !== 'bcJobSkillLevel' ){
            formBcPoints[e.target.name] = e.target.value
        }
        
        
        
       // console.log(formBcPoints)
       // setFormBcPoints(data)
        setScore(sumJsonObject(formBcPoints))
        
    }

    return (
           

        <div>
            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div dangerouslySetInnerHTML={{__html: content}} />     
                             <br/>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div id="accordion">
                                <div className="card-accordion">
                                    <div className="card-accordion-header bg-primary" id="headingOne">
                                        <h5 className="mb-0">
                                            <button className="accordion-btn text-white" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                   1. Skill Level of the B.C Job Offer:
                                    </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div className="card-accordion-body">
                                            <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-6 col-form-label">Skill Level of the B.C. Job Offer*</label>
                                                <div className="col-sm-6" >
                                                    <div className="form-check form-check-inline">
                                                    <input
                                                     className="form-check-input"
                                                     type="radio" name="bcJobSkillLevel"
                                                     id="bcJobSkillLevel"
                                                     value="O"
                                                     onChange = {event => changeHandler(event)}
                                                     
                                                     />
                                                    <label className="form-check-label" for="inlineRadio1">NOC O</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                    <input
                                                     className="form-check-input"
                                                     type="radio" name="bcJobSkillLevel"
                                                     id="bcJobSkillLevel"
                                                     value="A"
                                                     onChange = {event => changeHandler(event)}
                                                     
                                                     />
                                                     <label className="form-check-label" for="inlineRadio2">NOC A</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                    <input
                                                     className="form-check-input"
                                                     type="radio" name="bcJobSkillLevel"
                                                     id="bcJobSkillLevel"
                                                     value="B"
                                                     onChange = {event => changeHandler(event)}
                                                     
                                                     />
                                                    <label className="form-check-label" for="inlineRadio3">NOC B </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                    <input
                                                     className="form-check-input"
                                                     type="radio" name="bcJobSkillLevel"
                                                     id="bcJobSkillLevel"
                                                     value="C"
                                                     onChange = {event => changeHandler(event)}
                                                     
                                                     />
                                                    <label className="form-check-label" for="inlineRadio3">NOC C </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                    <input
                                                     className="form-check-input"
                                                     type="radio" name="bcJobSkillLevel"
                                                     id="bcJobSkillLevel"
                                                     value="D"
                                                     onChange = {event => changeHandler(event)}
                                                     
                                                     />
                                                    <label className="form-check-label" for="inlineRadio3">NOC D </label>
                                                    </div>
                                                </div>
                                            </div>

                                        { (bcJobSkillType === 1) &&  <div className="form-group row bg-yellow" >
                                                <label for="inputPassword" className="col-sm-6 col-form-label">Bonus - Occupation is a "00" NOC* <span className="text-danger">*</span></label>
                                                <div className="col-sm-6">
                                                    <div className="form-check form-check-inline">
                                                        <input 
                                                        className="form-check-input" 
                                                        type="radio"
                                                        name="bcBonusOccupation"
                                                        id="bcBonusOccupation"
                                                        value="15"
                                                        onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Yes</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="bcBonusOccupation"
                                                        id="bcBonusOccupation"
                                                        value="0"
                                                        onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio2">NO</label>
                                                    </div>
                                                    <div className="text-small">If not sure select "No"</div>
                                                    
                                                </div>
                                            </div> }
                                            
                                            <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-6 col-form-label">Is your Occupation identified in the Top 100 occupations in the BC Labour Market Outlook 2014-2024 ? <span className="text-danger">*</span></label>
                                                <div className="col-sm-6">
                                                    <div className="form-check form-check-inline">
                                                        <input 
                                                        className="form-check-input" 
                                                        type="radio"
                                                        name="bcLabourMarket"
                                                        id="bcLabourMarket"
                                                        value="10"
                                                        onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Yes</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="bcLabourMarket"
                                                        id="bcLabourMarket"
                                                        value="0"
                                                        onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio2">NO</label>
                                                    </div>
                                                    <div className="text-small"><a href="/" className="text-primary">Link to top 100 Occupations</a></div>
                                                    
                                                </div>
                                            </div>
                                            <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-6 col-form-label">Are you Currently working in B.C. for the employer in occupation identified in the BC PNP registration? <span className="text-danger">*</span></label>
                                                <div className="col-sm-6">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                         className="form-check-input"
                                                         type="radio"
                                                         name="currentlyWorkingBC"
                                                         id="currentlyWorkingBC"
                                                         value="10"
                                                         onChange = {event => changeHandler(event)}
                                                         />
                                                        <label className="form-check-label" for="inlineRadio1">Yes</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                         className="form-check-input"
                                                         type="radio"
                                                         name="currentlyWorkingBC"
                                                         id="currentlyWorkingBC"
                                                         value="0"
                                                         onChange = {event => changeHandler(event)}
                                                         />
                                                        <label className="form-check-label" for="inlineRadio2">NO</label>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="card-accordion">
                                    <div className="card-accordion-header bg-primary" id="headingTwo">
                                        <h5 className="mb0">
                                            <button className="collapsed text-white" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                     2. Annual wage of the B.C. job offer
                                    </button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                        <div className="card-accordion-body">
                                                <div className="form-group row bg-yellow">
                                                    <label for="inputPassword" className="col-sm-6 col-form-label">Hourly Wage for Job offer in BC <span className="text-danger">*</span></label>
                                                    <div className="col-sm-3">
                                                        <input 
                                                        type="number"
                                                        className="form-control rounded"
                                                        id="hourlyWageBc"
                                                        name="hourlyWageBc"
                                                        placeholder=""
                                                        onChange = {event => changeHandler(event)}
                                                        value={hourlyWageBc}
                                                        />
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div className="form-group row bg-yellow">
                                                    <label for="inputPassword" className="col-sm-6 col-form-label">Schedule Hour's in Week? <span className="text-danger">*</span></label>
                                                    <div className="col-sm-3">
                                                        <input 
                                                        type="number"
                                                        className="form-control rounded"
                                                        id="weekHoursBc"
                                                        name="weekHoursBc"
                                                        placeholder=""
                                                        onChange = {event => changeHandler(event)}
                                                        value={weekHoursBc}
                                                        />
                                                        
                                                        
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-accordion">
                                    <div className="card-accordion-header bg-primary" id="headingThree">
                                        <h5 className="mb-0">
                                            <button className="collapsed text-white" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                3. Regional District of Employment points are:
                                </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                        <div className="card-accordion-body">
                                                <div className="form-group row bg-yellow">
                                                    <label for="inputPassword" className="col-sm-6 col-form-label">Select Area of Employment <span className="text-danger">*</span></label>
                                                    <div className="col-sm-6">
                                                        
                                                        <div className="form-check">
                                                            <input 
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="empArea"
                                                            id="empArea"
                                                            value="10"
                                                            onChange = {event => changeHandler(event)}
                                                            />
                                                            <label className="form-check-label" for="inlineRadio1"> Stikine, Central Coast, Northern Rockies, Mount Waddington, Skeena-Queen Charlotte, Powell River, Sunshine Coast, Kootenay-Boundary, Alberni-Clayoquot</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                             className="form-check-input"
                                                             type="radio"
                                                             name="empArea"
                                                             id="empArea"
                                                             value="8"
                                                             onChange = {event => changeHandler(event)}
                                                             />
                                                            <label className="form-check-label" for="inlineRadio1"> Kitimat-Stikine, Bulkley-Nechako, Squamish-Lillooet, Strathcona, Columbia-Shushwap, East Kootenay</label>
                                                        </div>
                                                        <div className="form-check">
                                                        <input
                                                             className="form-check-input"
                                                             type="radio"
                                                             name="empArea"
                                                             id="empArea"
                                                             value="6"
                                                             onChange = {event => changeHandler(event)}
                                                             />
                                                            <label className="form-check-label" for="inlineRadio1"> Peace River, Comox Valley, Cariboo,Central Kootenay</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                             className="form-check-input"
                                                             type="radio"
                                                             name="empArea"
                                                             id="empArea"
                                                             value="4"
                                                             onChange = {event => changeHandler(event)}
                                                            />
                                                            <label className="form-check-label" for="inlineRadio1">Okanagan-Similkameen, Cowichan Valley, North Okanagan, Fraser-Fort George</label>
                                                        </div>
                                                        <div className="form-check">
                                                        <input
                                                             className="form-check-input"
                                                             type="radio"
                                                             name="empArea"
                                                             id="empArea"
                                                             value="2"
                                                             onChange = {event => changeHandler(event)}
                                                            />
                                                            <label className="form-check-label" for="inlineRadio1">Thompson-Nicola, Nanaimo, Central Okanagan</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                             className="form-check-input"
                                                             type="radio"
                                                             name="empArea"
                                                             id="empArea"
                                                             value="2"
                                                             onChange = {event => changeHandler(event)}
                                                            />
                                                            <label className="form-check-label" for="inlineRadio1">Capital, Fraser Valley</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                             className="form-check-input"
                                                             type="radio"
                                                             name="empArea"
                                                             id="empArea"
                                                             value="0"
                                                             onChange = {event => changeHandler(event)}
                                                            />
                                                            <label className="form-check-label" for="inlineRadio1">Greater Vancouver</label>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-accordion">
                                    <div className="card-accordion-header bg-primary" id="headingFour">
                                        <h5 className="mb-0">
                                            <button className="collapsed text-white" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                               4. Directly related work experience points:
                                </button>
                                        </h5>
                                    </div>
                                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                        <div className="card-accordion-body">
                                            <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-5 col-form-label">Directly Related Work Experience in the Occupation of B.C Job Offer <span className="text-danger">*</span></label>
                                                <div className="col-sm-7">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="workExpBcJobOffer"
                                                        id="workExpBcJobOffer"
                                                        value="15"
                                                        onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">5 + Years</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="workExpBcJobOffer"
                                                        id="workExpBcJobOffer"
                                                        value="12"
                                                        onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio2">4 to 5 Years</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="workExpBcJobOffer"
                                                        id="workExpBcJobOffer"
                                                        value="9"
                                                        onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio2">3 to 4 Years</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="workExpBcJobOffer"
                                                        id="workExpBcJobOffer"
                                                        value="6"
                                                        onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio2">2 to 3 Years</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="workExpBcJobOffer"
                                                        id="workExpBcJobOffer"
                                                        value="3"
                                                        onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio2">1 to 2 Years</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="workExpBcJobOffer"
                                                        id="workExpBcJobOffer"
                                                        value="1"
                                                        onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio2">Less than 1 Year</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="workExpBcJobOffer"
                                                        id="workExpBcJobOffer"
                                                        value="0"
                                                        onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio2">0</label>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>

                                            <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-6 col-form-label">At Least 1 year of directly related experince in Canada?* <span className="text-danger">*</span></label>
                                                <div className="col-sm-6">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="directCanadaExp"
                                                        id="directCanadaExp"
                                                        value="10"
                                                        onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Yes</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="directCanadaExp"
                                                        id="directCanadaExp"
                                                        value="0"
                                                        onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio2">NO</label>
                                                    </div>
                                                    <div className="text-small"><a href="/" className="text-primary">Bonus Points</a></div>
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-accordion">
                                    <div className="card-accordion-header bg-primary" id="headingFive">
                                        <h5 className="mb-0">
                                            <button className="collapsed text-white" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                               5. Highest Level of Education Points:
                                </button>
                                        </h5>
                                    </div>
                                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                        <div className="card-accordion-body">
                                        <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-6 col-form-label">Highest Level of Education <span className="text-danger">*</span></label>
                                                <div className="col-sm-6">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="educationPoints"
                                                        id="educationPoints"
                                                        value="17"
                                                        onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Doctoral or Master's Degree</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="educationPoints"
                                                        id="educationPoints"
                                                        value="11"
                                                        onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio2">Post Graduate Certificate or Diploma</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="educationPoints"
                                                        id="educationPoints"
                                                        value="11"
                                                        onChange = {event => changeHandler(event)}
                                                    />
                                                        
                                                        <label className="form-check-label" for="inlineRadio2">Bachelor's Degree</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="educationPoints"
                                                            id="educationPoints"
                                                            value="11"
                                                            onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio2">Trade Certification</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="educationPoints"
                                                            id="educationPoints"
                                                            value="4"
                                                            onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio2">Associate Degree</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="educationPoints"
                                                                id="educationPoints"
                                                                value="2"
                                                                onChange = {event => changeHandler(event)}
                                                            />
                                                        <label className="form-check-label" for="inlineRadio2">Non Trade Certification or Diploma</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="educationPoints"
                                                            id="educationPoints"
                                                            value="0"
                                                            onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio2">High School</label>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>

                                            <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-6 col-form-label">Post-Secondary Education Completed in B.C? <span className="text-danger">*</span></label>
                                                <div className="col-sm-6">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="educationInBc"
                                                            id="educationInBc"
                                                            value="8"
                                                            onChange = {event => changeHandler(event)}
                                                        />
                                                       
                                                        <label className="form-check-label" for="inlineRadio1">Yes</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="educationInBc"
                                                            id="educationInBc"
                                                            value="0"
                                                            onChange = {event => changeHandler(event)}
                                                        />

                                                        <label className="form-check-label" for="inlineRadio2">NO</label>
                                                    </div>
                                                   
                                                    
                                                    
                                                </div>
                                            </div>
                                            { (eduCompletedOutsideBc === 1) && <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-6 col-form-label">Post-Secondary Education Completed in Canada (Outside B.C) ? <span className="text-danger">*</span></label>
                                                <div className="col-sm-6">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="educationOutsideBc"
                                                            id="educationOutsideBc"
                                                            value="6"
                                                            onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Yes</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="educationOutsideBc"
                                                            id="educationOutsideBc"
                                                            value="0"
                                                            onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio2">NO</label>
                                                    </div>
                                                   
                                                    
                                                    
                                                </div>
                                            </div>}
                                            <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-6 col-form-label">Do you have Education Credential Assessment from qualified supplied? <span className="text-danger">*</span></label>
                                                <div className="col-sm-6">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="eduCredentialAssessment"
                                                            id="eduCredentialAssessment"
                                                            value="4"
                                                            onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Yes</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                    <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="eduCredentialAssessment"
                                                            id="eduCredentialAssessment"
                                                            value="0"
                                                            onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio2">NO</label>
                                                    </div>
                                                   
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-6 col-form-label">Do you have have trades certifiate assessment from the industry Training Authority? <span className="text-danger">*</span></label>
                                                <div className="col-sm-6">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="tradeCertificateAssessment"
                                                            id="tradeCertificateAssessment"
                                                            value="4"
                                                            onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Yes</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="tradeCertificateAssessment"
                                                            id="tradeCertificateAssessment"
                                                            value="0"
                                                            onChange = {event => changeHandler(event)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio2">NO</label>
                                                    </div>
                                                   
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-accordion">
                                    <div className="card-accordion-header bg-primary" id="headingSix">
                                        <h5 className="mb-0">
                                            <button className="collapsed text-white" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                               6. Language Points:
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseSix" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                        <div className="card-accordion-body">
                                            <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-6 col-form-label">Language Points (CLB Level) <span className="text-danger">*</span></label>
                                                <div className="col-sm-6">
                                                    <select
                                                     className="form-control rounded"
                                                      id="clbScore"
                                                      name="clbScore"
                                                      onChange = {event => changeHandler(event)}
                                                      >
                                                                            <option value=''>Select CLB Score</option>
                                                                            <option value='30'>10+</option>
                                                                            <option value="26">9</option>
                                                                            <option value="22">8</option>
                                                                            <option value="18">7</option>
                                                                            <option value="14">6</option>
                                                                            <option value="10">5</option>
                                                                            <option value="6">4</option>
                                                                            <option value="0">Below 4 or Not given Test</option>
                                                    </select>
                                                    
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                    <br/>
                        <div className="container">
                            <div className="row bg-light">
                                    <div className="form-group row  align-middle">
                                                                <label for="inputPassword" className="col-sm-5 col-form-label"><strong>Total points under British Columbia Province Nominee Program (BC PNP) <span className="text-danger">*</span></strong></label>
                                                                <div className="col-sm-2 ">
                                                                    <input type="text" readonly className="form-control rounded bg-warning" id="resultScore" name="resultScore" value={score} placeholder=""/>                          
                                                                    
                                                                </div>
                                                                <div className="col-sm-5 ">
                                                                   {/* <a href="/contactus" className="btn btn-default">Contact Us For Further Information</a> */}
                                                                </div>
                                    </div>
                                    <div className="row">
                                    	<div className="col-md-6">
                                    		<a href="/contactus" className="btn btn-default">Contact Us For Further Information -  <i className='fas fa-phone'></i> +1 (604) 356-0747</a> 
                                    	</div>
                                    	<div className="col-md-6">
                                    		 
                                    	</div>
                                    </div>
                            </div>
                        </div>
                        
                </div>
            </div>
        </div>
    )
}
