import React from 'react'
import ChildCareVisa from './ChildCareVisa'
import ExpressEntry from './ExpressEntry'
import FamilyVisa from './FamilyVisa'
import LMIA from './LMIA'
import PNP from './PNP'
import StudentVisa from './StudentVisa'
import TouristVisa from './TouristVisa'
import WorkPermit from './WorkPermit'

export function Visas() {
    return (
        <div>
           <div id="study-visa">
                <StudentVisa/>
           </div>
           
           <div id="work-permit">
                <WorkPermit/>
           </div>
           <div id="express-entry">
                <ExpressEntry/>
           </div>
           <div id="lmia-permit">
                <LMIA/>
           </div>
                                            
           <div id="family-sponsorship">
                <FamilyVisa/>
           </div>

           <div id="pnp">
                <PNP/>
           </div>
           <div id="tourist-visa">
                <TouristVisa/>
           </div>

           <div id="child-care">
                <ChildCareVisa/>
           </div>      
          
           

        </div>
    )
}
export default Visas