import React, { Component } from 'react'
import {LMIAData } from '../../helpers/CRSHelper'
export class LMIACalc extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lmia: { score: null, type: null },
            show: false

        }

        this.changeHandler = this.changeHandler.bind(this)
        this.changeHandler2 = this.changeHandler2.bind(this)
    }

    componentDidUpdate = () => {
        
        if(this.state.lmia.score && this.state.lmia.type){
            
            this.props.setLmiaScoreObj(this.state.lmia)
            this.props.setNominationCertShow(true)
            this.setState({
                lmia:{ score: null,
                    type: null
                    }
            })
        }

        if(this.state.lmia.score == 0 && this.state.lmia.type == ""){
            this.props.setLmiaScoreObj(this.state.lmia)
            this.props.setNominationCertShow(true)
            this.setState({
                lmia:{ score: null,
                    type: null
                    }
            })
        }
    }
 
    changeHandler = (e) => {

        if(e.target.value=='yes'){
            this.setState({
                show:true
            })
            this.props.setNominationCertShow(false)
        }

        if(e.target.value=="no"){
            this.setState({
                show:false
            })
            this.setState({
                lmia:{ score: 0,
                    type: ""
                    }
            })
            this.props.setNominationCertShow(true)
        }
    }

    changeHandler2 = (e) => {
       if(e.target.value){
            this.setState({
                lmia:{ score: LMIAData[e.target.value].score,
                    type: LMIAData[e.target.value].text
                    }
            })
        }

    }

    render() {
        return (
            <div>
                <div className="form-group">
                    <label for="name"> 8) Do you have a valid job offer supported by a Labour Market Impact Assessment (if needed)?<br />
A valid job offer must be
    <ul>
                            <li>full-time</li>
                            <li>in a skilled job listed as Skill Type 0, or Skill Level A or B in the 2011 National Occupational Classification</li>
                            <li>supported by a Labour Market Impact Assessment (LMIA) or exempt from needing one</li>
                            <li>for one year from the time you become a permanent resident</li>
                        </ul>
                        <br />
   A job offer isn’t valid if your employer is:
            <ul>
                            <li>an embassy, high commission or consulate in Canada or</li>
                            <li>on the list of ineligible employers.</li>
                        </ul>
                        <br />
            Whether an offer is valid or not also depends on different factors, depending on your case. See a full list of criteria for valid job offers.
</label>

                    <select id="lmiaHave" name="lmiaHave" className="form-control" onChange={this.changeHandler} >
                        <option value=''>Please Select..</option>

                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </select>
                </div>

                {(this.state.show) &&  <div className="form-group">
                    <label for="name">8a) Which NOC skill type or level is the job offer?</label>
                    <select className="form-control" id="lmia" name="lmia" onChange={this.changeHandler2}>
                        <option value="">Select...</option>
                        {LMIAData.map((item, idx) => {
                            return <option value={idx}>{item.text}</option>
                        })}
                    </select>
                    </div>
                }
            </div>
        )
    }
}

export default LMIACalc
