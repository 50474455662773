import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'

export  function HeaderClassic() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"><a href="/" className="logo">
                    <img src={`${process.env.PUBLIC_URL}` + "/assets/img/logo1.png"}  alt="Visapress an Immigration and Visa Consulting Website Template" />
                   </a></div> 
                <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
                    <div id="navigation-transparent" className="navigation-transparent">
                         <ul>
                            <li><a href="/">Home</a>
                                {/* <ul>
                                    <li><a href="index.html">Homepage v1</a></li>
                                    <li><a href="index-2.html">Homepage v2</a></li>
                                    <li><a href="index-3.html">Homepage v3</a></li>
                                    <li><a href="index-4.html">Homepage v4</a></li>
                                    <li><a href="index-5.html">Homepage v5</a></li>
                                    <li><a href="index-6.html">Homepage v6</a></li>
                                    <li><a href="#">Landing pages</a>
                                        <ul>
                                            <li><a href="landing-page-v1.html">Landing page v1</a></li>
                                            <li><a href="landing-page-country-v1.html">Landing page country v1</a></li>
                                            <li><a href="landing-page-ebook-v3.html">Landing page v3 - eBook</a></li>
                                            <li><a href="landing-page-webinar.html">Landing page v4 - Webinars</a></li>
                                        </ul>
                                    </li>
                                </ul> */}
                            </li>
                            <li><a href="/aboutus">About us </a>
                                {/* <ul>
                                    <li><a href="#">About Us</a>
                                        <ul>
                                            <li><a href="about-us-page-v1.html">About us v1</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="founder-page.html">Founder page</a></li>
                                    <li><a href="agent-list-page.html">Agent List</a></li>
                                    <li><a href="agent-single-page.html">Agent Single</a></li>
                                    <li><a href="partner-university-page.html">Partner University Page</a></li>
                                </ul> */}
                            </li>
                            <li><a href="/visas">Services</a>
                                        <ul>
                                            <li><Link to="/visas#study-visa">Student Visa</Link></li>
                                            <li><Link to="/visas#work-permit">Work Permit</Link></li>                                            
                                            <li><Link to="/visas#express-entry">Express Entry</Link></li>
                                            <li><Link to="/visas#lmia-permit">LMIA</Link></li>
                                            <li><Link to="/visas#family-sponsorship">Family Sponsorship</Link></li>
                                            <li><Link to="/visas#pnp">Provincial Nominee Program</Link></li>
                                            <li><Link to="/visas#tourist-visa">Tourist & Visitor Visa</Link></li>
                                            <li><Link to="/visas#child-care">Home Child Care Nominee Pilot Program</Link></li>

                                        </ul>
                            </li>
                            <li><a href="/nitrocalc1">Point Calculator</a>
                                            <ul>
                                                <li><a href="/nitrocalc1">BC PNP Point Calculator</a></li>
                                                <li><a href="/nitrocrs">CRS Points Calculator - Express Entry</a></li>
                                                <li><a href="/nitrocalc2">67 Point Skilled Worker Calculator</a></li>
                                                
                                            </ul>
                            </li>
                            {/* <li><a href="#">Coaching</a>
                                <ul>
                                    <li><a href="coaching-list-page.html">Coaching List</a></li>
                                    <li><a href="coaching-sidebar.html">Coaching Sidebar</a></li>
                                    <li><a href="coaching-full-page.html">Coaching Full Page</a></li>
                                </ul>
                            </li> */}
                            
                            
                           
                            {/* <li><a href="#">Features </a>
                                <ul>
                                    <li><a href="#">Testimonial</a>
                                        <ul>
                                            <li><a href="customer-review-page-v1.html">Customer Review Page v1</a></li>
                                            <li><a href="customer-review-score-page-v2.html">Customer Review page v2</a></li>
                                            <li><a href="video-testimonial-page.html">Video Testimonial</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="#">Email List Builder</a>
                                        <ul>
                                            <li><a href="list-builder-embeded.html"> List Builder Embeded </a></li>
                                            <li><a href="list-builder-popup.html">List Builder Popup</a></li>
                                            <li><a href="list-builder-scroll-box.html">List Builder Scroll Box </a></li>
                                        </ul>
                                    </li>
                                    <li><a href="#">Component</a>
                                        <ul>
                                            <li><a href="accordion-page.html">Accordion</a></li>
                                            <li><a href="tabs-page.html">Tabs</a></li>
                                            <li><a href="alert-page.html">Alert</a></li>
                                            <li><a href="table-page.html">Tables</a></li>
                                            <li><a href="button-page.html">Buttons</a></li>
                                            <li><a href="social-media-page.html">Social Media</a></li>
                                            <li><a href="call-to-action-page.html">Call To Actions</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="#">Gallery</a>
                                        <ul>
                                            <li><a href="gallery-popup.html">Gallery Popup</a></li>
                                            <li><a href="gallery-filterable.html">Gallery Filterable</a></li>
                                            <li><a href="gallery-carousel.html">Gallery Carousel</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="#">404 Error</a>
                                        <ul>
                                            <li><a href="error-page.html">404 Error 1</a></li>
                                            <li><a href="error-page-v2.html">404 Error 2</a></li>
                                            <li><a href="error-page-v3.html">404 Error 3</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="faq-page.html">Faq</a></li>
                                </ul>
                            </li>
                            <li><a href="#">Blog </a>
                                <ul>
                                    <li><a href="blog-page.html">Blog Regular</a></li>
                                    <li><a href="blog-3-column-page.html">Blog Listing 3 Column</a></li>
                                    <li><a href="blog-single-page.html">Blog Single</a></li>
                                </ul>
                            </li> */}
                            <li><a href="/contactus">Contact Us </a>
                                {/* <ul>
                                    <li><a href="contact-page.html">Contact Us</a></li>
                                    <li><a href="location-page.html">Location</a></li>
                                    <li><a href="free-assessment-enquiry.html">Enquiry Page</a></li>
                                </ul> */}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    
    )
}
export default HeaderClassic