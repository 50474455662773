import React, {useState, useEffect } from 'react'
import axios from 'axios'

export  function TableBlog() {
    const [blogsData, setBlogsData] = useState([])

    const fetchData = async () => {
       await  axios.get(`${process.env.REACT_APP_API_URL}/admin/blog`)
        .then(res => {
            console.log(res.data.blogs.length)
            if(res.data.blogs.length > 0){
                console.log(res.data.blogs)
                let count = 0
               res.data.blogs.map(blog => {
                   blogsData[count] = blog
                   count++
               })
               setBlogsData(blogsData)
               console.log(blogsData)
            }
           
       
        })
        .catch( err => {

            console.log(err)
        })
    }

    useEffect(() => {
        fetchData()
        console.log("Tested")
    },[])
    
    

    return (
        <div>
            <h1>List of Blogs</h1>
            <table className="table table-stripped">
                
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>shortDesc</th>
                        <th>fullDesc</th>
                    </tr>
                </thead>
                <tbody>
                  
                    { blogsData.length}
                    { (blogsData.length > 0) && blogsData.map((blogData) => {
                        return (
                        <tr key={blogData.title}>
                            <td></td>
                            <td>{blogData.title}</td>
                            <td>{blogData.shortDesc}</td>
                            <td>{blogData.fullDesc}</td>
                        </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
export default TableBlog