import React, { Component } from 'react'
import {SpouseEducationArray, SpouseCandiandWorkExpArray, SpouseCLBArray} from '../../helpers/CRSHelper'

export class SpouseComp extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            eduScore:{points:null, show:true},
            canExpScore:{points:null, show:false},
            langScore:{lpoints:null, spoints:null, rpoints:null, wpoints:null, show:false, type:null},
            test:null

             
        }
        this.changeHandler = this.changeHandler.bind(this)
        this.changeHandler2 = this.changeHandler2.bind(this)
        this.changeHandler3 = this.changeHandler3.bind(this)
        this.changeHandler4 = this.changeHandler4.bind(this)
    }
    componentDidUpdate = () => {
       
        if((this.state.eduScore.points || this.state.eduScore.points) && (this.state.canExpScore.points || this.state.canExpScore.points == 0) && this.state.langScore.lpoints && this.state.langScore.spoints && this.state.langScore.rpoints && this.state.langScore.wpoints){
            this.props.setSpouseScore({
                edu:this.state.eduScore.points,
                exp:this.state.canExpScore.points,
                lang:this.state.langScore.lpoints + this.state.langScore.spoints + this.state.langScore.wpoints + this.state.langScore.rpoints
            })
            console.log(this.state)

            this.setState({
                eduScore:{points:null, show:true},
                canExpScore:{points:null, show:true},
                langScore:{lpoints:null, spoints:null, rpoints:null, wpoints:null, show:true, type:null}

            })
          
        }
        

    }
    changeHandler = (e) => {
        this.setState({
            eduScore:{points:SpouseEducationArray[e.target.value].scoreWithSpouse, show:true},
            canExpScore:{show:true}
        })
    }

    changeHandler2 = (e) => {
        this.setState({
            canExpScore:{points:SpouseCandiandWorkExpArray[e.target.value].scoreWithSpouse, show:true},
            langScore:{show:true}
        })
    }

    changeHandler4 = (e) => {
        if(e.target.name == 'testType'){
            this.setState({
                langScore:{...this.state.langScore,type:e.target.value},
                test:e.target.value
            })


        }

    }
    changeHandler3 = (e) => {
       
        console.log(this.state.langScore.type)
        if(e.target.name == 'listening'){
            this.setState({
                langScore:{...this.state.langScore,lpoints:parseInt(SpouseCLBArray[e.target.value].scoreWithSpouse)}
            })
        }

        if(e.target.name == 'speaking'){
            this.setState({
                langScore:{...this.state.langScore,spoints:parseInt(SpouseCLBArray[e.target.value].scoreWithSpouse)}
            })
        }

        if(e.target.name == 'reading'){
            this.setState({
                langScore:{...this.state.langScore,rpoints:parseInt(SpouseCLBArray[e.target.value].scoreWithSpouse)}
            })
        }

        if(e.target.name == 'writing'){
            this.setState({
                langScore:{...this.state.langScore,wpoints:parseInt(SpouseCLBArray[e.target.value].scoreWithSpouse)}
            })
        }

    }
    render() {
        const {eduScore, canExpScore, langScore, test } = this.state
        return (
            <div>
                {
                    (eduScore.show) && <div className="row">

                        <div className="form-group">
                        <label for="name">11) What is the highest level of education for which your spouse or common-law partner's has:
                            <ul>
                                <li>earned a Canadian degree, diploma or certificate; or</li>
                                <li>had an Educational Credential Assessment (ECA)? (ECAs must be from an approved agency, in the last five years)</li>
                            </ul>
                            <br/>To get the correct number of points, make sure you choose the answer that best reflects your case. For example:
                        </label>
                                        
                            <select id="educationLevel" name="educationLevel" className="form-control" onChange={this.changeHandler} >
                                <option value=''>Please Select..</option>
                                {SpouseEducationArray.map((item, idx) => {
                                    return <option value={idx}>{item.text}</option> 
                                })}
                            </select>
                        </div>
                    </div>
                }
                {
                    (canExpScore.show) && <div className="row">
                        
                        <div className="form-group">
                        <label for="name">12) In the last ten years, how many years of skilled work experience in Canada does your spouse/common-law partner have?
<br/>It must have been paid, full-time (or an equal amount in part-time), and in one or more NOC 0, A or B jobs.</label>
                                                      
                        <select id="skilledWorkExp" name="skilledWorkExp" className="form-control" onChange={this.changeHandler2} >
                            <option value=''>Please Select..</option>
                            {SpouseCandiandWorkExpArray.map((item, idx) => {
                                return <option value={idx}>{item.text}</option> 
                            })}
                        </select>
                    </div>
                    </div>
                }

                {
                    (langScore.show) && <div className="row">
                        <div className="form-group">
                            <label for="name">13) i) Did your spouse or common-law partner take a language test? If so, which one?
Test results must be less than two years old.</label>
<div>
                        <div className="form-group">
                            <label>
                            Which language test did you take for your first official language?
                            </label>
                            <select id="testType" name="testType" className="form-control" onChange={this.changeHandler4}>
                                <option value="">Select..</option>
                                <option value="ielts">IELTS</option>
                                <option value="celpip">CELPIP-G</option>
                                <option value="tcf">TCF Canada</option>
                                <option value="tef">TEF Canada</option>
                            </select>
                        </div>

                    </div>
                    
                    {(test) && <div>
                   
                    <div className="form-group">
                        <label for="name">Listening</label>
                                                      
                        <select id="listening" name="listening" className="form-control" onChange={this.changeHandler3} >
                            <option value=''>Please Select..</option>
                            {SpouseCLBArray.map((item, idx) => {
                                return <option value={idx}>{item[`${test}`].listening}</option> 
                            })}
                        </select>
                    </div>
                    <div className="form-group">
                        <label for="name">Speaking</label>
                                                      
                        <select id="speaking" name="speaking" className="form-control"  onChange={this.changeHandler3}  >
                            <option value=''>Please Select..</option>
                            {SpouseCLBArray.map((item, idx) => {
                                return <option value={idx}>{item[`${test}`].speaking}</option> 
                            })}
                        </select>
                    </div>
                    <div className="form-group">
                        <label for="name">Reading</label>
                                                      
                        <select id="reading" name="reading" className="form-control"  onChange={this.changeHandler3} >
                            <option value=''>Please Select..</option>
                            {SpouseCLBArray.map((item, idx) => {
                                return <option value={idx}>{item[`${test}`].reading}</option> 
                            })}
                        </select>
                    </div>
                    <div className="form-group">
                        <label for="name">Writing</label>
                                                      
                        <select id="writing" name="writing" className="form-control"   onChange={this.changeHandler3} >
                            <option value=''>Please Select..</option>
                            {SpouseCLBArray.map((item, idx) => {
                                return <option value={idx}>{item[`${test}`].writing}</option> 
                            })}
                        </select>
                    </div>
                    </div> }

                        </div>
                    </div>
                }
                
            </div>
        )
    }
}

export default SpouseComp
