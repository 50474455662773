import React, {useState, useEffect} from 'react'
import ExpertServices from './ExpertServices'

export function Lawyer() {

    return (
       
    <div class="space-medium text-left">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-7 col-sm-6 col-12">
                    <h2>Immigration Services From <br/> Expert Consultant </h2>
                    <p class="lead">Trusted by many clients </p>
                    <ExpertServices/>
                    <a href="/aboutus" class="btn btn-default">About Nitro</a> <a href="/contactus" class="btn btn-primary">Contact us</a>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-5 col-sm-6 col-12">
                    <div class="img-block">
                        <img src="./assets/images/about-fancy-img-1.png" alt="simple bootstrap template" class="img-fluid"/>
                        <span><a href="https://www.youtube.com/embed/kSoO2KjVVG4" class="btn-round-play video" id=""><i class="fa fa-play"></i></a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
        
        
    )
}

export default Lawyer