import React,{useState, useEffect} from 'react'
import axios from 'axios'

export function Topbar() {
    const [data, setData] = useState({phone:'', email:''})
    const loadData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/page/topbar`)
        .then(res => {
            console.log("Doo"+res.data.content.phone)
            setData(res.data.content)
        })
        .catch(err => {
            console.log(err)
        })
    }
    useEffect( () => {
        loadData()
    },[])

    return (
        <div className="topbar-transparent">
            
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-none d-sm-none d-lg-block d-xl-block">
                        <p className="welcome-text">Immigration Consultants Of Canada Regulatory Council

</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="header-block">
                            <span className="header-link d-none d-xl-block d-md-block"><a href="#">Talk to Our Expert</a></span>
                            <span className="header-link"><i class="fas fa-phone"></i> {data.phone} </span>
                            <span className="header-link"><i class="fas fa-envelope-square"></i>  {data.email}</span>
                            {/* <span className="header-link"><a href="#" className="btn btn-default btn-sm">Contact Us</a></span> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Topbar