import React from 'react'
import Footer from '../components/Footers/Footer'
import HeaderWhite from '../components/Headers/HeaderLight/HeaderWhite'
import AdminLoginHero from '../components/Hero/AdminLoginHero'
import AdminLogin from '../components/Auth/AdminLogin'

export function AdminLoginView() {
    return (
        <div>
            <HeaderWhite/>
            <AdminLoginHero/>
            <AdminLogin/>
            <Footer/>
        </div>
    )
}

export default AdminLoginView