import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AboutUs from './views/AboutUs';
import ContactUs from './views/ContactUs';
import { CountryList } from './views/CountryList';
//import 'bootstrap/dist/css/bootstrap.min.css'; // Not to be imported when working with html css templates
//import Admin from './layout/Admin';
import { Index } from './views/Index'
import VisaSingle from './views/VisaSingle';
import BCPNPCalculator from './views/BCPNPCalculator';
import Point67CalcView from './views/Point67CalcView';

import AdminLoginView from './views/AdminLoginView';
import AdminLayout from './layout/AdminLayout'
import VisasViews from './views/VisasViews'
import BlogsViews from './views/BlogsViews';
import CRSCalcView from './views/CRSCalcView';
ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      <Route path="/admin" component={AdminLayout} />
      
      {/* add routes without layouts */}
      <Route path="/cpanel" exact render={props => <AdminLoginView {...props} />} />
      <Route path="/" exact component={Index} />
      <Route path="/aboutus" exact component={AboutUs} />
      <Route path="/visas" exact component={VisasViews} />
      <Route path="/countrylist" exact component={CountryList} />
      <Route path="/contactus" exact component={ContactUs} />
      <Route path="/nitrocalc1" exact component={BCPNPCalculator} />
      <Route path="/nitrocalc2" exact component={Point67CalcView} />
      <Route path="/nitrocrs" exact component={CRSCalcView} />
      <Route path="/blogs" exact component={BlogsViews}/>
      
      {/* add redirect for first page */}
     
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

