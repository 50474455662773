import React from 'react'
import Footer from '../components/Footers/Footer'
import HeaderWhite from '../components/Headers/HeaderLight/HeaderWhite'
import VisaHero from '../components/Hero/VisaHero'
import StudentVisa from '../components/Pages/Visas/StudentVisa'
import Visas from '../components/Pages/Visas/Visas'

export  function VisaSingle() {
    return (
        <div>
            <HeaderWhite/>
            <VisaHero/>
            <Visas/>
            <Footer/>
        </div>
    )
}

export default VisaSingle