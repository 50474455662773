import React from 'react'
import Footer from '../components/Footers/Footer'
import FooterBlue from '../components/Footers/FooterBlue'
import Header from '../components/Headers/Header/HeaderTransparent'
import HeaderWhite from '../components/Headers/HeaderLight/HeaderWhite'
import AboutUsHero from '../components/Hero/AboutUsHero'
import Content from '../components/Pages/AboutUs/Content'


export function AboutUs() {
    return (
        <div>
            <HeaderWhite/>
            <AboutUsHero/>
            <Content/>
            <Footer/>
        </div>
    )
}

export default AboutUs