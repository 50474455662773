import React, { Component } from 'react'
import {ageScoreWithSpouse, ageScoreWithoutSpouse } from '../../helpers/CRSHelper'

export class AgeCRS extends Component {

    
    constructor(props) {
        super(props)
    
        this.state = {
             age:null,
             ageArray:(this.props.partner) ? ageScoreWithSpouse : ageScoreWithoutSpouse
        }
    }
    handleChange = (e) => {
        let obj = this.state.ageArray[e.target.value]
        this.props.onChangeAge(obj.points)
    }
    
    render() {
        return (
            <div>
                <select id="age" name="age" className="form-control" onChange={this.handleChange} >
                    <option value=''>Please Select..</option>
                    {this.state.ageArray.map((item, idx) => {
                        return <option value={idx}>{item.text}</option> 
                    })}
                </select>
               
            </div>
        )
    }
}

export default AgeCRS
