export const ageScoreWithSpouse = [
    {
        minAge:0,
        maxAge:17,
        points:0,
        text:'17 years of age or less'

    },
    {
        minAge:18,
        maxAge:18,
        points:90,
        text:'18 years of age'

    },
    {
        minAge:19,
        maxAge:19,
        points:95,
        text:'19 years of age'


    },
    {
        minAge:20,
        maxAge:29,
        points:100,
        text:'20 to 29 years of age'


    },
    {
        minAge:30,
        maxAge:30,
        points:95,
        text:'30 years of age'


    },
    {
        minAge:31,
        maxAge:31,
        points:90,
        text:'31 years of age'

    },
    {
        minAge:32,
        maxAge:32,
        points:85,
        text:'32 years of age'

    },
    {
        minAge:33,
        maxAge:33,
        points:80,
        text:'33 years of age'

    },
    {
        minAge:34,
        maxAge:34,
        points:75,
        text:'34 years of age'

    },
    {
        minAge:35,
        maxAge:35,
        points:70,
        text:'35 years of age'

    },
    {
        minAge:36,
        maxAge:36,
        points:65,
        text:'36 years of age'

    },
    {
        minAge:37,
        maxAge:37,
        points:60,
        text:'37 years of age'

    },
    {
        minAge:38,
        maxAge:38,
        points:55,
        text:'38 years of age'

    },
    {
        minAge:39,
        maxAge:39,
        points:50,
        text:'39 years of age'

    },
    {
        minAge:40,
        maxAge:40,
        points:45,
        text:'40 years of age'

    },
    {
        minAge:41,
        maxAge:41,
        points:35,
        text:'41 years of age'

    },
    {
        minAge:42,
        maxAge:42,
        points:25,
        text:'42 years of age'

    },
    {
        minAge:43,
        maxAge:43,
        points:15,
        text:'43 years of age'

    },
    {
        minAge:44,
        maxAge:44,
        points:5,
        text:'44 years of age'

    },
    {
        minAge:45,
        maxAge:120,
        points:0,
        text:'45 years of age or more'

    },
]

export const ageScoreWithoutSpouse = [
    {
        minAge:0,
        maxAge:17,
        points:0,
        text:'17 years of age or less'

    },
    {
        minAge:18,
        maxAge:18,
        points:99,
        text:'18 years of age'

    },
    {
        minAge:19,
        maxAge:19,
        points:105,
        text:'19 years of age'

    },
    {
        minAge:20,
        maxAge:29,
        points:110,
        text:'20 to 29 years of age'

    },
    {
        minAge:30,
        maxAge:30,
        points:105,
        text:'30 years of age'

    },
    {
        minAge:31,
        maxAge:31,
        points:99,
        text:'31 years of age'

    },
    {
        minAge:32,
        maxAge:32,
        points:94,
        text:'32 years of age'

    },
    {
        minAge:33,
        maxAge:33,
        points:88,
        text:'33 years of age'

    },
    {
        minAge:34,
        maxAge:34,
        points:83,
        text:'34 years of age'

    },
    {
        minAge:35,
        maxAge:35,
        points:77,
        text:'35 years of age'

    },
    {
        minAge:36,
        maxAge:36,
        points:72,
        text:'36 years of age'

    },
    {
        minAge:37,
        maxAge:37,
        points:66,
        text:'37 years of age'

    },
    {
        minAge:38,
        maxAge:38,
        points:61,
        text:'38 years of age'

    },
    {
        minAge:39,
        maxAge:39,
        points:55,
        text:'39 years of age'

    },
    {
        minAge:40,
        maxAge:40,
        points:50,
        text:'40 years of age'

    },
    {
        minAge:41,
        maxAge:41,
        points:39,
        text:'41 years of age'

    },
    {
        minAge:42,
        maxAge:42,
        points:28,
        text:'42 years of age'

    },
    {
        minAge:43,
        maxAge:43,
        points:17,
        text:'43 years of age'

    },
    {
        minAge:44,
        maxAge:44,
        points:6,
        text:'44 years of age'

    },
    {
        minAge:45,
        maxAge:120,
        points:0,
        text:'45 years of age Or Older'

    },
]

export const educationScore = [
    {
       level:1,
       text: "Less than secondary school (high school)",
       scoreWithSpouse:0,
       scoreWithoutSpouse:0
    },
    {
        level:2,
        text: "Secondary diploma (high school graduation)",
        scoreWithSpouse:28,
        scoreWithoutSpouse:30
     },
     {
        level:3,
        text: "One-year degree, diploma or certificate from  a university, college, trade or technical school, or other institute",
        scoreWithSpouse:84,
        scoreWithoutSpouse:90
     },
     {
        level:4,
        text: "Two-year program at a university, college, trade or technical school, or other institute",
        scoreWithSpouse:91,
        scoreWithoutSpouse:98
     },
     {
        level:5,
        text: "Bachelor's degree OR  a three or more year program at a university, college, trade or technical school, or other institute",
        scoreWithSpouse:112,
        scoreWithoutSpouse:120
     },
     {
        level:6,
        text: "Two or more certificates, diplomas, or degrees. One must be for a program of three or more years",
        scoreWithSpouse:119,
        scoreWithoutSpouse:128
     },
     {
        level:7,
        text:"Master's degree, OR professional degree needed to practice in a licensed profession",
        fullText: "Master's degree, OR professional degree needed to practice in a licensed profession (For “professional degree,” the degree program must have been in: medicine, veterinary medicine, dentistry, optometry, law, chiropractic medicine, or pharmacy.)",
        scoreWithSpouse:126,
        scoreWithoutSpouse:135
     },
     {
        level:8,
        text: "Doctoral level university degree (Ph.D.)	",
        scoreWithSpouse:140,
        scoreWithoutSpouse:150
     },
     

]

export const SpouseEducationArray = [
    {
       level:1,
       text: "Less than secondary school (high school)",
       scoreWithSpouse:0,
       scoreWithoutSpouse:0
    },
    {
        level:2,
        text: "Secondary diploma (high school graduation)",
        scoreWithSpouse:2,
        scoreWithoutSpouse:0
     },
     {
        level:3,
        text: "One-year degree, diploma or certificate from  a university, college, trade or technical school, or other institute",
        scoreWithSpouse:6,
        scoreWithoutSpouse:0
     },
     {
        level:4,
        text: "Two-year program at a university, college, trade or technical school, or other institute",
        scoreWithSpouse:7,
        scoreWithoutSpouse:0
     },
     {
        level:5,
        text: "Bachelor's degree OR  a three or more year program at a university, college, trade or technical school, or other institute",
        scoreWithSpouse:8,
        scoreWithoutSpouse:0
     },
     {
        level:6,
        text: "Two or more certificates, diplomas, or degrees. One must be for a program of three or more years",
        scoreWithSpouse:9,
        scoreWithoutSpouse:0
     },
     {
        level:7,
        text:"Master's degree, OR professional degree needed to practice in a licensed profession",
        fullText: "Master's degree, OR professional degree needed to practice in a licensed profession (For “professional degree,” the degree program must have been in: medicine, veterinary medicine, dentistry, optometry, law, chiropractic medicine, or pharmacy.)",
        scoreWithSpouse:10,
        scoreWithoutSpouse:0
     },
     {
        level:8,
        text: "Doctoral level university degree (Ph.D.)	",
        scoreWithSpouse:10,
        scoreWithoutSpouse:0
     },
     

]

export const CLBArray = [
    {
        text:'Less than CLB 4',
        scoreWithSpouse:0,
        scoreWithoutSpouse:0,
	celpip:{reading:"0-3", writing:"0-3", listening:"0-3", speaking:"0-3"},
	ielts:{reading:"Less than 3.5", writing:"Less Than 4.0", listening:"Less Than 4.5", speaking:"Less Than 4.0"},
	tef:{reading:"0-120", writing:"0-180", listening:"0-144", speaking:"0-180"},
	tcf:{reading:"0-341", writing:"0-3", listening:"0-330", speaking:"0-3"},
        level:4
    },
    {
        text:'CLB 4',
        scoreWithSpouse:6,
        scoreWithoutSpouse:6,
	celpip:{reading:"4", writing:"4", listening:"4", speaking:"4"},
	ielts:{reading:"3.5", writing:"4.0", listening:"4.5", speaking:"4.0"},
	tef:{reading:"121-150", writing:"181-225", listening:"145-180", speaking:"181-225"},
	tcf:{reading:"342-374", writing:"4-5", listening:"331-368", speaking:"4-5"},
        level:4
    },
    {
        text:'CLB 5',
        scoreWithSpouse:6,
        scoreWithoutSpouse:6,
	celpip:{reading:"5", writing:"5", listening:"5", speaking:"5"},
	ielts:{reading:"4.0", writing:"5.0", listening:"5.0", speaking:"5.0"},
	tef:{reading:"151-180", writing:"226-270", listening:"181-216", speaking:"226-270"},
	tcf:{reading:"375-405", writing:"6", listening:"369-397", speaking:"6"},
        level:5
    },
    {
        text:'CLB 6',
        scoreWithSpouse:8,
        scoreWithoutSpouse:9,
	celpip:{reading:"6", writing:"6", listening:"6", speaking:"6"},
	ielts:{reading:"5.0", writing:"5.5", listening:"5.5", speaking:"5.5"},
	tef:{reading:"181-206", writing:"271-309", listening:"217-248", speaking:"271-309"},
	tcf:{reading:"406-452", writing:"7-9", listening:"398-457", speaking:"7-9"},
        level:6
    },
    {
        text:'CLB 7',
        scoreWithSpouse:16,
        scoreWithoutSpouse:17,
	celpip:{reading:"7", writing:"7", listening:"7", speaking:"7"},
	ielts:{reading:"6.0", writing:"6.0", listening:"6.0", speaking:"6.0"},
	tef:{reading:"207-232", writing:"310-348", listening:"249-279", speaking:"310-348"},
	tcf:{reading:"453-498", writing:"10-11", listening:"458-502", speaking:"10-11"},
        level:7
    },
    {
        text:'CLB 8',
        scoreWithSpouse:22,
        scoreWithoutSpouse:23,
	celpip:{reading:"8", writing:"8", listening:"8", speaking:"8"},
	ielts:{reading:"6.5", writing:"6.5", listening:"7.5", speaking:"6.5"},
	tef:{reading:"233-247", writing:"349-370", listening:"280-297", speaking:"349-370"},
	tcf:{reading:"499-523", writing:"12-13", listening:"503-522", speaking:"12-13"},
        level:8
    },
    {
        text:'CLB 9',
        scoreWithSpouse:29,
        scoreWithoutSpouse:31,
	celpip:{reading:"9", writing:"9", listening:"9", speaking:"9"},
	ielts:{reading:"7.0", writing:"7.0", listening:"8.0", speaking:"7.0"},
	tef:{reading:"248-262", writing:"371-392", listening:"298-315", speaking:"371-392"},
	tcf:{reading:"524-548", writing:"14-15", listening:"523-548", speaking:"14-15"},
        level:9
    },
    {
        text:'CLB 10 or More',
        scoreWithSpouse:32,
        scoreWithoutSpouse:34,
        celpip:{reading:"10", writing:"10", listening:"10", speaking:"10"},
	ielts:{reading:"8.0", writing:"7.5", listening:"8.5", speaking:"7.5"},
	tef:{reading:"263-300", writing:"393-450", listening:"316-360", speaking:"393-450"},
	tcf:{reading:"549-699", writing:"16-20", listening:"549-699", speaking:"16-20"},
        level:10
    }
]

export const CLB2ndLangArray = [
    {
        text:'Less than CLB 4',
        scoreWithSpouse:0,
        scoreWithoutSpouse:0,
	celpip:{reading:"0-3", writing:"0-3", listening:"0-3", speaking:"0-3"},
	ielts:{reading:"Less than 3.5", writing:"Less Than 4.0", listening:"Less Than 4.5", speaking:"Less Than 4.0"},
	tef:{reading:"0-120", writing:"0-180", listening:"0-144", speaking:"0-180"},
	tcf:{reading:"0-341", writing:"0-3", listening:"0-330", speaking:"0-3"},
        level:4
    },
    {
        text:'CLB 4',
        scoreWithSpouse:0,
        scoreWithoutSpouse:0,
	celpip:{reading:"4", writing:"4", listening:"4", speaking:"4"},
	ielts:{reading:"3.5", writing:"4.0", listening:"4.5", speaking:"4.0"},
	tef:{reading:"121-150", writing:"181-225", listening:"145-180", speaking:"181-225"},
	tcf:{reading:"342-374", writing:"4-5", listening:"331-368", speaking:"4-5"},
        level:4
    },
    {
        text:'CLB 5',
        scoreWithSpouse:1,
        scoreWithoutSpouse:1,
	celpip:{reading:"5", writing:"5", listening:"5", speaking:"5"},
	ielts:{reading:"4.0", writing:"5.0", listening:"5.0", speaking:"5.0"},
	tef:{reading:"151-180", writing:"226-270", listening:"181-216", speaking:"226-270"},
	tcf:{reading:"375-405", writing:"6", listening:"369-397", speaking:"6"},
        level:5
    },
    {
        text:'CLB 6',
        scoreWithSpouse:1,
        scoreWithoutSpouse:1,
	celpip:{reading:"6", writing:"6", listening:"6", speaking:"6"},
	ielts:{reading:"5.0", writing:"5.5", listening:"5.5", speaking:"5.5"},
	tef:{reading:"181-206", writing:"271-309", listening:"217-248", speaking:"271-309"},
	tcf:{reading:"406-452", writing:"7-9", listening:"398-457", speaking:"7-9"},
        level:6
    },
    {
        text:'CLB 7',
        scoreWithSpouse:3,
        scoreWithoutSpouse:3,
	celpip:{reading:"7", writing:"7", listening:"7", speaking:"7"},
	ielts:{reading:"6.0", writing:"6.0", listening:"6.0", speaking:"6.0"},
	tef:{reading:"207-232", writing:"310-348", listening:"249-279", speaking:"310-348"},
	tcf:{reading:"453-498", writing:"10-11", listening:"458-502", speaking:"10-11"},
        level:7
    },
    {
        text:'CLB 8',
        scoreWithSpouse:3,
        scoreWithoutSpouse:3,
	celpip:{reading:"8", writing:"8", listening:"8", speaking:"8"},
	ielts:{reading:"6.5", writing:"6.5", listening:"7.5", speaking:"6.5"},
	tef:{reading:"233-247", writing:"349-370", listening:"280-297", speaking:"349-370"},
	tcf:{reading:"499-523", writing:"12-13", listening:"503-522", speaking:"12-13"},
        level:8
    },
    {
        text:'CLB 9',
        scoreWithSpouse:6,
        scoreWithoutSpouse:6,
	celpip:{reading:"9", writing:"9", listening:"9", speaking:"9"},
	ielts:{reading:"7.0", writing:"7.0", listening:"8.0", speaking:"7.0"},
	tef:{reading:"248-262", writing:"371-392", listening:"298-315", speaking:"371-392"},
	tcf:{reading:"524-548", writing:"14-15", listening:"523-548", speaking:"14-15"},
        level:9
    },
    {
        text:'CLB 10 or More',
        scoreWithSpouse:6,
        scoreWithoutSpouse:6,
        celpip:{reading:"10", writing:"10", listening:"10", speaking:"10"},
	ielts:{reading:"8.0", writing:"7.5", listening:"8.5", speaking:"7.5"},
	tef:{reading:"263-300", writing:"393-450", listening:"316-360", speaking:"393-450"},
	tcf:{reading:"549-699", writing:"16-20", listening:"549-699", speaking:"16-20"},
        level:10
    }
]

export const SpouseCLBArray = [
    {
        text:'Less than CLB 4',
        scoreWithSpouse:0,
        scoreWithoutSpouse:0,
	    celpip:{reading:"0-3", writing:"0-3", listening:"0-3", speaking:"0-3"},
	    ielts:{reading:"Less than 3.5", writing:"Less Than 4.0", listening:"Less Than 4.5", speaking:"Less Than 4.0"},
	    tef:{reading:"0-120", writing:"0-180", listening:"0-144", speaking:"0-180"},
	    tcf:{reading:"0-341", writing:"0-3", listening:"0-330", speaking:"0-3"},
        level:4
    },
    {
        text:'CLB 4',
        scoreWithSpouse:0,
        scoreWithoutSpouse:0,
        celpip:{reading:"4", writing:"4", listening:"4", speaking:"4"},
        ielts:{reading:"3.5", writing:"4.0", listening:"4.5", speaking:"4.0"},
        tef:{reading:"121-150", writing:"181-225", listening:"145-180", speaking:"181-225"},
        tcf:{reading:"342-374", writing:"4-5", listening:"331-368", speaking:"4-5"},
        level:4
    },
    {
        text:'CLB 5',
        scoreWithSpouse:1,
        scoreWithoutSpouse:0,
        celpip:{reading:"5", writing:"5", listening:"5", speaking:"5"},
        ielts:{reading:"4.0", writing:"5.0", listening:"5.0", speaking:"5.0"},
        tef:{reading:"151-180", writing:"226-270", listening:"181-216", speaking:"226-270"},
        tcf:{reading:"375-405", writing:"6", listening:"369-397", speaking:"6"},
        level:5
    },
    {
        text:'CLB 6',
        scoreWithSpouse:1,
        scoreWithoutSpouse:0,
        celpip:{reading:"6", writing:"6", listening:"6", speaking:"6"},
        ielts:{reading:"5.0", writing:"5.5", listening:"5.5", speaking:"5.5"},
        tef:{reading:"181-206", writing:"271-309", listening:"217-248", speaking:"271-309"},
        tcf:{reading:"406-452", writing:"7-9", listening:"398-457", speaking:"7-9"},
        level:6
    },
    {
        text:'CLB 7',
        scoreWithSpouse:3,
        scoreWithoutSpouse:0,
        celpip:{reading:"7", writing:"7", listening:"7", speaking:"7"},
        ielts:{reading:"6.0", writing:"6.0", listening:"6.0", speaking:"6.0"},
        tef:{reading:"207-232", writing:"310-348", listening:"249-279", speaking:"310-348"},
        tcf:{reading:"453-498", writing:"10-11", listening:"458-502", speaking:"10-11"},
        level:7
    },
    {
        text:'CLB 8',
        scoreWithSpouse:3,
        scoreWithoutSpouse:0,
        celpip:{reading:"8", writing:"8", listening:"8", speaking:"8"},
        ielts:{reading:"6.5", writing:"6.5", listening:"7.5", speaking:"6.5"},
        tef:{reading:"233-247", writing:"349-370", listening:"280-297", speaking:"349-370"},
        tcf:{reading:"499-523", writing:"12-13", listening:"503-522", speaking:"12-13"},
        level:8
    },
    {
        text:'CLB 9',
        scoreWithSpouse:5,
        scoreWithoutSpouse:0,
        celpip:{reading:"9", writing:"9", listening:"9", speaking:"9"},
        ielts:{reading:"7.0", writing:"7.0", listening:"8.0", speaking:"7.0"},
        tef:{reading:"248-262", writing:"371-392", listening:"298-315", speaking:"371-392"},
        tcf:{reading:"524-548", writing:"14-15", listening:"523-548", speaking:"14-15"},
        level:9
    },
    {
        text:'CLB 10 or More',
        scoreWithSpouse:5,
        scoreWithoutSpouse:0,
        celpip:{reading:"10", writing:"10", listening:"10", speaking:"10"},
        ielts:{reading:"8.0", writing:"7.5", listening:"8.5", speaking:"7.5"},
        tef:{reading:"263-300", writing:"393-450", listening:"316-360", speaking:"393-450"},
        tcf:{reading:"549-699", writing:"16-20", listening:"549-699", speaking:"16-20"},
        level:10
    }
]

export const SpouseCLBArrayOld = [
    {
        text:'CLB 4 or Less',
        scoreWithSpouse:0,
        scoreWithoutSpouse:0,
        level:0
    },
    {
        text:'CLB 5 or 6',
        scoreWithSpouse:1,
        scoreWithoutSpouse:0,
        level:5
    },
    {
        text:'CLB 7 or 8',
        scoreWithSpouse:3,
        scoreWithoutSpouse:0,
        level:6
    },
    {
        text:'CLB 9 or More',
        scoreWithSpouse:5,
        scoreWithoutSpouse:0,
        level:7
    }
]

export const CLB2ndLangArrayOLD = [
    {
        text:'CLB 4 or less',
        scoreWithSpouse:0,
        scoreWithoutSpouse:0,
        level:1
    },
    {
        text:'CLB 5 or 6',
        scoreWithSpouse:1,
        scoreWithoutSpouse:2,
        level:2
    },
    {
        text:'CLB 7 or 8',
        scoreWithSpouse:3,
        scoreWithoutSpouse:3,
        level:3
    },
    {
        text:'CLB 9 or more',
        scoreWithSpouse:6,
        scoreWithoutSpouse:6,
        level:4
    }
]
export const CanadianSkilledWorkExpArray = [
    {
        text:'None or less than a year	',
        scoreWithSpouse:0,
        scoreWithoutSpouse:0,
        level:1
    },
    {
        text:'1 year',
        scoreWithSpouse:35,
        scoreWithoutSpouse:40,
        level:2
    },
    {
        text:'2 Years',
        scoreWithSpouse:46,
        scoreWithoutSpouse:53,
        level:3
    },
    {
        text:'3 Years',
        scoreWithSpouse:56,
        scoreWithoutSpouse:64,
        level:4
    },
    {
        text:'4 Years',
        scoreWithSpouse:63,
        scoreWithoutSpouse:72,
        level:5
    },
    {
        text:'5 Years or More',
        scoreWithSpouse:70,
        scoreWithoutSpouse:80,
        level:6
    }



]

export const SpouseCandiandWorkExpArray = [
    {
        text:'None or less than a year	',
        scoreWithSpouse:0,
        scoreWithoutSpouse:0,
        level:1
    },
    {
        text:'1 year',
        scoreWithSpouse:5,
        scoreWithoutSpouse:0,
        level:2
    },
    {
        text:'2 Years',
        scoreWithSpouse:7,
        scoreWithoutSpouse:0,
        level:3
    },
    {
        text:'3 Years',
        scoreWithSpouse:8,
        scoreWithoutSpouse:0,
        level:4
    },
    {
        text:'4 Years',
        scoreWithSpouse:9,
        scoreWithoutSpouse:0,
        level:5
    },
    {
        text:'5 Years or More',
        scoreWithSpouse:10,
        scoreWithoutSpouse:0,
        level:6
    }



]


//Foreign work experience – With good official language proficiency (Canadian Language Benchmark Level [CLB] 7 or higher)

export const foreignSkilledWorkExpCLB = [

    {
        text:'No foreign work experience',
        scoreWithCLB7:0,
        scoreWithCLB9:0
    },
    {
        text:'1 or 2 years of foreign work experience',
        scoreWithCLB7:13,
        scoreWithCLB9:25
    },
    {
        text:'3 years or more of foreign work experience',
        scoreWithCLB7:25,
        scoreWithCLB9:50
    }
]

//Foreign work experience – With Canadian work experience

export const foreignSkilledWorkExpCanada = [
    {
        text:'No foreign work experience',
        scoreWithCanExp1:0,
        scoreWithCanExp2:0
    },
    {
        text:'1 or 2 years of foreign work experience',
        scoreWithCanExp1:13,
        scoreWithCanExp2:25
    },
    {
        text:'3 years or more of foreign work experience',
        scoreWithCanExp1:25,
        scoreWithCanExp2:50
    }
]


export const LMIAData = [
    {
        text:'NOC Skill Type - OO',
        score:200
    },
    {
        text:'NOC Skill Level A Or B or Any Other',
        score: 50
    },
    {
        text:'NOC Skill Level C Or D',
        score:0
    }

]
