import React,{ useState, useEffect } from 'react'
import axios from 'axios'

export function Updates() {

    const [posts, setPosts ] = useState([])
    const [currentPage, setCurrentPage ] = useState(1)
    const [postsPerPage, setPostsPerPage ] = useState(3)

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_API_URL}/user/post`)
        .then( res => {
            if(res.data.posts.length > 0){
                    setPosts(res.data.posts)
            }
        })
        .catch( err => console.log(err))

    },[])

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(posts.length / postsPerPage); i++) {
      pageNumbers.push(i);
    }


    let indexOfLastPost = currentPage * postsPerPage
    let indexOfFirstPost = indexOfLastPost - postsPerPage
    let currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost)

    const handleClick = e => {
        //console.log(e.target.id)
        setCurrentPage(e.target.id)
        
        indexOfLastPost = currentPage * postsPerPage
        indexOfFirstPost = indexOfLastPost - postsPerPage
        currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost)
      
    }

    return (
       
        <div>
             
            <div class="widget widget-recent-post bg-yellow">
                <h2 class="widget bg-default p-2">Recent News </h2>
                <ul class="listnone p-2">
                   { currentPosts.map( post => {
                       return  <li key={post._id}>
                            <h4 class="recent-post-title"><a href ={"/blogs#"+post._id} class="title">{post.title}</a></h4>
                            <p class="meta"><span class="meta-date">{new Date(post.timestamp).toUTCString()}</span> </p>
                        </li>
                   })}
                  

                </ul>
                <div className="text-right">
                    <ul className="updatesDisplayClass text-danger ">
                        {
                            pageNumbers.map(number => {
                                return <li key={number} id={number} className="p-1 text-right" onClick={e => handleClick(e)}>
                                   {number}

                                </li>
                            })
                        }
                    </ul>
                </div>
                <div className="text-right p-3"><a href="/blogs" >View All Updates</a></div>
            </div>
        </div>
    )
}

export default Updates