import React,{useState, useEffect} from 'react'
import axios from 'axios'

export function TopBarLight() {
    const [data, setData] = useState({phone:'', email:''})
    const loadData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/page/topbar`)
        .then(res => {
            console.log("Doo"+res.data.content.phone)
            setData(res.data.content)
        })
        .catch(err => {
            console.log(err)
        })
    }
    useEffect( () => {
        loadData()
    },[])
    return (
        <div>
            <div class="topbar">

                <div class="container">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-none d-sm-none d-lg-block d-xl-block">
                            <p class="welcome-text">Immigration Consultants Of Canada Regulatory Council</p>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="header-block">
                                <span class="header-link d-none d-xl-block"><a href="#" class="anchor-link">Talk to Our Expert</a></span>
                                <span className="header-link"><i class="fas fa-phone"></i> {data.phone}</span>
                                <span className="header-link"><i class="fas fa-envelope-square"></i>  {data.email}</span>
                                {/* <span class="header-link"> <button type="submit" class="" data-toggle="modal" data-target="#searchModal"> <i class="fa fa-search"></i></button></span>
                                <span class="header-link"><a href="#" class="btn btn-default btn-sm">Check Eligibility</a></span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TopBarLight