import React from 'react'

export function Slider() {
    return (
        <div>
        <div className="slider">
        
        <div className="slider-carsoule owl-carousel owl-theme">
            
            <div className="item">
                <img src="./assets/img/slider-3n.jpg" alt="Immigration visa consultant HTML5 Template"  />
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-sm-8 col-md-9 col-8">
                            <div className="slider-captions">
                                <h1 className="slider-title dotted-line">Immigration &amp; Visa Consultant</h1>
                                {/* <p className="slider-text">Proin venenatis orci felis, tincidunt sagittis mi lacinia vitae. Vestibulum erat nisi, tincidunt vel lobortis eu, imperdiet eu sapien.</p>
                                <a href="#" className="btn btn-default btn-lg">BOOK A CONSULTATION</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item">
                <img src="./assets/img/slider-1n.jpg" alt="Immigration Consulting Bootstrap Responsive Website Template"/>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-sm-8 col-md-9 col-8">
                            <div className="slider-captions">
                                <h1 className="slider-title dotted-line">One of the Most Trusted Immigration Companies</h1>
                                {/* <p className="slider-text">Vestibulum erat nisi, tincidunt vel lobortis eu, imperdiet eu sapien. Proin venenatis orci felis, tincidunt sagittis mi lacinia vitae. </p>
                                <a href="#" className="btn btn-default btn-lg">BOOK A CONSULTATION</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item">
                <img src="./assets/img/slider-2n.jpg" alt="Immigration Consulting Responsive Website Template" />
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-sm-8 col-md-9 col-8">
                            <div className="slider-captions">
                                <h1 className="slider-title dotted-line">Apply for Study Visa</h1>
                                {/* <p className="slider-text"> High-stakes English test for study, migration or work.</p> */}
                                {/* <a href="#" className="btn btn-default btn-lg">Start Coaching Today</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="slanting-pattern"></div>
    </div>

    )
}

export default Slider
