import React, {useState, useRef, useEffect} from 'react'
//import JoditEditor from "jodit-react"
import axios from 'axios'
import { getCookie } from '../helpers/Auth'

export function HomePage() {
    const editor = useRef(null)
	const [content, setContent] = useState({
        "heading":'',
        "para1":'',
        "para2":'',
        "contact":''
    })
    const [token, setToken] = useState(null)
    const [errMessage, setErrMessage ] = useState('')
    const [infoMessage, setInfoMessage ] = useState('')
    const loadData = async () => {
       
        await axios.get(`${process.env.REACT_APP_API_URL}/page/home-intro`)
        .then( res => {
            //console.log(res.data)
            setContent(res.data.content)
            
        })
        .catch(err => {
            //setErrMessage(err.message)
            console.log(err.message)

        })

    }
    useEffect(() => {
        loadData()
        let tk = getCookie('token')

        if(tk){
            setToken(tk)
        }
       // console.log(content)

    },[])
    


	const config2 = {
        headers: {
            
            'Authorization': 'Bearer ' + token
        }
    }

    const changeHandler = (e) => {
        content[e.target.name] = e.target.value
        setContent({...content})

    }
    
    const submitHandler = (e) => {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_API_URL}/page/home-intro`,{intro:content},config2)
        .then( res => {
            //console.log(res.data)
            setInfoMessage(res.data.message)
        })
        .catch(err => setErrMessage(err.message))
        //console.log(content)
       
    }
    const {heading, para1, para2, contact } = content
    return (
       
        <div className="container">
                { (errMessage) && 
                    <div className="bg-danger p-2 text-white">{errMessage}</div>
                }
                { (infoMessage) && 
                    <div className="bg-success p-2 text-white">{infoMessage}</div>
                }
             <form onSubmit={e => submitHandler(e)}>
                 <h1>Home Introduction Section</h1>
                {/* <JoditEditor
                    ref={editor}
                    value={content}
                    id="intro"
                    name="intro"
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                  //  onChange={newContent => { console.log(content)}}
                /> */}
                <div className="h5">Heading</div>
                <input
                    type="text"
                    name="heading"
                    id="heading"
                    value={heading}
                    className="form-control"
                    onChange = {e => changeHandler(e)}
                />
                <div className="h5">Paragraph 1</div>
                <textarea
                rows="3"
                cols="30"
                id="para1"
                name="para1"
                className="form-control"
                value={para1}
                onChange = {e => changeHandler(e)}>

                </textarea>
                <div className="h5">Paragraph 2</div>
                <textarea
                rows="3"
                cols="30"
                id="para2"
                name="para2"
                className="form-control"
                value={para2}
                onChange = {e => changeHandler(e)}>

                </textarea>
                <div className="h5">Contact Details</div>
                <input
                    type="text"
                    name="contact"
                    id="contact"
                    className="form-control"
                    value={contact}
                    onChange = {e => changeHandler(e)}
                />
                <br/>
                <input
                type="submit"
                value="Update"
                className="form-control btn btn-primary bg-primary"
                />
                </form>
        </div>
    )
}

export default HomePage