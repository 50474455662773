import React from 'react'
import Footer from '../components/Footers/Footer'
import FooterBlue from '../components/Footers/FooterBlue'
import HeaderWhite from '../components/Headers/HeaderLight/HeaderWhite'
import CalculatorCRSHero from '../components/Hero/CalculatorCRSHero'
import CalculatorHero from '../components/Hero/CalculatorHero'
import ContactUsHero from '../components/Hero/ContactUsHero'
import BcPNP from '../components/Pages/Calculator/BcPNP'
import CRSCalc from '../components/Pages/Calculator/CRSCalc'

export  function CRSCalcView() {
    return (
        <div>
            <HeaderWhite/>
            <CalculatorCRSHero/>            
            <CRSCalc/>
            <Footer/>
        </div>
    )
}

export default CRSCalcView
