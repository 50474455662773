import React from 'react'
import Main from './Main'
import Mission from './Mission'
import Vision from './Vision'

export default function Content() {
    return (
        <div>
            <div className="content pdb0">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                           <Main/>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="image-block"><img src={`${process.env.PUBLIC_URL}` + "/assets/img/aboutus.png"} alt="" className="img-fluid" /></div>
                        </div>
                    </div>
                </div>
                <div className="space-medium pdb0">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="feature-blurb mb20">
                                    <div className="feature-content">
                                       <Vision/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="feature-blurb">
                                    <div className="feature-content">
                                        <Mission/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="space-medium">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                <h3>Our Infrastructure</h3>
                                <p>Duis eu neque nulla. Vivamus risus diam, interdum dictum dignissim hendrerit, porttitor at ligula. Vestibulum ullamcorper, tortor nec commodo lacinia, magna turpis tempus augue, et dictum quam orci interdum purus. Curabitur tincidunt orci elit, et mattis quam efficitur quis.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 nopadding">
                            <div className=""><img src={`${process.env.PUBLIC_URL}` +"/assets/img/about1.jpg"} alt="" className="img-fluid" /></div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 nopadding">
                            <div className=""><img src={`${process.env.PUBLIC_URL}` +"/assets/img/about2.jpg"} alt="" className="img-fluid" /></div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="space-medium bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center">

                                    <h2>Why Nitro Immigration Immigration?</h2>
                                    <p> Since our founding, our primary goal has been to provide immigration in all over country and universities. Our impact is speak louder than our word.</p>
                                </div>

                            </div>
                        </div>
                        <div className="counter-section pdb0">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                    <div className="counter-block text-center">
                                        <h2 className="counter-title text-default">1000+</h2>
                                        <p className="counter-text">Clients</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                    <div className="counter-block text-center">
                                        <h2 className="counter-title text-secondary">800+</h2>
                                        <p className="counter-text"> Counselled</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                    <div className="counter-block text-center">
                                        <h2 className="counter-title text-warning">$19.5m+</h2>
                                        <p className="counter-text">in scholarship awards</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                    <div className="counter-block text-center">
                                        <h2 className="counter-title text-default">30+</h2>
                                        <p className="counter-text">Country</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="space-large">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="feature-blurb mb40">
                                    <div className="feature-content">
                                        <h3 className="feature-title">Meet Our Founders</h3>
                                        <p> Quisque sed ornare massa. In placerat elit ac mattis dignissim. Nunc sagittis orci a turpis sodales posuere. Duis eget consequat diam. Vivimperdiet eros sollicitudin ac. </p>
                                        <a href="#" className="btn btn-default"> Meet The Founder</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="feature-blurb">
                                    <div className="feature-content">
                                        <h3 className="feature-title">Partner Colleges</h3>
                                        <p> Phasellus eget eros tincidunt, ullamcorper nibh ut, pretium est. Nulla consequat tortor elit, porttitor famattis dignissim ucibus felis fringilla a. Donec at gravida est.</p>
                                        <a href="#" className="btn btn-default">View partner Colleges</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <br/>
                <div className="bg-section bg-background bg-background-imgAboutUsBottom" >
                    <div className="space-large">
                        <div className="container">
                            <div className="row">
                                <div className="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <div className="section-title text-center">
                                       
                                <h2 className="text-white">Working Together, We Can Help</h2>
                                        {/* <p>Quisque vel porta quam, ac placerat lacus. Nulla venenatis consequat ex, sed maximus ipsum molestie ut. Nunc sodales nibh iaculis diam tincidunt iaculis.</p>
                                        <a href="#" className="btn btn-primary">Join Us</a> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
