import React from 'react'
import Footer from '../components/Footers/Footer'
import FooterBlue from '../components/Footers/FooterBlue'
import HeaderWhite from '../components/Headers/HeaderLight/HeaderWhite'
import Calculator67Hero from '../components/Hero/Calculator67Hero'
import Point67Calc from '../components/Pages/Calculator/Point67Calc'

export default function BCPNPCalculator() {
    return (
        <div>
            <HeaderWhite/>
            <Calculator67Hero/>  
            <Point67Calc/>          
            <Footer/>
        </div>
    )
}
