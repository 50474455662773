import React, { Component } from 'react'
import axios from 'axios'
export class TestimonialUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            testimonials: []
        }
    }

    componentDidMount = async () => {
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/user/testimonial`)
        this.setState({ testimonials: response.data.testimonials })
        //console.log(this.state.testimonials)
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                            <div className="section-title text-center mb60">

                                <h2>What our Clients say about us. </h2>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.testimonials.map(testimonial => {
                        return  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="card-testimonial card-testimonial-light">

                                {/* <div className="card-testimonial-img"><img src="./assets/images/card-testimonial-img-1.jpg" alt="" className="rounded-circle" /></div> */}
                                <div className="card-testimonial-content">
                                    <p className="card-testimonial-text">“{testimonial.description}”</p>
                                </div>
                                <div className="card-testimonial-info">
                                    {/* <span className="card-testimonial-flag"><img src="./assets/images/country/canada.svg" className="flag-small"/></span> */}
                                    <h4 className="card-testimonial-name">{testimonial.from}</h4>
                                    <p><small>( {testimonial.topic} )</small></p>
                                </div>
                            </div>

                        </div>
                        })}
                        
                    </div>
                </div>

            </div>
        )
    }
}

export default TestimonialUpdate
