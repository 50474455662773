import React,{ useState, useEffect }  from 'react'
import axios from 'axios'
import TableBlog from './TableBlog'
import TBlogs from './TBlogs'
import { getCookie } from '../helpers/Auth'
import {  Toast } from 'react-bootstrap'

export function Blog(props) {

    const [post, setPost ] = useState({
        title:'',
        shortDesc:'',
        fullDesc:'',
        display:''
        

    })
    const [blogId, setBlogId] = useState(null)
    const [show, setShow] = useState(false)
    const [ editMode, setEditMode ] = useState(false)
    const [featureImage, setFeatureImage ] = useState(null)
    const [image, setImage ] = useState(null)

    const [token , setToken ] = useState(null)
    const config2 = {
        headers: {
            
            'Authorization': 'Bearer ' + token
        }
    }

    const loadData = async(id) => {
        await axios.get(`${process.env.REACT_APP_API_URL}/admin/blog/${id}`,
            config2
        )
        .then( res => {
            console.log(res.data)
            setPost(res.data.blog)
            setImage(res.data.blog.fileName)
        })
        .catch(err => {
            console.log(err)
        })
    }


    useEffect(() => {
        console.log(props.match.params.id)
        let tk = getCookie('token')

        if(tk){
            setToken(tk)
        }

        let id = props.match.params.id
        if(id){
            setBlogId(id)
            setEditMode(true)
            loadData(id)
        }

    },[])
    

  
    const [msg, setMsg ] = useState('')
    const changeHandler = (e) => {

        
        post[e.target.name] = e.target.value
        setPost({...post})
       // console.log(post)
    }

    const fileChangeHandler = (e) => {

        setFeatureImage(e.target.files[0])

    }
    const { title, shortDesc, fullDesc, display } = post

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
        }
    }

    // let configHeaders = {
    //     headers: {
    //       'Authorization': 'Bearer ' + token
    //     }
    // }
    const submitHandler = (e) => {
        e.preventDefault()
       // console.log(featureImage)
        const formData = new FormData()
        formData.append("title",title)
        formData.append("shortDesc", shortDesc)
        formData.append("fullDesc",fullDesc)
        formData.append(
            "demo_image",
            featureImage,
           
        )
       
        axios.post(`${process.env.REACT_APP_API_URL}/admin/blog`,
          
            formData,
            config
        )
        .then(res => {
            console.log(res.data)
            if(res.data.success){
                setShow(true)
                setMsg(res.data.message)
                
            }
        })
        .catch( err => {
            setShow(true)
            console.log(err.message)
            setMsg(err.message)
        })
        
      // console.log(post)


    }


    const updateHandler = (e, id) => {
        e.preventDefault()
        axios.put(`${process.env.REACT_APP_API_URL}/admin/blog/${id}`,{
          
            title,
            shortDesc,
            fullDesc,
            display
        },
            config2
        )
        .then(res => {
            console.log(res.data)
            if(res.data.success){
                setShow(true)
                setMsg(res.data.message)
                
            }
        })
        .catch( err => {
            setShow(true)
            console.log(err.message)
            setMsg(err.message)
        })
    }

    // Code for Updating records
    const updateImageHandler = (e, id) => {
        e.preventDefault()
       // console.log("Update Called")
        const formData = new FormData()
        // formData.append("title",title)
        // formData.append("shortDesc", shortDesc)
        // formData.append("fullDesc",fullDesc)
        if(featureImage){
            formData.append(
                "demo_image",
                featureImage,
            
            )
        }
       
        axios.put(`${process.env.REACT_APP_API_URL}/admin/blog/image/${id}`,
          
            formData,
            config
        )
        .then(res => {
            console.log(res.data)
            if(res.data.success){
                setShow(true)
                setMsg(res.data.message)
                
            }
        })
        .catch( err => {
            setShow(true)
            console.log(err.message)
            setMsg(err.message)
        })
    }
    return (
        <div>
            <div className="container">
                <h1 className="text-center">Blog Post Form</h1> 
                { (show === 2) && <div className="row bg-danger p-2">{msg}</div>}
                { (show) && 
                    <Toast onClose={() => setShow(false)} show={show} delay={2000} autohide>
                        <Toast.Header>
                        <strong className="mr-auto">Nitro Message</strong>
                        <small>Please Read...</small>
                        </Toast.Header>
                        <Toast.Body>{msg}</Toast.Body>
                    </Toast>
              }
                <form onSubmit={(editMode) ? e => updateHandler(e, blogId): e => submitHandler(e)}  action="#">
                    
                    
                <div className="row  p-2">

                    <div className="col-md-6 text-right">
                           <label className="fomr-control">Title of the Post</label> 
                    </div>

                    <div className="col-md-6">
                            <input
                                type="text"
                                className="form-control"
                                name="title"
                                id="title"
                                onChange = { e => changeHandler(e) }
                                value = {title}
                                placeholder="Specify the title of your post"
                                required

                            />
                    </div>
                </div>
                <div className="row p-2">
                    <div className="col-md-6 text-right">
                    <label className="fomr-control">Short Description of the Post (100 Words)</label>
                    </div>

                    <div className="col-md-6">
                            <textarea
                                rows="3"
                                cols="50"
                                type="text"
                                name="shortDesc"
                                id="shortDesc"
                                className="form-control"
                                onChange = { e => changeHandler(e) }
                                value = {shortDesc}

                                placeholder="Specify the short description of your post"
                                required

                            ></textarea>
                    </div>
                </div>
                <div className="row p-2">
                    <div className="col-md-6 text-right">
                    <label className="fomr-control">Full Description of the Post or Article </label>
                    </div>

                    <div className="col-md-6">
                            <textarea
                                className="form-control"
                                rows="7"
                                cols="50"
                                type="text"
                                name="fullDesc"
                                id="fullDesc"
                                onChange = { e => changeHandler(e) }
                                value = {fullDesc}
                                placeholder="Specify the full description of your post"
                                required

                            ></textarea>
                    </div>
                </div>
                { (editMode) && <div className="row p-2">
                    <div className="col-md-6 text-right">
                        <label className="fomr-control">Do you want to display on website?</label>
                    </div>

                    <div className="col-md-6">
                           <select id="display" name="display" onChange={e => changeHandler(e)}>
                               <option value="no" selected={ (!display) && 'selected' }>No</option>
                               <option value="yes"  selected={ (display) && 'selected' }>Yes</option>
                              
                           </select>

                    </div>
                </div> }

                { (!editMode)  && 
                <div className="row  p-2">
                    <div className="col-md-6 text-right">
                    <label className="fomr-control">Upload the Featured Image</label>
                    </div>
                    
                    <div className="col-md-6">
                            {(editMode) && <img src={`${process.env.PUBLIC_URL}/uploads/${image}`}  width="200" height="200"/> }
                            <input
                                type="file"
                                name="featureImage"
                                id="featureImage"
                                onChange = { e => fileChangeHandler(e) }
                                
                                placeholder="Specify the title of your post"
                                

                            />
                    </div>
                </div>
                }
                <div className="row  p-2">
                    <div className="col-md-6 text-right">
                           
                    </div>
                    
                    <div className="col-md-6">
                            <input
                                type="submit"
                                value={ (editMode)? 'Update' : 'Save'}
                                className="form-control btn bg-primary btn-primary"
                                
                            />
                    </div>
                </div>
                </form>
            </div>
            {/* this container for table */}
            {/* <div className="container">
                <TBlogs />
            </div> */}
            <div className="container">
                <br/>
            { (editMode)  && 
                <form id="imageFrm" onSubmit={ e => updateImageHandler(e, blogId)}>
                <div className="row  p-2">
                    <div className="col-md-6 text-right">
                    <label className="fomr-control">Change the Featured Image (Seperately)</label>
                    </div>
                    
                    <div className="col-md-6">
                            {(editMode) && <img src={`${process.env.PUBLIC_URL}/uploads/${image}`}  width="100" height="100"/> }
                            <input
                                type="file"
                                name="featureImage"
                                id="featureImage"
                                onChange = { e => fileChangeHandler(e) }
                                
                                placeholder="Specify the title of your post"
                                

                            />
                    </div>
                </div>
                <div className="row  p-2">
                    <div className="col-md-6 text-right">
                    
                    </div>
                    
                    <div className="col-md-6">
                    <div className="col-md-6">
                            <input
                                type="submit"
                                value="Update Image Only"
                                className="form-control btn bg-primary btn-primary"
                                
                            />
                    </div>
                    </div>
                </div>
                </form>
                }
            </div>
            <div className="container text-right">
                 <a href="/admin/blogList" className="btn btn-default btn-sm text-right">Go to Blog List</a>
            </div>
        </div>
    )
}

export default Blog