
import React, { Component } from 'react'
import axios from 'axios'

export class Intro extends Component {
    constructor() {

        super()
        this.state = { intro: {
            "heading":'',
            "para1":'',
            "para2":'',
            "contact":''
        } }
        
        
       // this.updateHandler = this.updateHandler.bind(this)
    }   

   
   async loadData(){
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/page/home-intro`)
        this.setState({ intro : response.data.content })
    }
    componentDidMount = async () => {

        this.loadData()
        //console.log(this.state)
       
      

    }

    render() {
        return (
            <div>

                    {/* <p className="lead">Nitro Canadian Immigration is the Immigration firm that provides advice on matters pertaining to immigrating and in other visa categories. </p> */}
                    
                     <h2>{this.state.intro.heading}</h2>
                    
                    <p>{this.state.intro.para1}</p>
                    <p>{this.state.intro.para2}</p>
                    
                    <p className="text-default">{this.state.intro.contact}</p> 
            </div>
        )
    }
}

export default Intro

// export function Intro() {
//     const [content, setContent ] = useState('')

//     useEffect(() => {

//         axios.get(`${process.env.REACT_APP_API_URL}/page/home-intro`)
//         .then( res => {
           
//                     setContent(res.data.intro)
//                     console.log(content)
//         })
//         .catch( err => console.log(err))


//     },[]) 


//     return (
//         <div>
//                 {content}
            
//         </div>
//     )
// }

// export default Intro
