import React, {useState, useRef, useEffect} from 'react'
import JoditEditor from "jodit-react"
import axios from 'axios'
import { getCookie } from '../helpers/Auth'
export function MissionPage() {
    const editor = useRef(null)
    const [content, setContent] = useState(null)
    const [token, setToken] = useState(null)

    const [errMessage, setErrMessage ] = useState('')
    const [infoMessage, setInfoMessage ] = useState('')
    const config = {
		readonly: false // all options from https://xdsoft.net/jodit/doc/
	}

    const loadData = async () => {
      await axios.get(`${process.env.REACT_APP_API_URL}/page/aboutus-mission`)
        .then(res => {

            //console.log("On load: "+res.data.content)
            setContent(res.data.content)
        })
        .catch(err => console.log(err.message))
    }

    const config2 = {
        headers: {
            
            'Authorization': 'Bearer ' + token
        }
    }

    useEffect(() => {
       loadData()
       let tk = getCookie('token')

       if(tk){
           setToken(tk)
       }
    },[])

    const submitHandler = (e) => {
        e.preventDefault(e)
       // console.log(""+content)
        axios.post(`${process.env.REACT_APP_API_URL}/page/aboutus-mission`, { content}, config2)
        .then(res => {
            //console.log(res.data)
            setContent(content)
            setInfoMessage(res.data.message)
            setTimeout(() => {
                setInfoMessage('')
            },5000)
            
        })
        .catch(err => {
            //console.log(err.message)
            setErrMessage(err.message)
            setTimeout(() => {
                setErrMessage('')
            },5000)
            
        
        })

        
    }

    return (
        <div className="container">
                { (errMessage) && 
                    <div className="bg-danger p-2 text-white">{errMessage}</div>
                }
                { (infoMessage) && 
                    <div className="bg-success p-2 text-white">{infoMessage}</div>
                }
                <br/>
                <h1>Update About Us Mission Section</h1>
            <form onSubmit={e => submitHandler(e)}>
            <JoditEditor
                    ref={editor}
                    value={content}
                    id="intro"
                    name="intro"
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    //onChange={newContent => setContent(newContent)}
                />

                <br/>
                <input
                    type="submit"
                    value="Update Content"
                    className="form-control btn btn-primary bg-primary"
                    />
                    </form>
                    <hr/>
                    <h1 className="text-center">Preview Content</h1>
                    <br/>
                    <div dangerouslySetInnerHTML={{__html: content}} />
        </div>

    )
}

export default MissionPage