import React, { Component } from 'react'

export class PartnerComing extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            isPartnerCanadian:null,
            partnerComingToCanada:null
        }

        this.changeHandler = this.changeHandler.bind(this)
        this.changeHandler2 = this.changeHandler2.bind(this)
    }
    
    changeHandler = (e) => {

        console.log(e.target.value)
        this.setState({
             isPartnerCanadian:e.target.value
         },this.props.partnerFromCanadaHandler(e.target.value))
        
        
    }

    changeHandler2 = (e) => {
         this.setState({
             partnerComingToCanada:e.target.value
         },this.props.partnerComingHandler(e.target.value))

        
    }
    render() {
        return (
            <div>
              
                                    <div className="form-group">
                                        <label for="name">2.) i. Is your spouse or common-law partner a citizen or permanent resident of Canada? </label>
                                        <select id="isPartnerCanadian" name="isPartnerCanadian" className="form-control" onChange={this.changeHandler}>
                                           <option value="">Please Select..</option>
                                           <option value="yes">Yes</option>
                                           <option value="no">No</option>
                                        </select>
                                       
                                    </div>
                                    {(this.state.isPartnerCanadian == 'no') && <div className="form-group">
                                        <label for="name">2) ii. Will your spouse or common-law partner come with you to Canada? </label>
                                        <select id="partnerComingToCanada" name="partnerComingToCanada" className="form-control" onChange={this.changeHandler2}>
                                           <option value="">Please Select..</option>
                                           <option value="yes">Yes</option>
                                           <option value="no">No</option>
                                        </select>
                                       
                                    </div>}
                    
            </div>
        )
    }
}

export default PartnerComing
