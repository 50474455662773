import React, {useState, useEffect} from 'react'
import axios from 'axios'
export function WhyUs() {
    const [content, setContent] = useState(null)
    const loadData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/page/why-nitro`)
          .then(res => {
  
             // console.log("On load: "+res.data.content)
              setContent(res.data.content)
          })
          .catch(err => console.log(err.message))
      }
  
      useEffect(() => {
         loadData()
      },[])


    return (
        <div>
            <div className="space-large bg-light">
       
        <div className="container">
            <div dangerouslySetInnerHTML={{__html: content}} />
            {/* <div className="row">
                <div className="offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                    <div className="section-title text-center">
                       
                        <h2>Why Nitro Canadian Immigration?</h2>
                        <p>In order to help the global community, as an immigration expert we are committed to provide services regarding migration to Canada, advisory services and other immigration-related queries. Our team is dedicated to provide expert advice and immigration assistance to those aspiring to study, work or settle in Canada.</p>
                    </div>
                   
                </div>
            </div>
            <div className="mt80">
                <div className="impact-section">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="impact-block">
                                <div className="impact-icon mb40 icon-default"><span className="icon-student-1"></span></div>
                                <h2 className="impact-title">482+</h2>
                                <p className="impact-text">Students</p>
                                <p>Maecenas non augue sit amet tortor vehicula sagitulaa tempus, sit amet porttitor nisi rutrum.</p>
                               
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="impact-block">
                                <div className="impact-icon mb40 icon-default"><span className="icon-diploma"></span></div>
                                <h2 className="impact-title">90+</h2>
                                <p className="impact-text"> Immigration</p>
                                <p>Maecenas non augue sit amet tortor vehicula sagitulaa tempus, sit amet porttitor nisi rutrum.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="impact-block">
                                <div className="impact-icon mb40 icon-default"><span className="icon-university"></span></div>
                                <h2 className="impact-title">30+</h2>
                                <p className="impact-text">Universities</p>
                                <p>Maecenas non augue sit amet tortor vehicula sagitulaa tempus, sit amet porttitor nisi rutrum.</p>
                                
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="impact-block">
                                <div className="impact-icon mb40 icon-default"><span className="icon-earth-globe"></span></div>
                                <h2 className="impact-title">20+</h2>
                                <p className="impact-text">Country</p>
                                <p>Maecenas non augue sit amet tortor vehicula sagitulaa tempus, sit amet porttitor nisi rutrum.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    </div>
        </div>
    )
}

export default WhyUs
