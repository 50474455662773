import React from 'react'
import Footer from '../components/Footers/Footer'
import HeaderTransparent from '../components/Headers/Header/HeaderTransparent'
import Assessment from '../components/Pages/Home/Assessment'
import BlogsUpdate from '../components/Pages/Home/BlogsUpdate'
import ChooseCountry from '../components/Pages/Home/ChooseCountry'
import Lawyer from '../components/Pages/Home/Lawyer'
import News from '../components/Pages/Home/News'
import Testimonial from '../components/Pages/Home/Testimonial'
import TestimonialUpdate from '../components/Pages/Home/TestimonialUpdate'
import WhyUs from '../components/Pages/Home/WhyUs'
import Slider from '../components/Sliders/Slider'


export function Index() {
    return (
        <div>
            <HeaderTransparent/>
            <Slider/>            
            <Assessment/>
            <ChooseCountry/>
            <WhyUs/>
            <Lawyer/>
            {/* <Testimonial/> */}
            <TestimonialUpdate/>
            {/* <BlogsUpdate/>             */}
            <Footer/>
            
        </div>
    )
}
export default Index