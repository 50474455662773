import React from 'react'
import Footer from '../components/Footers/Footer'
import FooterBlue from '../components/Footers/FooterBlue'
import HeaderWhite from '../components/Headers/HeaderLight/HeaderWhite'
import CalculatorHero from '../components/Hero/CalculatorHero'
import ContactUsHero from '../components/Hero/ContactUsHero'
import BcPNP from '../components/Pages/Calculator/BcPNP'

export default function BCPNPCalculator() {
    return (
        <div>
            <HeaderWhite/>
            <CalculatorHero/>            
            <BcPNP/>
            <Footer/>
        </div>
    )
}
