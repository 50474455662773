import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Intro from './Intro'
import Updates from './Updates'

export function ChooseCountry() {

    const [content, setContent] = useState({
        studyVisa:'',
        workPermit:'',
        expressEntry:'',
        lmiaPermit:'',
        familySponsorship:'',
        pnpVisa:'',
        touristVisa:'',
        childCareVisa:''
    })
    const loadData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/page/visa-section`)
          .then(res => {
  
             //console.log("On load: "+res.data.content.studyVisa)
              setContent(res.data.content)
          })
          .catch(err => console.log(err.message))
      }
  
      useEffect(() => {
         loadData()
      },[])

    return (
        <div>
               <div className="space-medium">
        <div className="container">
        
            <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                        <Intro/>    
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                    <Updates/>
                </div>
            </div>
                
            {/* <div className="row">
                <div className="offset-xl-1 col-xl-10 offset-lg-2 col-lg-10 col-md-12 col-sm-12 col-12">
                    <div className="section-title text-center">
                        
                        <h2>Nitro Canadian Immigration and Visa Consulting Ltd.</h2>
                        <p> Nitro Canadian Immigration offers a fully comprehensive immigration consultancy service. Our experts have a deep wealth of knowledge across all visa categories and are committed to offer right strategy to follow. </p>
                    </div>
                    
                </div>
            </div> */}
            <div className="row mt60">
                <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 col-12">
                    <div className="visa-section visa-tabs">
                       
                        <ul className="nav nav-pills" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="canada-tab" data-toggle="tab" href="#canada" role="tab" aria-controls="canada" aria-selected="true"><span><img src="./assets/images/country/canada.svg" alt="" className="flag-xs"/></span> Canada As Your Immigration Destination </a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" id="australia-tab" data-toggle="tab" href="#australia" role="tab" aria-controls="australia" aria-selected="false"><span><img src="./assets/images/country/australia.svg" alt=""  className="flag-xs"/></span> Australia</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="united-kingdom-tab" data-toggle="tab" href="#united-kingdom" role="tab" aria-controls="united-kingdom" aria-selected="false"><span> <img src="./assets/images/country/united-kingdom.svg" alt=""  className="flag-xs"/></span> United Kingdom</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="usa-tab" data-toggle="tab" href="#usa" role="tab" aria-controls="usa" aria-selected="false"> <span><img src="./assets/images/country/united-states-of-america.svg" alt=""  className="flag-xs"/></span>  USA</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="germany-tab" data-toggle="tab" href="#germany" role="tab" aria-controls="germany" aria-selected="false"> <span><img src="./assets/images/country/germany.svg" alt=""  className="flag-xs"/></span> Europe</a>
                            </li> */}
                            {/* <li className="nav-item">
                                <a className="nav-link" id="singapore-tab" data-toggle="tab" href="#singapore" role="tab" aria-controls="singapore" aria-selected="false"> <span><img src="./assets/images/country/singapore.svg" alt=""  className="flag-xs"/></span>  Singapore</a>
                            </li> */}
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane active fade show" id="canada" role="tabpanel" aria-labelledby="canada-tab">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src={`${process.env.PUBLIC_URL}`+"/assets/img/study-visa1.jpg"} width="262" height="204"  alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Student Visa</a></h3>
                                                <p>           
                                                     <div dangerouslySetInnerHTML={{__html: content.studyVisa}} />
                                                </p>
                                                <a href="/visas#study-visa" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src={`${process.env.PUBLIC_URL}`+"/assets/img/work-visa1.jpg"}  width="262" height="204"   alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Work Permit</a></h3>
                                                <p><div dangerouslySetInnerHTML={{__html: content.workPermit}} /></p>
                                                <a href="/visas#work-permit" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src={`${process.env.PUBLIC_URL}`+"/assets/img/express-entry1.jpg"} alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Express Entry</a></h3>
                                                <p><div dangerouslySetInnerHTML={{__html: content.expressEntry}} /></p>
                                                <a href="/visas#express-entry" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src={`${process.env.PUBLIC_URL}`+"/assets/img/lmia-visa1.jpg"} alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">LMIA</a></h3>
                                                <p><div dangerouslySetInnerHTML={{__html: content.lmiaPermit}} /></p>
                                                <a href="/visas#lmia-permit" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src={`${process.env.PUBLIC_URL}`+"/assets/img/family-visa1.jpg"} alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Family Sponsorship</a></h3>
                                                <p><div dangerouslySetInnerHTML={{__html: content.familySponsorship}} /></p>
                                                <a href="/visas#family-sponsorship" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src={`${process.env.PUBLIC_URL}`+"/assets/img/pnp-visa1.jpg"}  width="262" height="204"  alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Provincial Nominee Program (PNP)</a></h3>
                                                <p><div dangerouslySetInnerHTML={{__html: content.pnpVisa}} /></p>
                                                <a href="/visas#pnp" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src={`${process.env.PUBLIC_URL}`+"/assets/img/tourist-visa1.jpg"} alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Tourist & Visitor Visa</a></h3>
                                                <p><div dangerouslySetInnerHTML={{__html: content.touristVisa}} /></p>
                                                <a href="/visas#tourist-visa" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src={`${process.env.PUBLIC_URL}`+"/assets/img/home-care-visa1.jpg"} alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Home Child Care Nominee Pilot Program</a></h3>
                                                <p><div dangerouslySetInnerHTML={{__html: content.childCareVisa}} /></p>
                                                <a href="/visas#child-care" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="tab-pane fade" id="australia" role="tabpanel" aria-labelledby="australia-tab">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src={`${process.env.PUBLIC_URL}`+"/assets/images/visa-img-2.jpg"} alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Business Visa</a></h3>
                                                <p>Quam semper simple dummy eleifend lorem in imperdiet mauris estibulum et efficitur lorem ipsum.</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src={`${process.env.PUBLIC_URL}`+"/assets/images/visa-img-1.jpg"} alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Student Visa</a></h3>
                                                <p>Studying In The canada The generom repetition jected humour, or non-characteristic words etc.</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src={`${process.env.PUBLIC_URL}`+"/assets/images/visa-img-2.jpg"} alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Business Visa</a></h3>
                                                <p>Quam semper simple dummy eleifend lorem in imperdiet mauris estibulum et efficitur lorem ipsum.</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src={`${process.env.PUBLIC_URL}`+"/assets/images/visa-img-3.jpg"} alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Job Seeker Visa</a></h3>
                                                <p>Duis id quam semper, eleifend lorem in, imperdiet mauris simple estibulum imple et efficitur nunc.</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="tab-pane fade" id="united-kingdom" role="tabpanel" aria-labelledby="united-kingdom-tab">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src="./assets/images/visa-img-3.jpg" alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Job Seeker Visa</a></h3>
                                                <p>Duis id quam semper, eleifend lorem in, imperdiet mauris simple estibulum imple et efficitur nunc.</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src="./assets/images/visa-img-1.jpg" alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Student Visa</a></h3>
                                                <p>Studying In The canada The generom repetition jected humour, or non-characteristic words etc.</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src="./assets/images/visa-img-2.jpg" alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Business Visa</a></h3>
                                                <p>Quam semper simple dummy eleifend lorem in imperdiet mauris estibulum et efficitur lorem ipsum.</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src="./assets/images/visa-img-4.jpg" alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Tourist & Visitor Visa</a></h3>
                                                <p>Suspendisse lobortis eros ac blan mi aliquet justo sit amet aliquet nunc simple dummy content risus a felis</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="tab-pane fade" id="usa" role="tabpanel" aria-labelledby="usa-tab">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src="./assets/images/visa-img-4.jpg" alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Tourist & Visitor Visa</a></h3>
                                                <p>Suspendisse lobortis eros ac blan mi aliquet justo sit amet aliquet nunc simple dummy content risus a felis</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src="./assets/images/visa-img-1.jpg" alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Student Visa</a></h3>
                                                <p>Studying In The canada The generom repetition jected humour, or non-characteristic words etc.</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src="./assets/images/visa-img-2.jpg" alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Business Visa</a></h3>
                                                <p>Quam semper simple dummy eleifend lorem in imperdiet mauris estibulum et efficitur lorem ipsum.</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src="./assets/images/visa-img-3.jpg" alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Job Seeker Visa</a></h3>
                                                <p>Duis id quam semper, eleifend lorem in, imperdiet mauris simple estibulum imple et efficitur nunc.</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="tab-pane fade" id="germany" role="tabpanel" aria-labelledby="germany-tab">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src="./assets/images/visa-img-2.jpg" alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Tourist & Visitor Visa</a></h3>
                                                <p>Suspendisse lobortis eros ac blan mi aliquet justo sit amet aliquet nunc simple dummy content risus a felis</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src="./assets/images/visa-img-1.jpg" alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Student Visa</a></h3>
                                                <p>Studying In The canada The generom repetition jected humour, or non-characteristic words etc.</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src="./assets/images/visa-img-3.jpg" alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Business Visa</a></h3>
                                                <p>Quam semper simple dummy eleifend lorem in imperdiet mauris estibulum et efficitur lorem ipsum.</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src="./assets/images/visa-img-4.jpg" alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Job Seeker Visa</a></h3>
                                                <p>Duis id quam semper, eleifend lorem in, imperdiet mauris simple estibulum imple et efficitur nunc.</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="tab-pane fade" id="singapore" role="tabpanel" aria-labelledby="singapore-tab">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src="./assets/images/visa-img-2.jpg" alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Tourist & Visitor Visa</a></h3>
                                                <p>Suspendisse lobortis eros ac blan mi aliquet justo sit amet aliquet nunc simple dummy content risus a felis</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src="./assets/images/visa-img-4.jpg" alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Student Visa</a></h3>
                                                <p>Studying In The canada The generom repetition jected humour, or non-characteristic words etc.</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src="./assets/images/visa-img-1.jpg" alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Business Visa</a></h3>
                                                <p>Quam semper simple dummy eleifend lorem in imperdiet mauris estibulum et efficitur lorem ipsum.</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-12">
                                        <div className="visa-card">
                                            <div className="visa-card-img zoomimg">
                                                <a href="#"> <img src="./assets/images/visa-img-3.jpg" alt="" className="img-fluid"/></a>
                                            </div>
                                            <div className="visa-card-content">
                                                <h3 className="visa-card-title"><a href="#" className="title">Job Seeker Visa</a></h3>
                                                <p>Duis id quam semper, eleifend lorem in, imperdiet mauris simple estibulum imple et efficitur nunc.</p>
                                                <a href="#" className="btn-link-primary">Read More</a>
                                            </div>
                                            <div className="slanting-pattern-small"></div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </div>
    )
}

export default ChooseCountry
