import React from 'react'
import Footer from '../components/Footers/Footer'
import HeaderWhite from '../components/Headers/HeaderLight/HeaderWhite'
import ContactUsHero from '../components/Hero/ContactUsHero'
import ContactForm from '../components/Pages/ContactUs/ContactForm'
import { ContactMap } from '../components/Pages/ContactUs/ContactMap'

export function ContactUs() {
    return (
        <div>
            <HeaderWhite/>
            <ContactUsHero/>
            <ContactForm/>
            {/* <ContactMap/> */}
            <Footer/>
        </div>
    )
}

export default ContactUs