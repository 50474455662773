import React from 'react'
import Footer from '../components/Footers/Footer'
import HeaderWhite from '../components/Headers/HeaderLight/HeaderWhite'
import BlogsHero from '../components/Hero/BlogsHero'
import VisaHero from '../components/Hero/VisaHero'
import Blogs from '../components/Pages/Blogs/Blogs'

export  function BlogsViews() {
    return (
        <div>
            <HeaderWhite/>
            <BlogsHero/>
            <Blogs/>
            <Footer/>
        </div>
    )
}

export default BlogsViews