import React from 'react'
import { AssessmentData } from './HomeData'
export function Assessment() {
    return (
        <div>
            <div className="bg-primary space-small text-left">
                   
                    <div className="container">
                        <div className="row">
                            
                            <div className="col-md-6 text-center">
                                <a href="/nitrocalc1" className="btn btn-default btn-lg mt10">BC PNP POINT CALCULATOR</a>
                            </div>
                            <div className="col-md-6 text-center">
                                <a href="/nitrocrs" className="btn btn-default btn-lg mt10">CRS Points - Express Entry</a>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}


export default Assessment