import React, { useState, useEffect } from 'react'
import axios from 'axios'
export function News() {

    const [posts, setPosts] = useState([])

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_API_URL}/user/blog`)
            .then(res => {
                if (res.data.posts.length > 0) {

                    setPosts(res.data.blogs)
                    console.log(posts)
                }

            })
            .catch(err => console.log(err))

    }, [])



    return (
        <div>
            <div className="space-medium bg-light text-left">

                <div className="container">

                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="section-title mb60">

                                <h2>Resources &amp; News</h2>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        {posts.map(post => {
                            return (
                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <div className="post-holder">
                                        <div className="post-img zoomimg">
                                            <a href="#"><img src={`${process.env.PUBLIC_URL}/uploads` + post.filename} alt="Nitro Immigrations Consultant Responsive Web Design Templates" className="img-fluid" /></a>
                                        </div>
                                        <div className="post-header">
                                            <h2 className="post-title"><a href="#" className="title">{post.title}</a></h2>
                                            <p className="meta"><span className="meta-date">1 Feb, 2018</span> <span className="meta-author">By <a href="#">Nitro Immigration</a></span> <span className="meta-comments"><a href="#">Comments</a></span></p>
                                        </div>
                                        <div className="post-content">
                                            <p>{post.shortDesc}</p>
                                            <a href="#" className="btn btn-default">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                        {/* <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <div className="post-holder">
                                    <div className="post-img zoomimg">
                                        <a href="#"><img src={`${process.env.PUBLIC_URL}`+"/assets/img/news2.jpg"} alt="Nitro Immigrations Consultant Responsive Web Design Templates" className="img-fluid"/></a>
                                    </div>
                                    <div className="post-header">
                                        <h2 className="post-title"><a href="#" className="title">Bootstrap Website Template for Nitro Consultant</a></h2>
                                        <p className="meta"><span className="meta-date">1 Feb, 2018</span> <span className="meta-author">By <a href="#">Nitro Immigration</a></span> <span className="meta-comments"><a href="#">Comments</a></span></p>
                                    </div>
                                    <div className="post-content">
                                        <p>Proin pulvinar accumsan lectusfeugiat, a sollicitudin massa imperdiet. Phasellus ac ex ligula. Nulla egestas lobortis mattis.</p>
                                        <a href="#" className="btn btn-default">Read More</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <div className="post-holder">
                                    <div className="post-img zoomimg">
                                        <a href="#"><img src={`${process.env.PUBLIC_URL}`+"/assets/img/news3.jpg"} alt="Nitro Immigrations Consultant Responsive Web Design Templates" className="img-fluid"/></a>
                                    </div>
                                    <div className="post-header">
                                        <h2 className="post-title"><a href="#" className="title">Start Your Bootstrap Blog Page Design</a></h2>
                                        <p className="meta"><span className="meta-date">1 Feb, 2018</span> <span className="meta-author">By <a href="#">Nitro Immigration</a></span> <span className="meta-comments"><a href="#">Comments</a></span></p>
                                    </div>
                                    <div className="post-content">
                                        <p>Proin pulvinar accumsan lectus, ut sagittis nislodio fetudin massa imperdiet. Phasellus ac ex ligula. Nulla egestas lobortis mattis.</p>
                                        <a href="#" className="btn btn-default">Read More</a>
                                    </div>
                                </div>
                            </div> */}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default News