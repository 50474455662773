import React from 'react'
export function AboutUsHero() {
    return (
        <div>
            <div className="page-header bg-background-imgAboutUs"> 
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <h1 className="page-title">About Nitro Canadian Immigration and Visa Consulting Ltd.</h1>
                            {/* <p className="page-description">Unique and Engaging about us page design template in bootstrap 4. Creating a best about us and company profile page for your website.</p>
                        */}
                       </div> 
                    </div>
                </div>
            </div>
            <div className="slanting-pattern"></div>
        </div>
    )
}

export default AboutUsHero