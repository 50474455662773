import React, { Component } from 'react'
import { educationScore } from '../../helpers/CRSHelper'
export class EducationCRS extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            eduArray : educationScore,
            part4B:false,
            part4C:false

        }

        this.changeHandler = this.changeHandler.bind(this)
        this.changeHandler2 = this.changeHandler2.bind(this)
        this.changeHandler3 = this.changeHandler3.bind(this)
    }
    
    changeHandler = (e) => {
        let obj = this.state.eduArray[e.target.value]
        this.setState({part4B:true})

        if(this.props.partner){
            this.props.setEducationScore(obj.scoreWithSpouse, obj.level)
        }else{
            this.props.setEducationScore(obj.scoreWithoutSpouse, obj.level)
        }

    }

    changeHandler2 = (e) => {
       if(e.target.value == "yes"){
           this.setState({
               part4C:true
           })
       }else{
            this.setState({
                part4C:false
            })
           this.props.setEngScoreShowStatus(true)
       }
    }

    changeHandler3 = (e) => {
        this.props.setCanadianDegreePoints(parseInt(e.target.value))
        this.props.setEngScoreShowStatus(true)
     }

    render() {

        return (
            <div>
                 <div className="form-group">
                    <label for="name">4) What is your level of education?</label>
                                      
                        <select id="educationLevel" name="educationLevel" className="form-control" onChange={this.changeHandler} >
                            <option value=''>Please Select..</option>
                            {this.state.eduArray.map((item, idx) => {
                                return <option value={idx}>{item.text}</option> 
                            })}
                        </select>
                    </div>

                    {(this.state.part4B) && <div className="form-group">
                    <label for="name">4B) Have you earned a Canadian degree, diploma or certificate?</label>
                                      
                        <select id="earnedCanadianDegree" name="earnedCanadianDegree" className="form-control" onChange={this.changeHandler2} >
                            <option value=''>Please Select..</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>}

                    {(this.state.part4C) && <div className="form-group">
                    <label for="name">4c) Choose the best answer to describe this level of education.</label>
                                      
                        <select id="earnedCanadianDegree" name="earnedCanadianDegree" className="form-control" onChange={this.changeHandler3} >
                            <option value=''>Please Select..</option>
                            <option value="0">Secondary High School Or Less</option>
                            <option value="15">Post-secondary education in Canada - credential of one or two years</option>
                            <option value="30">Post-secondary education in Canada - credential three years or longer</option>
                        </select>
                    </div>}
            </div>
        )
    }
}

export default EducationCRS
