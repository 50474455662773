import e from 'cors'
import React, { Component } from 'react'

export class CertQualification extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            certScore:null
             
        }
        this.changeHandler = this.changeHandler.bind(this)
    }
    
    componentDidUpdate = () => {
        
        if(this.state.certScore || this.state.certScore == 0){
            
            console.log(this.state)
            this.props.setCertOfQual(this.state.certScore)
            this.props.setLmiaShow(true)
            this.setState({
                
                certScore:null
            })
        }
    }


    changeHandler = (e) => {
        
        this.setState({

            certScore:parseInt(e.target.value)
        })
    }
    render() {
        return (
            <div>
               <div className="form-group">
                        <label for="name">7) Do you have a certificate of qualification from a Canadian province, territory or federal body?<br/>
Note: A certificate of qualification lets people work in some skilled trades in Canada. Only the provinces, territories and a federal body can issue these certificates. To get one, a person must have them assess their training, trade experience and skills to and then pass a certification exam.
<br/>
People usually have to go to the province or territory to be assessed. They may also need experience and training from an employer in Canada.
<br/>
This isn’t the same as a nomination from a province or territory.</label>
                                                      
                        <select id="certOfQual" name="certOfQual" className="form-control" onChange={this.changeHandler} >
                            <option value=''>Please Select..</option>
                            
                                 <option value="0">No</option> 
                                 <option value="50">Yes</option> 
                        </select>
                    </div>
            </div>
        )
    }
}

export default CertQualification
