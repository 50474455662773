import React, { Component } from 'react'
import Updates from '../Home/Updates'
import axios from 'axios'
export class Blogs extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             posts:[],
             currentPage:1,
             postsPerPage:10
        }

    }

    componentDidMount = async ()=> {
        await axios.get(`${process.env.REACT_APP_API_URL}/admin/post`)
        .then(res => {
            console.log(res.data)
            this.setState( {posts: res.data.posts})
        })
        .catch(err => {
            console.log(err)
        })
    }
    
    render() {
        return (
            <div className="container">
                <br/>
                <div className="h1">Recent News | Blogs</div>
                <div className="row">
                    <div className="col-md-7">
                            {this.state.posts.map(post => {
                                return <div id={post._id}>

                                        <div className="post-header">
                                            <h2 className="post-title"><a href="#" class="title">{post.title}</a></h2>
                                            <p className="meta"><span class="meta-date">{post.timestamp}</span> <span class="meta-author">By <a href="#" class="meta-link">Nitro Canadian Immigration</a></span> <span class="meta-comments"></span></p>
                                        </div>
                                        <div className="post-content">
                                            <p>{post.description}</p>
                                            <a href="/contactus" className="btn btn-default">For Query Contact Us</a>
                                        </div>
                                </div>
                            })}

                            <br/>
                        
                                   
                                    
                        </div>
                    <div className="col-md-5">
                        <Updates/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Blogs
