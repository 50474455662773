import React from 'react'

export  function FooterAdmin() {
    return (
        <div>
            <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright &copy; 2021 Nitro Canadian Immigration and Visa Consulting Ltd.</span>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default FooterAdmin