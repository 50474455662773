import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Dashboard from '../components/Admin/Dashboard';
import FooterAdmin from '../components/Admin/FooterAdmin';
import Navbar from '../components/Admin/Navbar'
import Blog from '../components/Admin/Blog';
import Sidebar from '../components/Admin/Sidebar'
import "./sb-admin-2.css"
import TBlogs from '../components/Admin/TBlogs';
import AddPost from '../components/Admin/AddPost';
import PostList from '../components/Admin/PostList';
import AddTestimonial from '../components/Admin/AddTestimonial';
import TestimonialList from '../components/Admin/TestimonialList';
import HomePage from '../components/Admin/HomePage';
import ContactList from '../components/Admin/ContactList';
import ExpertServicePage from '../components/Admin/ExpertServicePage';
// import StudyVisaPage from '../components/Admin/StudyVisaPage-OldNA';
import SubscriptionList from '../components/Admin/SubscriptionList';
import { WhyNitroPage } from '../components/Admin/WhyNitroPage';
import StudentVisaPage from '../components/Admin/StudentVisaPage';
import WorkPermitPage from '../components/Admin/WorkPermitPage';
import ExpressEntryPage from '../components/Admin/ExpressEntryPage';
import LMIAPage from '../components/Admin/LMIAPage';
import FamilySponsorshipPage from '../components/Admin/FamilySposorshipPage';
import PNPPage from '../components/Admin/PNPPage';
import TouristVisaPage from '../components/Admin/TouristVisaPage';
import ChildCarePage from '../components/Admin/ChildCarePage';
import AboutUsPage from '../components/Admin/AboutUsPage';
import VisionPage from '../components/Admin/VisionPage';
import MissionPage from '../components/Admin/MissionPage';
import ContactUsPage from '../components/Admin/ContactUsPage';
import BCCalcPage from '../components/Admin/BCCalcPage';
import BC67CalcPage from '../components/Admin/BC67CalcPage';
import VisaHomeSection from '../components/Admin/VisaHomeSection';
import SiteSettings from '../components/Admin/SiteSettings';


export function AdminLayout() {
    return (
        <div>
            <div id="page-top">
                <div id="wrapper">
                    <Sidebar />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Navbar/>
                            <BrowserRouter>
                                <Switch>
                                
                                    
                                    <Route path="/admin/dashboard" exact component={Dashboard} />
                                     <Route path="/admin/settings" exact component={SiteSettings}/> 
                                    <Route path="/admin/addPost" exact component={AddPost}/> 
                                    <Route path="/admin/post/:id" exact component={AddPost}/> 
                                    <Route path="/admin/postList" exact component={PostList}/>    
                                    <Route path="/admin/blog" exact component={Blog}/>
                                    <Route path="/admin/blog/:id" exact component={Blog}/>
                                    <Route path="/admin/blogList" exact component={TBlogs}/>
                                    <Route path="/admin/addTestimonial" exact component={AddTestimonial}/>
                                    <Route path="/admin/testimonial/:id" exact component={AddTestimonial}/> 
                                    <Route path="/admin/testimonialList" exact component={TestimonialList}/>  
                                    <Route path="/admin/contacts" exact component={ContactList} />  
                                    <Route path="/admin/homeEdit" exact component={HomePage} />
                                    <Route path="/admin/visaSection" exact component={VisaHomeSection} />
                                    <Route path="/admin/subscriptions" exact component={SubscriptionList}/>
                                    <Route path="/admin/whynitro" exact component={WhyNitroPage}/>
                                    <Route path="/admin/expertService" exact component={ExpertServicePage}/>
                                    <Route path="/admin/study-visa" exact component={StudentVisaPage}/>
                                    <Route path="/admin/work-permit" exact component={WorkPermitPage}/>
                                    <Route path="/admin/express-entry" exact component={ExpressEntryPage}/>
                                    <Route path="/admin/lmia-permit" exact component={LMIAPage}/>
                                    <Route path="/admin/family-sponsorship" exact component={FamilySponsorshipPage}/>
                                    <Route path="/admin/pnp-permit" exact component={PNPPage}/>
                                    <Route path="/admin/tourist-visa" exact component={TouristVisaPage}/>
                                    <Route path="/admin/child-care" exact component={ChildCarePage}/>
                                    <Route path="/admin/aboutus-main" exact component={AboutUsPage}/>
                                    <Route path="/admin/aboutus-vision" exact component={VisionPage}/>
                                    <Route path="/admin/aboutus-mission" exact component={MissionPage}/>
                                    <Route path="/admin/contactus-main" exact component={ContactUsPage}/>
                                    <Route path="/admin/bc-calc" exact component={BCCalcPage}/>
                                    <Route path="/admin/bc67-calc" exact component={BC67CalcPage}/>




                                    <Redirect from="/admin" to="/admin/dashboard" />


                                </Switch>
                            </BrowserRouter>
                        </div>
                        <FooterAdmin />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminLayout
