import React, { Component } from 'react'

export class NominationCert extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             nominationScore:null
        }
        this.changeHandler = this.changeHandler.bind(this)
    }
    componentDidUpdate = () => {
        if(this.state.nominationScore){
            this.props.setNominationCertScore(this.state.nominationScore)
            this.props.setRelativeInCanadaStatus(true)
            this.setState({
                nominationScore:null
            })

        }
    }
    changeHandler = (e) => {

        if(e.target.value){
            this.setState({
                nominationScore:e.target.value
            })
        }

    }
    render() {
        return (
            <div>
                <div className="form-group">
                        <label for="name">9) Do you have a nomination certificate from a province or territory?</label>
                                                      
                        <select id="certOfQual" name="certOfQual" className="form-control" onChange={this.changeHandler} >
                            <option value=''>Please Select..</option>
                            
                                 <option value="0">No</option> 
                                 <option value="600">Yes</option> 
                        </select>
                    </div>
            </div>
        )
    }
}

export default NominationCert
