import React, { useState, useEffect } from 'react'
import { getCookie } from '../helpers/Auth'

import axios from 'axios'

export function SiteSettings(props) {
    const [data, setData ] = useState({
        phone:'',
        email:''

       
    })
    const [token, setToken] = useState(null)
    const [errMessage, setErrMessage ] = useState('')
    const [infoMessage, setInfoMessage ] = useState('')
    const [editMode, setEditMode] = useState(false)
    
    const config = {
        headers: {
            
            'Authorization': 'Bearer ' + token
        }
    }
    const loadData = async () => {
       
        await axios.get(`${process.env.REACT_APP_API_URL}/page/topbar`,
            config
        )
        .then( res => {
            console.log(res.data)
            setData(res.data.content)
            
        })
        .catch(err => {
            setErrMessage(err.message)

        })

    }
    useEffect(() => {
        let tk = getCookie('token')

        if(tk){
            setToken(tk)
        }
        loadData()
        

    },[])
    

   
    const changeHandler = (e) => {

        
        data[e.target.name] = e.target.value
        setData({...data})
       // console.log(post)
    }

    const { phone, email } = data
    const submitHandler = (e) => {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_API_URL}/page/topbar`,{
            phone,
            email
        }, config )
        .then( res => {
            
            console.log(res.data)
            setInfoMessage(res.data.message)
        })
        .catch( err => {
            console.log(err.message)
            setErrMessage(err.message)
        })
        console.log("Worked")
    }

    
    return (
        <div className="container">
            <h1 className="text-center">Topbar Settings For Nitro Immigrations</h1>
            <form onSubmit={e => submitHandler(e)} action="#">
                { (errMessage) && 
                    <div className="bg-danger p-2 text-white">{errMessage}</div>
                }
                { (infoMessage) && 
                    <div className="bg-success p-2 text-white">{infoMessage}</div>
                }

                <div className="row  p-2">

                    <div className="col-md-3 text-right">
                        <label className="fomr-control">Main Phone Number</label>
                    </div>
                    
                    <div className="col-md-9">
                     <input
                            type="text"
                            className="form-control"
                            name="phone"
                            id="phone"
                            value = {phone}
                            onChange={e => changeHandler(e)}
                            placeholder="Specify the Main Phone Number of your company"
                            required

                        />
                    </div>
                </div>
                <div className="row  p-2">

                    <div className="col-md-3 text-right">
                        <label className="fomr-control">Main Email Address</label>
                    </div>
                    
                    <div className="col-md-9">
                     <input
                            type="text"
                            className="form-control"
                            name="email"
                            id="email"
                            value = {email}
                            onChange={e => changeHandler(e)}
                            placeholder="Specify the Main Email Addresss of your company"
                            required

                        />
                    </div>
                </div>
                

               

                <div className="row  p-2">
                    <div className="col-md-3 text-right">

                    </div>

                    <div className="col-md-9">
                        <input
                            type="submit"
                            value="Update"
                            className="form-control btn bg-primary btn-primary"

                        />
                    </div>
                </div>
            </form>

            {/* <div className="container text-right">
                 <a href="/admin/postList" className="btn btn-default btn-sm text-right">Go to Post List</a>
            </div> */}
           

        </div>
    )
}

export default SiteSettings