export const wageArray = [
    {
        minWage:0,
        maxWage:25000,
        points:0

    },
    {
        minWage:25000,
        maxWage:26249,
        points:3

    },
    {
        minWage:26250,
        maxWage:27499,
        points:4

    },
    {
        minWage:27500,
        maxWage:28749,
        points:5

    },
    {
        minWage:28750,
        maxWage:29999,
        points:6

    },
    {
        minWage:30000,
        maxWage:31249,
        points:7

    },
    {
        minWage:31250,
        maxWage:32499,
        points:8

    },
    {
        minWage:32500,
        maxWage:33749,
        points:9

    },
    {
        minWage:33750,
        maxWage:34999,
        points:10

    },
    {
        minWage:35000,
        maxWage:36249,
        points:11

    },
    {
        minWage:36250,
        maxWage:37499,
        points:12

    },
    {
        minWage:37500,
        maxWage:38749,
        points:13

    },
    {
        minWage:38750,
        maxWage:39999,
        points:14

    },
    {
        minWage:40000,
        maxWage:42499,
        points:15

    },
    {
        minWage:42450,
        maxWage:44999,
        points:16

    },
    {
        minWage:45000,
        maxWage:47499,
        points:17

    },
    {
        minWage:47500,
        maxWage:49999,
        points:18

    },
    {
        minWage:50000,
        maxWage:52499,
        points:19

    },
    {
        minWage:52500,
        maxWage:54999,
        points:20

    },
    {
        minWage:55000,
        maxWage:57499,
        points:21

    },
    {
        minWage:57500,
        maxWage:59999,
        points:22

    },
    {
        minWage:60000,
        maxWage:62499,
        points:23

    },
    {
        minWage:62450,
        maxWage:64999,
        points:24

    },
    {
        minWage:65000,
        maxWage:67499,
        points:25

    },
    {
        minWage:67500,
        maxWage:69999,
        points:26

    },
    {
        minWage:70000,
        maxWage:72499,
        points:27

    },
    {
        minWage:72500,
        maxWage:74999,
        points:28

    },
    {
        minWage:75000,
        maxWage:77499,
        points:29

    },
    {
        minWage:77500,
        maxWage:79999,
        points:30

    },
    {
        minWage:80000,
        maxWage:82499,
        points:31

    },
    {
        minWage:82500,
        maxWage:84999,
        points:32

    },
    {
        minWage:85000,
        maxWage:87499,
        points:33

    },
    {
        minWage:87500,
        maxWage:89999,
        points:34

    },
    {
        minWage:90000,
        maxWage:92499,
        points:35

    },
    {
        minWage:92500,
        maxWage:94999,
        points:36

    },
    {
        minWage:95000,
        maxWage:97499,
        points:37

    },
    {
        minWage:97500,
        maxWage:99999,
        points:38

    },
    {
        minWage:100000,
        maxWage:100000000,
        points:50

    },

]

export const skillLevelBcJobOffer = [
                                        {
                                            type:'O',
                                            points:25
                                        },
                                        {
                                            type:'A',
                                            points:25
                                        },
                                        {
                                            type:'B',
                                            points:10
                                        },
                                        {
                                            type:'C',
                                            points:5
                                        },
                                        {
                                            type:'D',
                                            points:5
                                        }
                                    ]
export const calculateAnnualWageBcJobOffer = (ratePerHour, weeklyHours) => {

        
        let annualWage = ratePerHour * weeklyHours * 52.143
       // console.log(annualWage)
        let obj = wageArray.find(o => {
            let object = null
            if(annualWage >= o.minWage && annualWage <= o.maxWage ){
                object = o
            }
            return object
        })
        return obj.points

    
}