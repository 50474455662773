import React, {useState, useRef, useEffect} from 'react'
import axios from 'axios'
export function Point67Calc() {

    const [fswpPoints, setFswpPoints ] = useState({
        CLBSpeakLevel : 0,
        CLBListenLevel : 0,
        CLBReadLevel : 0,
        CLBWriteLevel : 0,
        CLBSecondLangLevel : 0,
        educationLevel : 0,
        experience : 0,
        age : 0,
        arrangedEmp : 0,
        adaptability : 0

    })

    const [content, setContent] = useState(null)
    const loadData = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/page/bc67-calc`)
          .then(res => {
  
            //  console.log("On load: "+res.data.content)
              setContent(res.data.content)
          })
          .catch(err => console.log(err.message))
      }
  
      useEffect(() => {
         loadData()
      },[])

    const [ speakLevel, setSpeakLevel ] = useState(0)
    const [ listenLevel, setListenLevel ] = useState(0)
    const [ readLevel, setReadLevel ] = useState(0)
    const [ writeLevel, setWriteLevel ] = useState(0)
    

    const [adaptability, setAdaptability ] = useState([
        {
            id : 1,
            name:'Your spouse or partner’s language level',
            value: 5,
            checked:false
        },
        {
            id : 2,
            name:'Your past study in Canada',
            value: 5,
            checked:false
        },
        {
            id : 3,
            name:' Your spouse or partner’s past study in Canada',
            value: 5,
            checked:false
        },
        {
            id : 4,
            name:'Your past work in Canada',
            value:10,
            checked:false
        },
        {
            id : 5 ,
            name:'Your spouse or common-law partner’s past work in Canada',
            value: 5,
            checked:false
        },
        {
            id : 6,
            name:'Your spouse or common-law partner’s past work in Canada',
            value: 5,
            checked:false
        },
        {
            id : 7,
            name:'Relatives in Canada',
            value: 5,
            checked:false
        },
        

    ])

    const [ score, setScore ] = useState(0)

    const sumJsonObject = (obj) => {
        //console.log(Object.values(obj).reduce((acc,b) => parseInt(acc) +parseInt(b) ))
        return  Object.values(obj).reduce((acc,b) => parseInt(acc)+parseInt(b),0)
     }

    const changeHandler = ( e ) => {
       
        if(e.target.name === 'CLBSpeakLevel' && e.target.value === '0'){
            
            setSpeakLevel(1)
        }

        if(e.target.name === 'CLBListenLevel' && e.target.value === '0'){
            setListenLevel(1)
        }

        if(e.target.name === 'CLBReadLevel' && e.target.value === '0'){
            setReadLevel(1)
        }

        if(e.target.name === 'CLBWriteLevel' && e.target.value === '0'){
            setWriteLevel(1)
        }
       // console.log(e.target.value)
        fswpPoints[e.target.name] = e.target.value
        setScore(sumJsonObject(fswpPoints))

        
    }

    const checkChangeHandler = (id, e) => {

       adaptability.map(item => {
            if(item.id === id){
                 
                item.checked = !item.checked
                
                setAdaptability([...adaptability])
                //console.log(adaptability)
               
            } 

         })

         let maxScore = 0

         adaptability.forEach(item => {
             if(item.checked){
                    maxScore += item.value
             }
         })

         // At max only 10 points allowed for this section

         if(maxScore > 10){
             maxScore = 10
         }

         fswpPoints[e.target.name] = maxScore
         setScore(sumJsonObject(fswpPoints))



    }

    return (
        <div>
            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div dangerouslySetInnerHTML={{__html: content}} />
                            <br />
                        </div>

                    </div>

                    <div className="row">

                        <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div id="accordion">
                                <div className="card-accordion">
                                    <div className="card-accordion-header bg-danger" id="headingOne">
                                        <h5 className="mb0">
                                            <button className="collapsed text-white" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                1. First official language (Maximum 24 points)
                                    </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div className="card-accordion-body">
                                            <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-3 col-form-label">Speaking (CLB Level) <span className="text-danger">*</span></label>
                                                <div className="col-sm-2">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="CLBSpeakLevel"
                                                            id="CLBSpeakLevel"
                                                            value="0"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Below CLB Level 7</label>
                                                    </div>


                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="CLBSpeakLevel"
                                                            id="CLBSpeakLevel"
                                                            value="4"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">CLB Level 7</label>
                                                    </div>


                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="CLBSpeakLevel"
                                                            id="CLBSpeakLevel"
                                                            value="5"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">CLB Level 8</label>
                                                    </div>


                                                </div>

                                                <div className="col-sm-3">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="CLBSpeakLevel"
                                                            id="CLBSpeakLevel"
                                                            value="6"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">CLB Level 9 0r Above</label>
                                                    </div>


                                                </div>
                                            </div>
                                            { (speakLevel == 1) && 
                                                        <div className="form-group row bg-light">
                                                            <label for="inputPassword" className="col-sm-12 col-form-label">Do not qualify <span className="text-danger">*</span>( <small>If you have Less than CLB 7 in any Module than you don't Qualify for Federal Skilled Worker Program.</small> )</label>
                                                            <br/>
                                                        </div>        
                                            }
                                            <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-3 col-form-label">Listening (CLB Level) <span className="text-danger">*</span></label>
                                                <div className="col-sm-2">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="CLBListenLevel"
                                                            id="CLBListenLevel"
                                                            value="0"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Below CLB Level 7</label>
                                                    </div>


                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="CLBListenLevel"
                                                            id="CLBListenLevel"
                                                            value="4"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">CLB Level 7</label>
                                                    </div>


                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="CLBListenLevel"
                                                            id="CLBListenLevel"
                                                            value="5"

                                                            onChange = {e => changeHandler(e)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">CLB Level 8</label>
                                                    </div>


                                                </div>

                                                <div className="col-sm-3">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="CLBListenLevel"
                                                            id="CLBListenLevel"
                                                            value="6"

                                                            onChange = {e => changeHandler(e)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">CLB Level 9 0r Above</label>
                                                    </div>


                                                </div>
                                            </div>

                                            { (listenLevel == 1) && 
                                                        <div className="form-group row bg-light">
                                                            <label for="inputPassword" className="col-sm-12 col-form-label">Do not qualify <span className="text-danger">*</span>( <small>If you have Less than CLB 7 in any Module than you don't Qualify for Federal Skilled Worker Program.</small> )</label>
                                                            <br/>
                                                        </div>        
                                            }

                                            <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-3 col-form-label">Reading (CLB Level) <span className="text-danger">*</span></label>
                                                <div className="col-sm-2">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="CLBReadLevel"
                                                            id="CLBReadLevel"
                                                            value="0"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Below CLB Level 7</label>
                                                    </div>


                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="CLBReadLevel"
                                                            id="CLBReadLevel"
                                                            value="4"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">CLB Level 7</label>
                                                    </div>


                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="CLBReadLevel"
                                                            id="CLBReadLevel"
                                                            value="5"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">CLB Level 8</label>
                                                    </div>


                                                </div>

                                                <div className="col-sm-3">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="CLBReadLevel"
                                                            id="CLBReadLevel"
                                                            value="6"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">CLB Level 9 0r Above</label>
                                                    </div>


                                                </div>
                                            </div>
                                            { (readLevel == 1) && 
                                                        <div className="form-group row bg-light">
                                                            <label for="inputPassword" className="col-sm-12 col-form-label">Do not qualify <span className="text-danger">*</span>( <small>If you have Less than CLB 7 in any Module than you don't Qualify for Federal Skilled Worker Program.</small> )</label>
                                                            <br/>
                                                        </div>        
                                            }

                                            <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-3 col-form-label">Writing (CLB Level) <span className="text-danger">*</span></label>
                                                <div className="col-sm-2">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="CLBWriteLevel"
                                                            id="CLBWriteLevel"
                                                            value="0"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Below CLB Level 7</label>
                                                    </div>


                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="CLBWriteLevel"
                                                            id="CLBWriteLevel"
                                                            value="4"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">CLB Level 7</label>
                                                    </div>


                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="CLBWriteLevel"
                                                            id="CLBWriteLevel"
                                                            value="5"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">CLB Level 8</label>
                                                    </div>


                                                </div>

                                                <div className="col-sm-3">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="CLBWriteLevel"
                                                            id="CLBWriteLevel"
                                                            value="6"

                                                            onChange = {e => changeHandler(e)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">CLB Level 9 0r Above</label>
                                                    </div>


                                                </div>
                                            </div>

                                            { (writeLevel == 1) && 
                                                        <div className="form-group row bg-light">
                                                            <label for="inputPassword" className="col-sm-12 col-form-label">Do not qualify <span className="text-danger">*</span>( <small>If you have Less than CLB 7 in any Module than you don't Qualify for Federal Skilled Worker Program.</small> )</label>
                                                            <br/>
                                                        </div>        
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div id="accordion">
                                <div className="card-accordion">
                                    <div className="card-accordion-header bg-danger" id="headingTwo">
                                        <h5 className="mb0">
                                            <button className="collapsed text-white" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                2. Second official language (Maximum 4 points)

                                    </button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                        <div className="card-accordion-body">
                                            <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-4 col-form-label">At Least CLB Level 5 in ALL 4 Abilities? <span className="text-danger">*</span></label>
                                                
                                                <div className="col-sm-1">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="CLBSecondLevel"
                                                            id="CLBSecondLevel"
                                                            value="4"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Yes</label>
                                                    </div>


                                                </div>
                                                <div className="col-sm-1">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="CLBSecondLangLevel"
                                                            id="CLBSecondLangLevel"
                                                            value="O"

                                                            onChange = {e => changeHandler(e)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">No</label>
                                                    </div>


                                                </div>
                                                
                                                <div className="col-sm-6">
                                                    

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div id="accordion">
                                <div className="card-accordion">
                                    <div className="card-accordion-header bg-danger" id="headingThree">
                                        <h5 className="mb0">
                                            <button className="collapsed text-white" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                3. Education (Maximum 25 Points)

                                    </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                        <div className="card-accordion-body">
                                            <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-4 col-form-label">Education Level  <span className="text-danger">*</span></label>
                                                <div className="col-sm-8">
                                                    <div className="form-check form-check-inline">
                                                        <select className="form-control rounded" id="educationLevel" onChange = {e => changeHandler(e)} name="educationLevel">
                                                            <option value="0">Please select </option>
                                                            <option value="25">University Degree at the Doctoral (PHD) Level or Equal</option>
                                                            <option value="23">University Degree at the Master's Level or Equal</option>
                                                            <option value="22">Two or more post-secondary credentials, one of which was for three years or longer in duration	</option>
                                                            <option value="21">Three years or longer post-secondary credential	</option>
                                                            <option value="19">Two-year post-secondary credential</option>
                                                            <option value="15">One year post-secondary credential</option>
                                                            <option value="5">Secondary school	</option>
                                                        </select>
                                                        
                                                    </div>


                                                </div>
                                                
                                               
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row">

                        <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div id="accordion">
                                <div className="card-accordion">
                                    <div className="card-accordion-header bg-danger" id="headingFour">
                                        <h5 className="mb0">
                                            <button className="collapsed text-white" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                4. Experience (Maximum 15 Points)

                                    </button>
                                        </h5>
                                    </div>
                                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                        <div className="card-accordion-body">
                                            <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-4 col-form-label">Experience in Same NOC or HIgher  <span className="text-danger">*</span></label>
                                                <div className="col-sm-8">
                                                    <div className="form-check form-check-inline">
                                                        <select className="form-control rounded" id="experience" onChange = {e => changeHandler(e)} name="experience">
                                                            <option value="0">Please select </option>
                                                            <option value="9">1 Year</option>
                                                            <option value="11">2-3 Years</option>
                                                            <option value="13">4-5 Years</option>
                                                            <option value="15">6 Years or Above</option>
                                                            
                                                        </select>
                                                        
                                                    </div>


                                                </div>
                                                
                                               
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="row">

                        <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div id="accordion">
                                <div className="card-accordion">
                                    <div className="card-accordion-header bg-danger" id="headingFive">
                                        <h5 className="mb0">
                                            <button className="collapsed text-white" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                5. Age (Maximum 12 Points)

                                    </button>
                                        </h5>
                                    </div>
                                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                        <div className="card-accordion-body">
                                            <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-4 col-form-label">Age While Submitting Application  <span className="text-danger">*</span></label>
                                                <div className="col-sm-8">
                                                    <div className="form-check form-check-inline">
                                                        <select className="form-control rounded" onChange = {e => changeHandler(e)} id="age" name="age">
                                                            <option>Please select </option>
                                                            <option value="0">Under 18 Years</option>
                                                            <option value="12">18-35 Years</option>
                                                            <option value="11">36 Year</option>
                                                            <option value="10">37 year</option>
                                                            <option value="9">38 year</option>
                                                            <option value="8">39 year</option>
                                                            <option value="7">40 year</option>
                                                            <option value="6">41 year</option>
                                                            <option value="5">42 year</option>
                                                            <option value="4">43 year</option>
                                                            <option value="3">44 year</option>
                                                            <option value="2">45 year</option>
                                                            <option value="1">46 year</option>
                                                            <option value="0">47 or Above year</option>
                                                            

                                                        </select>
                                                        
                                                    </div>


                                                </div>
                                                
                                               
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div id="accordion">
                                <div className="card-accordion">
                                    <div className="card-accordion-header bg-danger" id="headingSix">
                                        <h5 className="mb0">
                                            <button className="collapsed text-white" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                6. Arranged Employment in Canada (Maximum 10 Points)


                                    </button>
                                        </h5>
                                    </div>
                                    <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                        <div className="card-accordion-body">
                                            <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-4 col-form-label">Do you Have Arranged Employment in Canada ? <span className="text-danger">*</span></label>
                                                <div className="col-sm-1">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="arrangedEmp"
                                                            id="arrangedEmp"
                                                            value="10"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Yes</label>
                                                    </div>


                                                </div>
                                                <div className="col-sm-1">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="arrangedEmp"
                                                            id="arrangedEmp"
                                                            value="O"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">No</label>
                                                    </div>


                                                </div>
                                                
                                                <div className="col-sm-6">
                                                    

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div id="accordion">
                                <div className="card-accordion">
                                    <div className="card-accordion-header bg-danger" id="headingSeven">
                                        <h5 className="mb0">
                                            <button className="collapsed text-white" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                7. Adaptability in Canada (Maximum 10 Points)



                                    </button>
                                        </h5>
                                    </div>
                                    <div id="collapseSeven" className="collapse
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    " aria-labelledby="headingSeven" data-parent="#accordion">
                                        <div className="card-accordion-body">
                                            <div className="form-group row bg-yellow">
                                                <label for="inputPassword" className="col-sm-4 col-form-label">Check All That Apply ? <span className="text-danger">*</span></label>
                                                <div className="col-sm-8">
                                                    {/* <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="adaptability"
                                                            id="adaptability"
                                                            value="5"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Your spouse or partner’s language level</label>
                                                    </div>
                                                    <br/>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="adaptability"
                                                            id="adaptability"
                                                            value="5"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Your past study in Canada</label>
                                                    </div>
                                                    <br/>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="adaptability"
                                                            id="adaptability"
                                                            value="5"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Your spouse or partner’s past study in Canada</label>
                                                    </div>
                                                    <br/>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="adaptability"
                                                            id="adaptability"
                                                            value="10"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Your past work in Canada</label>
                                                    </div>
                                                    <br/>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="adaptability"
                                                            id="adaptability"
                                                            value="5"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Your spouse or common-law partner’s past work in Canada</label>
                                                    </div>
                                                    <br/>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="adaptability"
                                                            id="adaptability"
                                                            value="5"
                                                            onChange = {e => changeHandler(e)}

                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Arranged Employment in Canada</label>
                                                    </div>
                                                    <br/>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="adaptability"
                                                            id="adaptability"
                                                            value="5"

                                                            onChange = {e => changeHandler(e)}
                                                        />
                                                        <label className="form-check-label" for="inlineRadio1">Relatives in Canada</label>
                                                    </div>
                                                    <br/> */}
                                                    {adaptability.map(item => {
                                                       return <div>
                                                                    <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            name="adaptability"
                                                                            id="adaptability"
                                                                            value={item.value}
                                                                            onChange = { e => checkChangeHandler(item.id, e)}
                                                                            
                                                                        />
                                                                <label className="form-check-label" for="inlineRadio1">{item.name}</label>
                                                        </div>

                                                    })}
                                                        


                                                </div>
                                                
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <br/>
                        <div className="container">
                            <div className="row bg-light">
                                    <div className="form-group row  align-middle">
                                                                <label for="inputPassword" className="col-sm-5 col-form-label"><strong>Total points under 67 Point Federal Skilled Worker Program <span className="text-danger">*</span></strong></label>
                                                                <div className="col-sm-2 ">
                                                                    <input type="text" readonly className="form-control rounded bg-warning" id="resultScore" name="resultScore" value={score}  placeholder=""/>                          
                                                                    
                                                                </div>
                                                                <div className="col-sm-5 ">
                                                                   {/* <a href="/contactus" className="btn btn-default">Contact Us For Further Information</a> */}
                                                                </div>
                                    </div>
                                    <div className="row">
                                    	<div className="col-md-6">
                                    		<a href="/contactus" className="btn btn-default">Contact Us For Further Information -  <i className='fas fa-phone'></i> +1 (604) 356-0747</a> 
                                    	</div>
                                    	<div className="col-md-6">
                                    		 
                                    	</div>
                                    </div>
                            </div>
                        </div>
                        
            </div>

        </div>
    )
}
export default Point67Calc
