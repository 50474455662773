import React, { Component } from 'react'
import { CanadianSkilledWorkExpArray, foreignSkilledWorkExpCLB, foreignSkilledWorkExpCanada} from '../../helpers/CRSHelper'
export class WorkExperience extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            skilledWorkExp:null,
            foreignWorkExp:null,
            foreignExpLevel:null

        }
        this.changeHandler = this.changeHandler.bind(this)
        this.changeHandler2 = this.changeHandler2.bind(this)
    }
    componentDidUpdate = () => {
        if(this.state.skilledWorkExp){
            this.props.setCanadianSkilledWorkExp(this.state.skilledWorkExp)
            
            this.setState({skilledWorkExp:null})
           // console.log("WE"+this.state.fo)
        }
      // console.log("Foregn "+this.state.foreignWorkExp)

        if(this.state.foreignWorkExp || (this.state.foreignWorkExp == 0)){
            //console.log("hop")
            this.props.setForeignSkilledExpScore(this.state.foreignWorkExp, this.state.foreignExpLevel)
            this.props.setCertOfQualShow(true)
            this.setState({foreignWorkExp:null})
        }
    }

    changeHandler = (e) => {
        let val
        if(this.props.partner){
            val =  CanadianSkilledWorkExpArray[e.target.value].scoreWithSpouse
        }else{
            val =  CanadianSkilledWorkExpArray[e.target.value].scoreWithoutSpouse
        }
        this.setState({
            skilledWorkExp:val
        })
    }

    changeHandler2 = (e) => {

        let val = "0"
        if(this.props.firstLangScore >= 64 && this.props.firstLangScore < 116 && this.props.partner){

            val = foreignSkilledWorkExpCLB[e.target.value].scoreWithCLB7
        }
        if(this.props.firstLangScore >= 116 && this.props.partner){
            
            val = foreignSkilledWorkExpCLB[e.target.value].scoreWithCLB9

        }

        if(this.props.firstLangScore >= 68 && this.props.firstLangScore < 124 && !this.props.partner){

            val = foreignSkilledWorkExpCLB[e.target.value].scoreWithCLB7
        }
        if(this.props.firstLangScore >= 124 && !this.props.partner){
            
            val = foreignSkilledWorkExpCLB[e.target.value].scoreWithCLB9

        }

       
        this.setState({foreignWorkExp:val})
        this.setState({foreignExpLevel:e.target.value}) // To check whether its one year or two year etc

    }
    
    render() {
        
        return (
            <div>
                <label for="name">
                    6 i.) Work Experience <p> In the last ten years, how many years of skilled work experience in Canada do you have?
It must have been paid and full-time (or an equal amount in part-time).<br /></p>
                    <p>
                        Note: In Canada, the National Occupational Classification (NOC) is the official list of all the jobs in the Canadian labour market. It describes each job according to skill type, group and level.
</p>
"Skilled work" in the NOC is:
<ul>
                        <li>managerial jobs (NOC Skill Level 0)</li>
                        <li>professional jobs (NOC Skill Type A)</li>
                        <li>technical jobs and skilled trades/manual work (NOC Skill Type B)</li>
                    </ul>
                    <p>If you aren’t sure of the NOC level for this job, you can find your NOC.</p>
                </label>
                    <div className="form-group">
                        <label for="name"></label>
                                                      
                        <select id="skilledWorkExp" name="skilledWorkExp" className="form-control" onChange={this.changeHandler} >
                            <option value=''>Please Select..</option>
                            {CanadianSkilledWorkExpArray.map((item, idx) => {
                                return <option value={idx}>{item.text}</option> 
                            })}
                        </select>
                    </div>
                    <div className="form-group">
                        <label for="name">ii.) In the last 10 years, how many total years of foreign skilled work experience do you have?<br/>
It must have been paid, full-time (or an equal amount in part-time), and in only one occupation (NOC skill type 0, A or B).</label>
                                                      
                        <select id="skilledWorkExp" name="skilledWorkExp" className="form-control" onChange={this.changeHandler2} >
                            <option value=''>Please Select..</option>
                            {foreignSkilledWorkExpCLB.map((item, idx) => {
                                return <option value={idx}>{item.text}</option> 
                            })}
                        </select>
                    </div>
            </div>
        )
    }
}

export default WorkExperience
