import React from 'react'

export function FooterBlue() {
    return (
        <div>
            <div className="footer">

                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-4 col-12">
                            <div className="widget-footer">
                                <h3 className="widget-title">About us</h3>
                                <p>Welcome to Nitro Immigration Consulting Responsive Website Template</p>
                                <p>We have a global presence with a strategic network of own and associate offices in 25 locations across the world. We are planning to extend our services to over 20 offices by the year end. </p>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6">
                                <div class="widget-footer">
                                    <h3 class="widget-title">Country</h3>
                                    <ul class="listnone arrow-footer">
                                        <li><a href="#">Canada</a></li>
                                        <li><a href="#">United States</a></li>
                                        <li><a href="#">United Kingdom</a></li>
                                        <li><a href="#">Australia</a></li>
                                        <li><a href="#">New Zealand</a></li>
                                        <li><a href="#">Europe</a></li>
                                    </ul>
                                </div>
                        </div>
                            <div class="widget-footer">
                                    <h3 class="widget-title">Visas</h3>
                                    <ul class="listnone arrow-footer">
                                        <li><a href="#">Students Visas</a></li>
                                        <li><a href="#">Business Visas</a></li>
                                        <li><a href="#">Family Visas</a></li>
                                        <li><a href="#">Travel Visas</a></li>
                                        <li><a href="#">Work Visas</a></li>
                                        <li><a href="#">Visitor Visas</a></li>
                                        <li><a href="#">Migrate Visas</a></li>
                                        <li><a href="#">PR Visas</a></li>
                                    </ul>
                                </div>
                        <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6">
                            <div className="widget-footer">
                                <h3 className="widget-title">Contact Us</h3>
                                <strong>Corporate Office</strong>
                                <address>2279 Fire Access Road
                            <br /> Greensboro, NC 27401</address>
                                <p>1800 102 4150
                            <br /> 1800 102 4151</p>
                                <p><a href="#">Schedule a Meeting</a>
                                    <br />
                                    <a href="#">Talk to our Expert</a></p>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-6">
                            <div className="widget-footer widget-social">
                                <h3 className="widget-title">Connect</h3>
                                <ul className="listnone">
                                    <li><a href="#"><i className="fa fa-facebook social-icon"></i> Facebook</a></li>
                                    <li><a href="#"><i className="fa fa-twitter social-icon"></i> Twitter</a></li>
                                    <li><a href="#"><i className="fa fa-instagram social-icon"></i> Instagram</a></li>
                                    <li><a href="#"><i className="fa fa-youtube social-icon"></i> Youtube</a></li>
                                    <li><a href="#"><i className="fa fa-linkedin social-icon"></i> Linked In</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tiny-footer">

                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <p>Copyright © 2021 <a href="#">Nitro Canadian Immigration and Visa Consulting Ltd. </a>. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterBlue