import React, { Component } from 'react'
import axios  from 'axios'
import { getCookie } from '../helpers/Auth'
import {  Toast } from 'react-bootstrap'
export class TBlogs extends Component {
    
    constructor() {

        super()
        this.state = {blogs: [], token: null }
        
        this.deleteHandler = this.deleteHandler.bind(this)
       // this.updateHandler = this.updateHandler.bind(this)
    }   

   
   async loadData(){
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/blog`)
        this.setState({ blogs : response.data.blogs })
    }
    componentDidMount = async () => {

        this.loadData()
        console.log(this.state.blogs)
        let tokenCookie = getCookie('token')
        if(tokenCookie){
            this.setState({
                token : tokenCookie
            })
        }
      

    }

   

     deleteHandler = (e, id) => {
        e.preventDefault()
        let x =  window.confirm('Do you really want to Delete this record?')
        if(x){
            axios.delete(`${process.env.REACT_APP_API_URL}/admin/blog/${id}`,
            {
                headers: {
                  
                    'Authorization': 'Bearer ' + this.state.token
                }
            })
            .then(res => {
                this.loadData()
                console.log(res.data)
            })
            .catch( err => {
                console.log(err)
            })
        }
        //console.log(id)
        
       
        
    }

    
   
    render() {
        return (
            <div className="container">
                <h1>List of Blogs Post</h1>
                <table className="table table-stripped">
                    
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>shortDesc</th>
                            <th>fullDesc</th>
                            <th>Image</th>
                            <th>Show Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    
                      
                        { (this.state.blogs.length > 0) && this.state.blogs.map((blogData, index) => {
                            return (
                            <tr key={blogData.title}>
                                <td>{index+1}</td>
                                <td>{blogData.title}</td>
                                <td>{blogData.shortDesc}</td>
                                <td>{blogData.fullDesc}  </td>
                                <td><img src={`${process.env.PUBLIC_URL}/uploads/`+ blogData.fileName } width="50px" height="50px" /> </td>
                                <td>{(blogData.display)? 'Yes':'No'}</td>
                                <td><a href={`/admin/blog/${blogData._id}`} ><i className="fas fa-edit text-primary"></i> </a>| <a href="#" id={blogData._id} onClick={e => this.deleteHandler(e, blogData._id)}><i className="fas fa-trash-alt text-danger"></i></a></td>
                            </tr>
                            )
                        })}
                    </tbody>
                </table>
                
            </div>
        )
    }
}

export default TBlogs
