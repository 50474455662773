import { read } from '@popperjs/core'
import React, { Component } from 'react'
import { CLBArray, CLB2ndLangArray } from '../../helpers/CRSHelper'
export class LanguageScore extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             clbArray:CLBArray,
             clb2ndLangArray: CLB2ndLangArray,
             listening:null,
             speaking:null,
             reading:null,
             writing:null,
             firstLangType:null,
             otherLang:false,
             otherLangType:null,
             listeningOtherLang:null,
             speakingOtherLang:null,
             readingOtherLang:null,
             writingOtherLang:null
        }
        this.changeHandler = this.changeHandler.bind(this)
        this.changeHandler2 = this.changeHandler2.bind(this)
        this.changeHandler3 = this.changeHandler3.bind(this)
        this.changeHandler4 = this.changeHandler4.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleOtherLangChange = this.handleOtherLangChange.bind(this)
        this.handleFirstLangChange = this.handleFirstLangChange.bind(this)
    }
    
    componentDidUpdate = () => {
        const {listening, reading, speaking, writing} = this.state
        if((listening || listening == 0) && (reading || reading == 0) && (speaking || speaking ==0) && (writing || writing == 0)){
            let total = listening+reading+speaking+writing
            this.props.setEngLangScore(total, this.state.firstLangType)
            
            this.setState({listening:null, speaking:null, writing:null, reading:null})
            
        }
        const {listeningOtherLang, speakingOtherLang, readingOtherLang, writingOtherLang} = this.state
        if((listeningOtherLang || listeningOtherLang == 0) && (speakingOtherLang || speakingOtherLang == 0) && (readingOtherLang || readingOtherLang == 0) && (writingOtherLang || writingOtherLang == 0)){
            let total = listeningOtherLang+speakingOtherLang+readingOtherLang+writingOtherLang
            //maaximum score in case of married 22
            if(this.props.partner && (total> 22)){
                this.props.setOtherOfficialLangScore(22, this.state.otherLangType)
            }else{
                this.props.setOtherOfficialLangScore(total, this.state.otherLangType)

            }
            
            this.props.setWorkExpShowStatus(true)
            this.setState({listeningOtherLang:null, speakingOtherLang:null, readingOtherLang:null, writingOtherLang:null})
            
        }
        
    }
    
    changeHandler = (e) => {
        if(this.props.partner){
            this.setState({listening:this.state.clbArray[e.target.value].scoreWithSpouse})
        }else{
            this.setState({listening:this.state.clbArray[e.target.value].scoreWithoutSpouse})
        }
       
    }

    changeHandler2 = (e) => {
        if(this.props.partner){
            this.setState({speaking:this.state.clbArray[e.target.value].scoreWithSpouse})
        }else{
            this.setState({speaking:this.state.clbArray[e.target.value].scoreWithoutSpouse})
        }
      
       
    }
    changeHandler3 = (e) => {
        if(this.props.partner){
            this.setState({reading:this.state.clbArray[e.target.value].scoreWithSpouse})
        }else{
            this.setState({reading:this.state.clbArray[e.target.value].scoreWithoutSpouse})
        }
      
       
    }
    changeHandler4 = (e) => {
        if(this.props.partner){
            this.setState({writing:this.state.clbArray[e.target.value].scoreWithSpouse})
        }else{
            this.setState({writing:this.state.clbArray[e.target.value].scoreWithoutSpouse})
        }
      
       
    }

   
    handleChange = (e) => {
        let val
        if(this.props.partner){
            val =this.state.clb2ndLangArray[e.target.value].scoreWithSpouse
        }else{
            val =this.state.clb2ndLangArray[e.target.value].scoreWithoutSpouse
        }
        if(e.target.name == 'listeningOtherLang'){
            this.setState({listeningOtherLang:val})
        }
        if(e.target.name == 'speakingOtherLang'){
            this.setState({speakingOtherLang:val})
        }
        if(e.target.name == 'readingOtherLang'){
            this.setState({readingOtherLang:val})
        }
        if(e.target.name == 'writingOtherLang'){
            this.setState({writingOtherLang:val})
        }

    }
   
    handleOtherLangChange = (e) => {

                                        
        if(e.target.value == 'TCF' || e.target.value == 'TEF' || e.target.value== 'IELTS' || e.target.value== 'CELPIP-G'){
	    	if(e.target.value == 'IELTS'){
            	    this.setState({ otherLang: true, otherLangType:'ielts'})
		}
		if(e.target.value == 'CELPIP-G'){
		    this.setState({ otherLang: true, otherLangType:'celpip'})
		}
		if(e.target.value == 'TCF'){
		    this.setState({ otherLang: true, otherLangType:'tcf'})
		}
		if(e.target.value == 'TEF'){
		   this.setState({ otherLang: true, otherLangType:'tef'})
		}
            
        }else{
            this.setState({ otherLang: false, otherLangType:e.target.value})
            this.props.setWorkExpShowStatus(true)
        }
        
    }

    handleFirstLangChange = (e) => {

        if(e.target.value == 'IELTS'){
            this.setState({firstLangType:'ielts'})
        }
	if(e.target.value == 'CELPIP-G'){
            this.setState({firstLangType:'celpip'})
        }
	if(e.target.value == 'TCF'){
            this.setState({firstLangType:'tcf'})
        }
	if(e.target.value == 'TEF'){
            this.setState({firstLangType:'tef'})
        }


    }
    render() {
      
        return (
            <div>
                <label for="name">5) Official languages: Canada's official languages are English and French.

You need to submit language test results that are less than two years old for all programs under Express Entry, even if English or French is your first language.

</label>
                       <div>
                        <div className="form-group">
                            <label>
                            Which language test did you take for your first official language?
                            </label>
                            
                            <select id="selectFirstLang" name="selectFirstLang" className="form-control" onChange={this.handleFirstLangChange}>
		                        <option value="">Select..</option>
                                <option value="CELPIP-G">CELPIP-G</option>
					            <option value="IELTS">IELTS</option>
		                        <option value="TEF">TEF Canada</option>
		                        <option value="TCF">TCF Canada</option>
		                        <option value="NA">Not Applicable</option>
		                    </select>    
                               
                                
                            
                        </div>

                    </div>
		    {  (this.state.firstLangType) && <div>
		            <div className="form-group">
		                <label for="name">Listening</label>
		                                              
		                <select id="listening" name="listening" className="form-control" onChange={this.changeHandler} >
		                    <option value=''>Please Select..</option>
		                    {this.state.clbArray.map((item, idx) => {
		                        return <option value={idx}>{item[`${this.state.firstLangType}`].listening}</option> 
		                    })}
		                </select>
		            </div>
		            <div className="form-group">
		                <label for="name">Speaking</label>
		                                              
		                <select id="speaking" name="speaking" className="form-control"  onChange={this.changeHandler2}  >
		                    <option value=''>Please Select..</option>
		                    {this.state.clbArray.map((item, idx) => {
		                        return <option value={idx}>{item[`${this.state.firstLangType}`].speaking}</option> 
		                    })}
		                </select>
		            </div>
		            <div className="form-group">
		                <label for="name">Reading</label>
		                                              
		                <select id="reading" name="reading" className="form-control"  onChange={this.changeHandler3} >
		                    <option value=''>Please Select..</option>
		                    {this.state.clbArray.map((item, idx) => {
		                        return <option value={idx}>{item[`${this.state.firstLangType}`].reading}</option> 
		                    })}
		                </select>
		            </div>
		            <div className="form-group">
		                <label for="name">Writing</label>
		                                              
		                <select id="writing" name="writing" className="form-control"   onChange={this.changeHandler4} >
		                    <option value=''>Please Select..</option>
		                    {this.state.clbArray.map((item, idx) => {
		                        return <option value={idx}>{item[`${this.state.firstLangType}`].writing}</option> 
		                    })}
		                </select>
		            </div>
		            <div className="row">
		                <div className="form-group">
		                    <label>
		                        ii. Do you have other language results?
		                            If so, which language test did you take for your second official language?

		                            Test results must be less than two years old.
		                    </label>
                            { (this.state.firstLangType == 'ielts' || this.state.firstLangType == "celpip") &&
                                  <select id="selectOtherLang" name="selectOtherLang" className="form-control" onChange={this.handleOtherLangChange}>
                                        <option value="">Select..</option>
                                        <option value="NA">Not Applicable</option>
                                        <option value="TCF">TCF Canada</option>
                                        <option value="TEF">TEF Canada</option>
                                  </select>
                                }
                                { (this.state.firstLangType == 'tef' || this.state.firstLangType == "tcf") &&
                                  <select id="selectOtherLang" name="selectOtherLang" className="form-control" onChange={this.handleOtherLangChange}>
                                        <option value="">Select..</option>
                                        <option value="NA">Not Applicable</option>
                                        <option value="IELTS">IELTS</option>
                                        <option value="CELPIP-G">CELPIP-G</option>
                                  </select>
                                }
		                    
		                </div>

		            </div>
			</div> }
                   {  (this.state.otherLang) && <div>
                    <div className="form-group">
                        <label for="name">Listening</label>
                                                      
                        <select id="listeningOtherLang" name="listeningOtherLang" className="form-control" onChange={this.handleChange} >
                            <option value=''>Please Select..</option>
                            {this.state.clb2ndLangArray.map((item, idx) => {
                                return <option value={idx}>{item[`${this.state.otherLangType}`].listening}</option> 
                            })}
                        </select>
                    </div>
                    <div className="form-group">
                        <label for="name">Speaking</label>
                                                      
                        <select id="speakingOtherLang" name="speakingOtherLang" className="form-control"  onChange={this.handleChange}  >
                            <option value=''>Please Select..</option>
                            {this.state.clb2ndLangArray.map((item, idx) => {
                                return <option value={idx}>{item[`${this.state.otherLangType}`].speaking}</option> 
                            })}
                        </select>
                    </div>
                    <div className="form-group">
                        <label for="name">Reading</label>
                                                      
                        <select id="readingOtherLang" name="readingOtherLang" className="form-control"  onChange={this.handleChange} >
                            <option value=''>Please Select..</option>
                            {this.state.clb2ndLangArray.map((item, idx) => {
                                return <option value={idx}>{item[`${this.state.otherLangType}`].reading}</option> 
                            })}
                        </select>
                    </div>
                    <div className="form-group">
                        <label for="name">Writing</label>
                                                      
                        <select id="writingOtherLang" name="writingOtherLang" className="form-control"   onChange={this.handleChange} >
                            <option value=''>Please Select..</option>
                            {this.state.clb2ndLangArray.map((item, idx) => {
                                return <option value={idx}>{item[`${this.state.otherLangType}`].writing}</option> 
                            })}
                        </select>
                    </div>
               </div> }

            </div>
        )
    }
}

export default LanguageScore
