import React from 'react'

export function Sidebar() {
    return (
        <div className="bg-gradient-primary">
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">


                <a className="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
                    <div className="sidebar-brand-icon">
                        <img src={`${process.env.PUBLIC_URL}`+"/assets/img/nitroicon1.png"} width="60" height="60" alt="Nitro Logo"/>
                    </div>
                    <div className="sidebar-brand-text mx-3">Admin Panel</div> 
                </a>


                <hr className="sidebar-divider my-0" />


                <li className="nav-item active">
                    <a className="nav-link" href="/admin">
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <span>Dashboard</span></a>
                </li>


                <hr className="sidebar-divider" />


                <div className="sidebar-heading">
                    Pages
                </div>

                <li className="nav-item">
                    <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#collapseTwo"
                        aria-expanded="true" aria-controls="collapseTwo">
                        <i class="fas fa-blog"></i>
                        <span>Recent Updates</span>
                    </a>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">Important Components:</h6>
                            <a className="collapse-item" href="/admin/addPost">Add  Post</a>
                            <a className="collapse-item" href="/admin/postList">List  Post</a>
                            
                        </div>
                    </div>
                   
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#collapseThree"
                        aria-expanded="true" aria-controls="collapseThree">
                        <i class="fas fa-newspaper"></i>
                        <span>News | Blogs Post</span>
                    </a>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">Important Components:</h6>
                            <a className="collapse-item" href="/admin/blog">Add Blog Post</a>
                            <a className="collapse -item" href="/admin/blogList">List Blog Post</a>
                          
                        </div>
                    </div>
                   
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#collapseFour"
                        aria-expanded="true" aria-controls="collapseThree">
                        <i class="fas fa-comments"></i>
                        <span>Testimonials</span>
                    </a>
                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">Important Components:</h6>
                            <a className="collapse-item" href="/admin/addTestimonial">Add Testimonial</a>
                            <a className="collapse-item" href="/admin/testimonialList">List Testimonial</a>
                          
                        </div>
                    </div>
                   
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#collapseFive"
                        aria-expanded="true" aria-controls="collapseFive">
                       <i class="fas fa-address-card"></i>
                        <span>Enquiries</span>
                    </a>
                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">Important Components:</h6>
                           
                            <a className="collapse-item" href="/admin/contacts">List Queries | Contacts</a>
                          
                        </div>
                    </div>
                   
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapse8"
                        aria-expanded="true" aria-controls="collapseThree">
                        <i class="fas fa-calculator"></i>
                        <span>Calculators</span>
                    </a>
                    <div id="collapse8" className="collapse" aria-labelledby="heading8" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">Important Components:</h6>
                            <a className="collapse-item" href="/admin/bc-calc">BC-PNP Calc</a>
                            <a className="collapse-item" href="/admin/bc67-calc">67-Point Calc</a>
                          
                        </div>
                    </div>
                   
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#collapseSix"
                        aria-expanded="true" aria-controls="collapseSix">
                        <i class="fas fa-registered"></i>
                        <span>Subscriptions</span>
                    </a>
                    <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">Important Components:</h6>
                           
                            <a className="collapse-item" href="/admin/subscriptions">List Subscriptions</a>
                          
                        </div>
                    </div>
                   
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#collapseSeven"
                        aria-expanded="true" aria-controls="collapseSix">
                        <i className="fas fa-fw fa-cog"></i>
                        <span>Contact Us Page</span>
                    </a>
                    <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">Important Components:</h6>
                           
                            <a className="collapse-item" href="/admin/contactus-main">Update Contact Info</a>
                          
                        </div>
                    </div>
                   
                </li>


                <li className="nav-item">
                    <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#collapseUtilities"
                        aria-expanded="true" aria-controls="collapseUtilities">
                        <i class="fas fa-file"></i>
                        <span>Pages</span>
                    </a>
                    <div id="collapseUtilities" className="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">Home Pages:</h6>
                            <a className="collapse-item" href="/admin/homeEdit">Intro Section</a>
                            <a className="collapse-item" href="/admin/visaSection">Visa Section</a>
                            <a className="collapse-item" href="/admin/whynitro">WhyNitro Section</a>
                            <a className="collapse-item" href="/admin/expertService">Immigration Services</a>
                            
                        </div>
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">About Us Sections Pages:</h6>
                            <a className="collapse-item" href="/admin/aboutus-main">About Us</a>
                            <a className="collapse-item" href="/admin/aboutus-vision">Vision</a>
                            <a className="collapse-item" href="/admin/aboutus-mission">Mission</a>
                            
                        </div>
                    </div>
                    
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#collapseUtilities1"
                        aria-expanded="true" aria-controls="collapseUtilities">
                        <i class="fas fa-paper-plane"></i>
                        <span>All Visa's</span>
                    </a>
                    <div id="collapseUtilities1" className="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">Home Pages:</h6>
                            <a className="collapse-item" href="/admin/study-visa">Study Visa</a>
                            <a className="collapse-item" href="/admin/work-permit">Work Permit</a>
                            <a className="collapse-item" href="/admin/express-entry">Express Entry</a>
                            <a className="collapse-item" href="/admin/lmia-permit">LMIA</a>
                            <a className="collapse-item" href="/admin/family-sponsorship">Family Sponsorship</a>
                            <a className="collapse-item" href="/admin/pnp-permit">PNP</a>
                            <a className="collapse-item" href="/admin/tourist-visa">Tourist Visa</a>
                            <a className="collapse-item" href="/admin/child-care">Home Child Care</a>
                            
                        </div>
                    </div>
                </li>


                <hr className="sidebar-divider" />
                <li className="nav-item">
                    <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#collapseUtilities2"
                        aria-expanded="true" aria-controls="collapseUtilities">
                        <i class="fas fa-cogs"></i>
                        <span>Site Settings</span>
                    </a>
                    <div id="collapseUtilities2" className="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">Important Updates</h6>
                            <a className="collapse-item" href="/admin/settings">Topbar Contacts</a>
                            <a className="collapse-item" href="/admin/settings">Change Password</a>
                           
                            
                        </div>
                    </div>
                </li>


                {/* <div className="sidebar-heading">
                    Addons
                </div>


                <li className="nav-item">
                    <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#collapsePages"
                        aria-expanded="true" aria-controls="collapsePages">
                        <i className="fas fa-fw fa-folder"></i>
                        <span>Pages</span>
                    </a>
                    <div id="collapsePages" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">Login Screens:</h6>
                            <a className="collapse-item" href="login.html">Login</a>
                            <a className="collapse-item" href="register.html">Register</a>
                            <a className="collapse-item" href="forgot-password.html">Forgot Password</a>
                            <div className="collapse-divider"></div>
                            <h6 className="collapse-header">Other Pages:</h6>
                            <a className="collapse-item" href="404.html">404 Page</a>
                            <a className="collapse-item" href="blank.html">Blank Page</a>
                        </div>
                    </div>
                </li>


                <li className="nav-item">
                    <a className="nav-link" href="charts.html">
                        <i className="fas fa-fw fa-chart-area"></i>
                        <span>Charts</span></a>
                </li>


                <li className="nav-item">
                    <a className="nav-link" href="tables.html">
                        <i className="fas fa-fw fa-table"></i>
                        <span>Tables</span></a>
                </li>


                <hr className="sidebar-divider d-none d-md-block" />


                <div className="text-center d-none d-md-inline">
                    <button className="rounded-circle border-0" id="sidebarToggle"></button>
                </div> */}


                {/* <div className="sidebar-card">
                    <img className="sidebar-card-illustration mb-2" src="img/undraw_rocket.svg" alt="" />
                    <p className="text-center mb-2"><strong>SB Admin Pro</strong> is packed with premium features, components, and more!</p>
                    <a className="btn btn-success btn-sm" href="https://startbootstrap.com/theme/sb-admin-pro">Upgrade to Pro!</a>
                </div>  */}

            </ul>
        </div>
    )
}

export default Sidebar
