import React, { Component } from 'react'

export class ResultCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
                coreTotalMain:this.computeCoreHuman(),
                spouseTotal:this.computeSpouse(),
                eduSubTotal:this.computeCE(),
                expSubTotal:this.computeCW(),
                skillTransTotal:0,
                additionalPoints:this.computeD(),
                grandScore:0

        }
        this.computeCoreHuman = this.computeCoreHuman.bind(this)
        this.computeSpouse = this.computeSpouse.bind(this)
        this.computeCE= this.computeCE.bind(this)
        this.computeCW= this.computeCW.bind(this)
        this.computeD = this.computeD.bind(this)
        this.computeSkillTrans = this.computeSkillTrans.bind(this)
        this.computeGrand = this.computeGrand.bind(this)
       
    }
    // componentDidMount = () => {
    //     const { coreFactors, spouseFactors, skillTransferabilityFactors, additionalFactors } = this.props
    //     // For Core Calculation
    //     let coreTotal = coreFactors.age + coreFactors.educationLevel + coreFactors.firstOfficialLang + coreFactors.secondOfficialLang + coreFactors.canadianWorkExp
    //       this.setState({
    //           coreTotalMain:coreTotal
    //       })  

    //       let partnerTotal = spouseFactors.educationPoints + spouseFactors.languagePoints + spouseFactors.canadaExpPoints
    //       this.setState({
    //           spouseTotal:partnerTotal
    //       })
    //     // For Skill Tranferability Calculations
    //     let totalEdu = parseInt(skillTransferabilityFactors.education.officialLangEdu) + parseInt(skillTransferabilityFactors.education.canadianWorkExpEdu)
    //     if(totalEdu > 50){
    //         totalEdu = 50
    //     }
    //     this.setState({
    //         eduSubTotal:totalEdu
    //     })

    //     let totalExp = parseInt(skillTransferabilityFactors.foreignExp.foreignWorkExpCLB) + parseInt(skillTransferabilityFactors.foreignExp.canadianForeignExp)
    //     if(totalExp > 50){
    //         totalExp = 50
    //     }
    //     this.setState({
    //         expSubTotal:totalExp
    //     })
    //     let certQual = parseInt(skillTransferabilityFactors.certOfQual)

    //     let totalSkill = parseInt(totalEdu) + parseInt(totalExp) + parseInt(certQual)
    //     console.log("Skill"+totalSkill)
    //     if(totalSkill > 100){
    //         totalSkill = 100
           
    //     }
        
    //     this.setState({
    //         skillTransTotal:totalSkill
    //     })

    //     // For Additional Factors
    //     let addTotal = parseInt(additionalFactors.provincialNomination) + parseInt(additionalFactors.jobOffer) + parseInt(additionalFactors.studyInCanada) + parseInt(additionalFactors.siblingsCanada) + parseInt(additionalFactors.frenchLanguageSkills)
    //     if(addTotal > 600){
    //         addTotal = 600
            
    //     }
    //     this.setState({
    //         additionalPoints:addTotal
    //     })
    //     console.log("Add"+addTotal)
    //     let grand = coreTotal + partnerTotal + totalSkill + addTotal
    //     this.setState({
    //         grandScore : grand
    //     })
   
    // }

    componentDidMount = () => {
        // this.setState({
        //     coreTotalMain:this.computeCoreHuman(),
        //     spouseTotal:this.computeSpouse(),
        //     eduSubTotal: this.computeCE(),
        //     expSubTotal: this.computeCW(),
           
        //     additionalPoints:this.computeD(),
            
            

        // })
       
        this.setState({
            skillTransTotal: this.computeSkillTrans(),
            

        }, () => { 
            let grand = this.state.coreTotalMain + this.state.spouseTotal + this.state.skillTransTotal + this.state.additionalPoints 

            this.setState({
                    grandScore : grand
            })
        })
        
           


    }
   componentDidUpdate = (prevProps) => {
        //Very important to add condition in update method otherwise loop will contiinue infinite times
       if(prevProps.coreFactors != this.props.coreFactors){
           this.setState({
             coreTotalMain:this.computeCoreHuman(),
             
           }, () => {

            this.setState({
                grandScore:this.computeGrand()
            })

           } )
       }
       if(prevProps.additionalFactors != this.props.additionalFactors){
           this.setState({
               additionalPoints : this.computeD(),
               
           }, () => {
                this.setState({
                    grandScore:this.computeGrand()
                })

           })
       }

       if(prevProps.spouseFactors != this.props.spouseFactors){
           this.setState({
                spouseTotal:this.computeSpouse(),
              
           }, () => {
            this.setState({
                grandScore:this.computeGrand()
            })

            })
       }

       if(prevProps.skillTransferabilityFactors != this.props.skillTransferabilityFactors){
           this.setState({
            eduSubTotal: this.computeCE(),
            expSubTotal: this.computeCW(),
            
            
           }, ()=> {
                this.setState({
                    skillTransTotal: this.computeSkillTrans()
                }, () => {
                    this.setState({
                     grandScore: this.computeGrand()
                    })
                })
           })
       }

      
   }

    computeCoreHuman = () => {
        const { coreFactors } = this.props
        let total = coreFactors.age + coreFactors.educationLevel + coreFactors.firstOfficialLang + coreFactors.secondOfficialLang + coreFactors.canadianWorkExp
      
       
        return total

    }
    computeSpouse = () => {
        const { spouseFactors } = this.props
        let total = spouseFactors.educationPoints + spouseFactors.languagePoints + spouseFactors.canadaExpPoints
        
        return total
    }

    

    computeCE = () => { // C Part Education 
        const {skillTransferabilityFactors } = this.props
        let total = parseInt(skillTransferabilityFactors.education.officialLangEdu) + parseInt(skillTransferabilityFactors.education.canadianWorkExpEdu)
        
        if(total > 50){
            total = 50
        }
        this.setState({
          eduSubTotal:total  
        })
       
       return total
    }

    computeCW = () => { // C Part Work Exp 
        const {skillTransferabilityFactors } = this.props
        let total = parseInt(skillTransferabilityFactors.foreignExp.foreignWorkExpCLB) + parseInt(skillTransferabilityFactors.foreignExp.canadianForeignExp)
        
       
        if(total > 50){
            total = 50
        }
        this.setState({
            expSubTotal:total  
          })
         
       
        return total
    }
    computeSkillTrans  = ()  => {
        let total = this.state.eduSubTotal + this.state.expSubTotal + parseInt(this.props.skillTransferabilityFactors.certOfQual)
        if(total > 100){
            total = 100
        }
       
         
        return total

    }

    computeD = () => {

        const { additionalFactors } = this.props
        let total = parseInt(additionalFactors.provincialNomination) + parseInt(additionalFactors.jobOffer) + parseInt(additionalFactors.studyInCanada) + parseInt(additionalFactors.siblingsCanada) + parseInt(additionalFactors.frenchLanguageSkills)
    //     
        if(total > 600){
            total = 600
        }
       
        return total
    }

    computeGrand = () => {

        let grand = this.state.coreTotalMain + this.state.spouseTotal + this.state.skillTransTotal + this.state.additionalPoints 
        
        return grand
    }
    render() {
        const { coreFactors, spouseFactors, skillTransferabilityFactors, additionalFactors } = this.props
        return (
            <div>
               
                <div className="post-holder">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="post-content post-quote">
                                <h1>Your Results </h1>
                                <blockquote>All Express Entry candidates get a score out of 1,200, based on the four parts of the Comprehensive Ranking System formula.
<br />
Canada invite the highest-ranking candidates from the pool to apply as a permanent resident through regular “rounds of invitations.” See what minimum scores have been in the past.</blockquote>
                            </div>
                            {/* <div className="post-header">
                                    <p className="meta"><span className="meta-date">1 Feb, 2018</span> <span className="meta-author">By <a href="#" className="meta-link">Visapress</a></span> <span className="meta-comments"><a href="#" className="meta-link">Comments</a></span></p>
                                </div> */}

                            <h3 className="text-primary">A. Core/Human capital factors</h3>
                            <hr />
                            <div className="h6">Age = {this.props.coreFactors.age}</div>
                            <div className="h6">Level Of Education = {this.props.coreFactors.educationLevel}</div>
                            <div className="h6">First official Language = {this.props.coreFactors.firstOfficialLang}</div>
                            <div className="h6">Second official Language = {this.props.coreFactors.secondOfficialLang}</div>
                            <div className="h6">Canadian Work Experience = {this.props.coreFactors.canadianWorkExp}</div>
                            <div className="h6">Core Human Factors Total = {this.state.coreTotalMain}</div>
                            <br/>
                            <h3 className="text-primary">B. Spouse/Common-Law Partner factors</h3>
                            <hr />
                            <div className="h6">Level Of Education = {spouseFactors.educationPoints}</div>
                            <div className="h6">First official Language = {spouseFactors.languagePoints}</div>
                            <div className="h6">Canadian Work Experience = {spouseFactors.canadaExpPoints}</div>
                            <div className="h6">Spouse Factors Total = {this.state.spouseTotal}</div>
                            <br/>
                            <h3 className="text-primary">C. Skill Transferability factors (Maximum 100 points)</h3>
                            <hr />
                            <h5>Education</h5>
                            <div className="h6">A) Official Language proficiency and education = {skillTransferabilityFactors.education.officialLangEdu}</div>
                            <div className="h6">B) Canadian work experience and education = {skillTransferabilityFactors.education.canadianWorkExpEdu}</div>
                            <div className="h6">Sub Total= {this.state.eduSubTotal}</div>
                           
                            <br/>
                            <h5>Foreign work experience</h5>
                            <div className="h6">A) Official Language proficiency and foreign work experience  = {skillTransferabilityFactors.foreignExp.foreignWorkExpCLB}</div>
                            <div className="h6">B) Canadian and foreign work experience = {skillTransferabilityFactors.foreignExp.canadianForeignExp}</div>
                            <div className="h6">Sub Total= {this.state.expSubTotal}</div>
                            <div className="h6">Certificate of qualification = {skillTransferabilityFactors.certOfQual}</div>
                            <div className="h6">Total Skill transferability factors Points = {this.state.skillTransTotal}</div>

                            <br/>
                            <h3 className="text-primary">D. Additional Factors (Maximum 600 Points)</h3>
                            <hr />

                            <div className="h6">Provincial nomination = {additionalFactors.provincialNomination}</div>
                            <div className="h6">Job offer = {additionalFactors.jobOffer}</div>
                            <div className="h6">Study in Canada = {additionalFactors.studyInCanada}</div>
                            <div className="h6">Sibling in Canada = {additionalFactors.siblingsCanada}</div>
                            <div className="h6">French-language skills = {additionalFactors.frenchLanguageSkills}</div>
                            <div className="h6">Subtotal Additional factors = {this.state.additionalPoints}</div>
                            <br/>
                            <hr/>
                            <h3 className="text-danger">Grand Score : {this.state.grandScore} </h3>
                            <br/>
                            <div >
                                <div className="row">
                                    	<div className="col-md-6">
                                    		<a href="/contactus" className="btn btn-default">Contact Us For Further Information -  <i className='fas fa-phone'></i> +1 (604) 356-0747</a> 
                                    	</div>
                                    	<div className="col-md-6">
                                    		 
                                    	</div>
                                    </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResultCard
