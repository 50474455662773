import React from 'react'
import HeaderClassicLight from './HeaderClassicLight'
import TopBarLight from './TopBarLight'
export function HeaderWhite() {
    return (
        <div>
            
                <TopBarLight/>
                <HeaderClassicLight/>
            
            
        </div>
    )
}

export default HeaderWhite