import React from 'react'

export function ContactUsHero() {
    return (
        <div>
            <div className="page-header bg-background-imgContact">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <h1 className="page-title">Contact Us </h1>
                            <p className="page-description">We are here to help you in all your immigration needs.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slanting-pattern"></div>
            
        </div>
    )
}

export default ContactUsHero